import styled from 'styled-components'
import Grid from '../Grid'
import IconButton from '../IconButton'
import Paper from '../Paper'

export const ContainerFormStyled = styled.div`
  position: relative;
`

export const ContainerTableStyled = styled.div`
  margin-top: 10px;
  max-width: 100%;
  overflow-x: scroll;
`

export const RatesInfoStyled = styled(Grid)`
  padding: 25px;
  border-radius: 50px;
  min-width: 500px;
  overflow: hidden;
`

export const DueDateGrid = styled(Grid)`
  position: relative;
`

export const DueDateLoading = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
`

export const RefundLoanInfoStyled = styled(Grid)`
  padding: 25px;
  border-radius: 50px;
  min-width: 500px;
  overflow: hidden;
`

export const ContentLoaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`

export const IconButtonStyled = styled(IconButton)`
  padding: 5px;
  margin-left: 5px;
`

export const PaperStyled = styled(Paper)`
  display: flex;
  justify-content: center;
`
