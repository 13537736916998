import React, { memo } from 'react'
import PropTypes from 'prop-types'
import i18n from '~commons/i18n'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { IconButton } from '~components'
import { Event as EventUtils } from '@base39/ui-utils'
import toast from '~commons/utils/toast'

function CopyProposalLink({ id }) {
  const text = `${window.location.origin}/propostas/${id}`

  const copyLink = () => {
    navigator.clipboard.writeText(text)
    toast.success(i18n.t('proposal.copiedLink'))
  }

  const handleClick = (event) => {
    EventUtils.stopEvent(event)
    copyLink()
  }

  return (
    <IconButton onClick={handleClick}>
      <FileCopyIcon />
    </IconButton>
  )
}

CopyProposalLink.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default memo(CopyProposalLink)
