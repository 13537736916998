import styled, { css } from 'styled-components'

export const TimelineStyled = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #24292e;

  ${({ comments }) =>
    comments &&
    css`
      && {
        margin-left: ${({ theme }) => theme.spacing(5)}px;
        padding-left: ${({ theme }) => theme.spacing(2)}px;
      }
    `}
`

export const TimelineItemStyled = styled.div`
  display: flex;
  position: relative;
  padding: ${({ theme }) => theme.spacing(2)}px 0
    ${({ theme, space }) => (space ? `${theme.spacing(space)}px` : '4px')} 0;
  margin-left: ${({ theme }) => theme.spacing(2)}px;

  &:before {
    content: '';
    display: block;
    width: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #e1e4e8;
  }

  &:target .TimelineItem-badge {
    border-color: #2188ff;
    box-shadow: 0 0 0.2em #c8e1ff;
  }
`

export const TimelineBodyStyled = styled.div`
  width: 100%;
  margin-top: 4px;
  color: #444d56;
  flex: auto;
`

export const CommentStyled = styled(TimelineBodyStyled)`
  margin-top: 0;
  margin-bottom: 0;
`
