import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { ptBR } from './locales'

export const defaultLanguage = 'pt-BR'

export const resources = {
  'pt-BR': {
    translation: ptBR,
  },
}

const languageDetector = new LanguageDetector()

languageDetector.addDetector(defaultLanguage)

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: defaultLanguage,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
