import React from 'react'
import PeopleIcon from '@material-ui/icons/People'
import ConfigIcon from '@material-ui/icons/Build'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import DashboardIcon from '@material-ui/icons/Dashboard'
import { ReactComponent as CompanySvg } from '~assets/svg/company.svg'
import i18n from '~commons/i18n'
import { SvgIcon } from '~components'

const companies = [
  {
    id: 'groups-menu-item',
    color: '#1ABC9C',
    label: i18n.t('menu.groups'),
    route: '/grupos',
    icon: () => <GroupOutlinedIcon fontSize="small" />,
    rule: 'ADMIN',
  },
  {
    id: 'companies-menu-item',
    color: '#1ABC9C',
    label: i18n.t('menu.companies'),
    route: '/empresas',
    icon: () => <SvgIcon component={CompanySvg} fontSize="small" viewBox="0 0 496 496" />,
    rule: null,
  },
]

const backoffice = [
  {
    id: 'clients-menu-item',
    color: '#17BAE6',
    label: i18n.t('menu.clients'),
    route: '/clientes',
    icon: () => <PeopleIcon fontSize="small" />,
    rule: 'ADMIN',
  },
  {
    id: 'funds-menu-item',
    color: '#17BAE6',
    label: i18n.t('menu.funds'),
    route: '/fundos',
    icon: () => <ConfigIcon fontSize="small" />,
    rule: 'ADMIN',
  },
]

const tools = [
  {
    id: 'simulator-menu-item',
    color: '#686DE0',
    label: i18n.t('menu.simulator'),
    route: '/simulador',
    icon: () => <AttachMoneyIcon fontSize="small" />,
    rule: 'ADMIN',
  },
  {
    id: 'credit-policy-menu-item',
    color: '#686DE0',
    label: i18n.t('menu.creditPolicy'),
    route: '/politicas-credito',
    icon: () => <DashboardIcon fontSize="small" />,
    rule: 'ADMIN',
  },
]

export const ALL_ITEMS = [...backoffice, ...companies, ...tools]

export const ITEMS_MENU = {
  companies,
  backoffice,
  tools,
}
