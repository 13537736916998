import React, { createRef, useEffect, useState, useRef, memo } from 'react'
import RCTDatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import { Close, DeleteForever } from '@material-ui/icons'
import i18n from '~commons/i18n'
import { Date as DateUtils } from '@base39/ui-utils'
import i18nCalendar from '~commons/i18n/calendar'
import { getLanguage } from '~commons/utils/locale'
import IconButton from '../../IconButton'
import Button from '../../Button'
import DateInput from './DateInput'
import { DatePeriodPickerStyled, DatePickerFooterStyled, TextStyled } from './style'
import 'react-datepicker/dist/react-datepicker.min.css'

const localeUser = i18nCalendar[getLanguage()]

const refStart = createRef()
const refEnd = createRef()

const initialState = {
  startDate: null,
  endDate: null,
  defaultStartDate: DateUtils.getNow(),
  defaultEndDate: DateUtils.getNow(),
}

const DatePeriodPicker = (props) => {
  const {
    dateFormat,
    required,
    disabled,
    onChange,
    onCancel,
    minDate,
    maxDate,
    customProps,
  } = props
  const { shouldCloseOnSelect, localeFormat, withPortal, hasClear, hasClearEach } = props

  const [dates, setDates] = useState(initialState)
  const oldStartDate = useRef(null)
  const oldEndDate = useRef(null)
  const [refreshStart, setRefreshStart] = useState(null)
  const [refreshEnd, setRefreshEnd] = useState(null)

  const setOldDates = () => {
    oldStartDate.current = props.value.startDate
    oldEndDate.current = props.value.endDate
  }

  const clearOldDates = () => {
    oldStartDate.current = null
    oldEndDate.current = null
  }

  const closeCalendar = (ref) => {
    if (ref && ref.current) {
      ref.current.setOpen(false)
    }
  }

  const handleConfirmStartDate = (ref) => {
    const date = {
      startDate: DateUtils.formatToISO(dates.startDate || dates.defaultStartDate),
      endDate: DateUtils.formatToISO(dates.endDate),
    }

    onChange(date)
    setDates(date)
    closeCalendar(ref)
    setRefreshStart(dates.startDate)
  }

  const handleConfirmEndDate = (ref) => {
    const date = {
      startDate: DateUtils.formatToISO(dates.startDate),
      endDate: DateUtils.formatToISO(dates.endDate || dates.defaultEndDate),
    }

    onChange(date)
    setDates(date)
    closeCalendar(ref)
    setRefreshEnd(dates.endDate)
  }

  const handleCancelStart = () => {
    closeCalendar(refStart)

    onChange({
      startDate: DateUtils.formatToISO(oldStartDate.current),
      endDate: DateUtils.formatToISO(dates.endDate),
    })

    if (onCancel) {
      onCancel()
    }
  }

  const handleCancelEnd = () => {
    closeCalendar(refEnd)

    onChange({
      startDate: DateUtils.formatToISO(dates.startDate),
      endDate: DateUtils.formatToISO(oldEndDate.current),
    })

    if (onCancel) {
      onCancel()
    }
  }

  const handleOnChange = (startDate, endDate) => {
    const newStartDate = DateUtils.getStartDay(startDate)
    const newEndDate = DateUtils.getEndDay(endDate)

    const validateEndDate =
      newStartDate > newEndDate ? DateUtils.getEndDay(startDate) : newEndDate

    const dates = {
      startDate: DateUtils.formatToISO(newStartDate),
      endDate: DateUtils.formatToISO(validateEndDate),
    }

    setDates(dates)
    onChange(dates)
  }

  const getDateValue = (key) => DateUtils.convertDateToUTC(props.value[key])

  const getValue = () => {
    const startDate = getDateValue('startDate')
    const endDate = getDateValue('endDate')
    const newEndDate = startDate > endDate ? startDate : endDate

    return { startDate, endDate: newEndDate }
  }

  const onClearStartDate = () => {
    const date = { startDate: null, endDate: dates.endDate }
    onChange(date)
    setDates({ ...initialState, ...date })
    oldStartDate.current = null
  }

  const onClearEndDate = () => {
    const date = { startDate: dates.startDate, endDate: null }
    onChange(date)
    setDates({ ...initialState, ...date })
    oldEndDate.current = null
  }

  const onClearCalendar = () => {
    onChange({ startDate: null, endDate: null })
    setDates(initialState)
    clearOldDates()
  }

  const value = getValue()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setOldDates()
  }, [refreshStart, refreshEnd])

  const renderClearButton = () => {
    if (!hasClear) return null
    return (
      <IconButton className="ml-1" size="small" onClick={onClearCalendar}>
        <DeleteForever size="small" variant="outlined" />
      </IconButton>
    )
  }

  const renderCloseButtonEnd = () => {
    if (!hasClearEach || !dates.endDate) return null

    return (
      <IconButton className="ml-1" size="small" onClick={onClearEndDate}>
        <Close size="small" />
      </IconButton>
    )
  }

  const renderCloseButtonStart = () => {
    if (!hasClearEach || !dates.startDate) return null

    return (
      <IconButton className="ml-1" size="small" onClick={onClearStartDate}>
        <Close size="small" />
      </IconButton>
    )
  }

  return (
    <DatePeriodPickerStyled>
      <RCTDatePicker
        ref={refStart}
        autoComplete="off"
        customInput={<DateInput {...customProps} />}
        dateFormat={dateFormat}
        disabled={disabled}
        endDate={value.endDate}
        locale={localeFormat}
        maxDate={maxDate}
        minDate={minDate}
        onChange={(date) => handleOnChange(date, value.endDate)}
        placeholderText={i18n.t('general.start')}
        required={required}
        selected={value.startDate}
        selectsStart
        shouldCloseOnSelect={shouldCloseOnSelect}
        startDate={value.startDate}
        withPortal={withPortal}
        fixedHeight
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      >
        <DatePickerFooterStyled>
          <Button color="primary" onClick={handleCancelStart} data-e2e-id="button-cancel">
            {i18n.t('general.cancel')}
          </Button>
          <Button
            color="primary"
            onClick={() => handleConfirmStartDate(refStart)}
            data-e2e-id="button-confirm"
          >
            {i18n.t('general.ok')}
          </Button>
        </DatePickerFooterStyled>
      </RCTDatePicker>
      {renderCloseButtonStart()}

      <TextStyled>{i18n.t('general.until')}</TextStyled>

      <RCTDatePicker
        ref={refEnd}
        autoComplete="off"
        customInput={<DateInput {...customProps} />}
        dateFormat={dateFormat}
        endDate={value.endDate}
        locale={localeFormat}
        maxDate={maxDate}
        minDate={value.startDate || minDate}
        onChange={(date) => handleOnChange(value.startDate, date)}
        placeholderText={i18n.t('general.end')}
        required={required}
        selected={value.endDate}
        selectsEnd
        shouldCloseOnSelect={shouldCloseOnSelect}
        startDate={value.startDate}
        withPortal={withPortal}
        fixedHeight
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      >
        <DatePickerFooterStyled>
          <Button color="primary" onClick={handleCancelEnd} data-e2e-id="button-cancel">
            {i18n.t('general.cancel')}
          </Button>
          <Button
            color="primary"
            onClick={() => handleConfirmEndDate(refEnd)}
            data-e2e-id="button-confirm"
          >
            {i18n.t('general.ok')}
          </Button>
        </DatePickerFooterStyled>
      </RCTDatePicker>
      {renderCloseButtonEnd()}
      {renderClearButton()}
    </DatePeriodPickerStyled>
  )
}

DatePeriodPicker.propTypes = {
  dateFormat: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  value: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  customProps: PropTypes.object,
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
  required: PropTypes.bool,
  shouldCloseOnSelect: PropTypes.bool,
  localeFormat: PropTypes.object,
  withPortal: PropTypes.bool,
  hasClear: PropTypes.bool,
  hasClearEach: PropTypes.bool,
}

DatePeriodPicker.defaultProps = {
  customProps: null,
  dateFormat: localeUser.formats.L,
  disabled: false,
  required: false,
  maxDate: null,
  minDate: null,
  shouldCloseOnSelect: false,
  localeFormat: {
    localize: {
      month: (month) => localeUser.months[month],
      day: (day) => localeUser.days[day],
    },
    formatLong: {},
  },
  onCancel: null,
  withPortal: false,
  hasClear: false,
  hasClearEach: true,
}

export default memo(DatePeriodPicker)
