import styled from 'styled-components'
import Grid from '../Grid'

export const WrapMenuStyled = styled(Grid)`
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #e5e5e6 #f2f2f2;
  width: 100%;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f2f2f2;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #e5e5e6;
  }
`

export const HeaderStyled = styled(Grid)`
  width: 100%;
`

export const ContentStyled = styled(Grid).attrs({
  item: true,
  xs: true,
})`
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #e5e5e6 #f2f2f2;
  width: 100%;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f2f2f2;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #e5e5e6;
  }
`
