/* eslint-disable camelcase */
import { Drawer } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const DRAWER_WIDTH = 275

export const DrawerStyled = styled(Drawer).attrs({
  classes: {
    paper: 'custom-paper',
  },
})`
  width: ${DRAWER_WIDTH}px;
  flex-shrink: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  & .custom-paper {
    width: ${DRAWER_WIDTH}px;
    box-shadow: inset 0 -1px 0 0 #e2e2ea;
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.palette.primary.main}
      ${({ theme }) => theme.palette.scrollbar.main};

    ::-webkit-scrollbar {
      width: 8px;
      background-color: ${({ theme }) => theme.palette.scrollbar.main};
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      & .custom-paper {
        height: calc(100% - 50px);
        margin-top: 50px;
        overflow: hidden;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        width: ${DRAWER_WIDTH}px;
      }
    }
  `}
`
