import authRoutes from './auth'
import company from './company'
import customers from './customers'
import simulator from './simulator'
import groups from './groups'
import privacyPolicy from './privacy-policy'
import creditPolicy from './credit-policy'
import configs from './configs'

const routes = [
  ...authRoutes,
  ...company,
  ...creditPolicy,
  ...customers,
  ...simulator,
  ...configs,
  ...groups,
  ...privacyPolicy,
]

export default routes
