import React from 'react'
import PropTypes from 'prop-types'
import { OptimizelyFeature } from '@optimizely/react-sdk'
import featureSdk from './FeatureSdk'

function Feature({ children, feature }) {
  return (
    <OptimizelyFeature feature={feature}>
      {(isEnabled) => isEnabled && <>{children}</>}
    </OptimizelyFeature>
  )
}

Feature.propTypes = {
  feature: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Feature

export { withOptimizely } from '@optimizely/react-sdk'

export { featureSdk }
