import React, { memo, useState } from 'react'
import MoreIcon from '@material-ui/icons/KeyboardArrowDown'
import { Grid } from '~components'
import { ITEMS_MENU, ALL_ITEMS } from './menu'
import {
  MenuIndicatorStyled,
  NavigationMenuStyled,
  MenuItemStyled,
  LinkStyled,
  DividerRowStyled,
  MenuStyled,
  MenuContentStyled,
  GridStyled,
} from './style'

const NavigationMenu = ({ location }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const openMenu = (ev) => setAnchorEl(ev?.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const getSelectedContainerRoute = location.pathname.split('/')[1]

  const selectedMenu = ALL_ITEMS.find(
    (it) => getSelectedContainerRoute === it.route.replace('/', ''),
  )

  const renderMenu = ({ color, label, route, icon, id }) => {
    return (
      <MenuItemStyled onClick={handleClose} key={label}>
        <LinkStyled to={route}>
          <Grid id={id} container direction="column" alignItems="center">
            <Grid item>
              <MenuIndicatorStyled color={color}>{icon()}</MenuIndicatorStyled>
            </Grid>
            <GridStyled item>{label}</GridStyled>
          </Grid>
        </LinkStyled>
      </MenuItemStyled>
    )
  }

  return (
    <>
      <NavigationMenuStyled onClick={openMenu}>
        <MenuIndicatorStyled className="mr-1" color={selectedMenu?.color}>
          {selectedMenu?.icon()}
        </MenuIndicatorStyled>
        {selectedMenu?.label}
        <MoreIcon fontSize="small" className="ml-1" />
      </NavigationMenuStyled>

      <MenuStyled
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: 'menu' }}
      >
        <MenuContentStyled>{ITEMS_MENU.companies?.map(renderMenu)}</MenuContentStyled>
        <DividerRowStyled />
        <MenuContentStyled>{ITEMS_MENU.backoffice?.map(renderMenu)}</MenuContentStyled>
        <DividerRowStyled />
        <MenuContentStyled>{ITEMS_MENU.tools?.map(renderMenu)}</MenuContentStyled>
      </MenuStyled>
    </>
  )
}

export { NavigationMenu }
export default memo(NavigationMenu)
