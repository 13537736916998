import styled, { css } from 'styled-components'
import DialogTitle from '../DialogTitle'
import Typography from '../../Typography'
import IconButton from '../../IconButton'

export const DialogTitleStyled = styled(Typography)`
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.1rem;
`

export const TitleContainerStyled = styled.div`
  flex: 1;
`

export const DialogSubtitleStyled = styled(Typography)`
  padding: 0;
  color: ${({ theme }) => theme.typography.label};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
`

export const DialogHeaderStyled = styled(DialogTitle)`
  margin: 0;
  display: flex;
  align-items: center;
  ${({ color }) =>
    color &&
    css`
      background: ${color};
      color: #fff;
    `}
`

export const ActionsContainerStyled = styled.div`
  margin: ${({ theme }) => theme.spacing(-1, -2, -1, 1)};
`
export const IconButtonStyled = styled(IconButton)`
  margin: 0 5px;
`
