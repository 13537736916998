import React, { memo } from 'react'
import { ArrowBackInterface } from './interfaces'
import {
  ArrowBackIconStyled,
  ContentStyled,
  ContainedIconStyled,
  IconStyled,
  TextStyled,
} from './style'

const ArrowBack = ({ onClick, children, contained }: ArrowBackInterface) => {
  const renderIcon = () => {
    if (contained) {
      return <ContainedIconStyled data-testid="arrowBack-iconContained" />
    }

    return (
      <IconStyled data-testid="arrowBack-icon">
        <ArrowBackIconStyled />
      </IconStyled>
    )
  }

  return (
    <ContentStyled
      onClick={onClick}
      contained={contained}
      data-testid="arrowBack-content"
    >
      {renderIcon()}
      <TextStyled contained={contained} data-testid="arrowBack-text">
        {children}
      </TextStyled>
    </ContentStyled>
  )
}

export default memo(ArrowBack)
