import styled from 'styled-components'
import Logo from '~assets/png/logo_pkt.png'
import i18n from '~commons/i18n'

export const ImageStyled = styled.img.attrs({
  src: Logo,
  alt: i18n.t('seo.logo.alt'),
  title: i18n.t('seo.logo.title'),
})`
  height: 55px;
  width: 120px;
`
