import theme from '~commons/constants/theme'

const colors = {
  done: '#7ED321',
  underReview: theme.palette.warning.main,
  onRelease: '#15BAE6',
  notApproved: '#D0021B',
}

const STATUS_COLORS = {
  SIGNATURE_PENDING: {
    color: colors.underReview,
  },
  IN_ANALYSIS_RH: {
    color: colors.onRelease,
  },
  REQUESTED_CHANGES_PAKETA: {
    color: colors.underReview,
  },
  REQUESTED_CHANGES_FINANCIAL: {
    color: colors.underReview,
  },
  REQUESTED_CHANGES_RH: {
    color: colors.underReview,
  },
  CANCELED: {
    color: colors.notApproved,
  },
  IN_ANALYSIS_PAKETA: {
    color: colors.onRelease,
  },
  IN_ANALYSIS_FINANCIAL: {
    color: colors.onRelease,
  },
  SIGNED: {
    color: colors.onRelease,
  },
  REJECTED_RH: {
    color: colors.notApproved,
  },
  DONE: {
    color: colors.done,
  },
  PAID: {
    color: colors.done,
  },
  REJECTED_PAKETA: {
    color: colors.notApproved,
  },
}

export default STATUS_COLORS
