import styled from 'styled-components'
import { FormHelperText } from '@material-ui/core'

export default styled(FormHelperText)`
  position: absolute;
  bottom: -20px;
  height: 15px;
  line-height: 15px;
  text-overflow: ellipsis;
`
