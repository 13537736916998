import React from 'react'
import { Input } from '../Form/Input'
import { FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  SelectStyled,
  ContainerStyled,
  MenuItemStyled,
  IconButtonStyled,
  InputLabelStyled,
} from './style'
import { Close } from '@material-ui/icons'
import Checkbox from '../Checkbox'

const useStyles = makeStyles({
  selectMenu: {
    height: '70%',
  },
  icon: {
    right: '8px',
  },
})

const SearchMultiple = ({
  values,
  variant,
  placeholder = '',
  label,
  renderItem,
  onChange,
  selectedValues = [],
}) => {
  const classes = useStyles()
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
        width: 350,
      },
    },
  }

  const handleChange = (event) => {
    changeValue(event.target.value)
  }

  const changeValue = (values) => {
    onChange(values)
  }

  const cancelBtn = (
    <IconButtonStyled data-e2e-id="clearButton" onClick={() => changeValue([])}>
      <Close cursor="pointer" color="action" />
    </IconButtonStyled>
  )

  const renderMenuItem = (value) => (
    <MenuItemStyled key={value.key} value={value.key}>
      <Checkbox checked={selectedValues.indexOf(value.key) > -1} />
      {value.label}
    </MenuItemStyled>
  )

  const hasValues = selectedValues.length > 0

  const renderContentValue = (selected) => {
    if (selected.length > 1) {
      return (
        <>
          {renderItem(selected[0])} + {selected.length - 1}
        </>
      )
    }
    return renderItem(selected[0])
  }

  const renderValue = (selected) => {
    if (selected.length > 0) {
      return <ContainerStyled>{renderContentValue(selected)}</ContainerStyled>
    }

    return placeholder
  }

  return (
    <FormControl
      variant={variant}
      fullWidth
      classes={{ FormControl: classes.FormControl }}
    >
      {label && !hasValues && <InputLabelStyled shrink>{label}</InputLabelStyled>}
      <SelectStyled
        classes={{ icon: classes.icon, selectMenu: classes.selectMenu }}
        multiple
        displayEmpty
        value={selectedValues}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        endAdornment={hasValues ? cancelBtn : null}
        MenuProps={MenuProps}
        renderValue={renderValue}
        label={label}
      >
        {values.map(renderMenuItem)}
      </SelectStyled>
    </FormControl>
  )
}

export default SearchMultiple
