import styled from 'styled-components'
import { DialogTitle, DialogContent, DialogActions } from '~components/Dialog'

export const DialogTitleStyled = styled(DialogTitle)`
  && {
    text-align: center;
    padding: ${({ theme }) => theme.spacing(4)}px;
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const DialogContentStyled = styled(DialogContent)`
  && {
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;
    text-align: center;
  }
`

export const DialogActionsStyled = styled(DialogActions)`
  && {
    margin: 0;
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  }

  .button {
    width: 50%;

    &--success {
      background: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }

    &--cancel {
      background: ${({ theme }) => theme.palette.error.main};
      color: ${({ theme }) => theme.palette.error.contrastText};
    }
  }
`
