import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const WrapBackStyled = styled.button`
  display: flex;
  width: fit-content;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(2, 0)};
  background: none;
  border: none;
`

export const ImageStyled = styled.img`
  width: 20px;
`

export const TextStyled = styled.span`
  padding-left: 8px;
  color: ${({ theme }) => theme.palette.primary.main};
  &:hover {
    text-decoration: underline;
  }
`

export const ButtonStyled = styled(Button)`
  && {
    border-radius: 10px;
    line-height: 24px;
    text-transform: none;
    font-size: 0.875rem;
  }
`
