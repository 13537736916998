import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import { SpeedDialStyled } from './style'

const SpeedDial = ({
  float,
  label,
  className,
  hidden,
  open,
  direction,
  actions,
  ...props
}) => {
  const [opened, setOpened] = useState(open)
  const handleClose = () => {
    setOpened(false)
  }

  const handleOpen = () => {
    setOpened(true)
  }

  const renderSpeedAction = (action) => {
    const { name, icon } = action
    return (
      <SpeedDialAction
        key={name}
        icon={icon}
        tooltipTitle={name}
        onClick={() => handleClick(action)}
      />
    )
  }

  const handleClick = (action) => {
    action.onClick()
    handleClose()
  }

  return (
    <SpeedDialStyled
      {...props}
      float={float}
      ariaLabel={label}
      className={className}
      hidden={hidden}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={opened}
      direction={direction}
    >
      {actions.map(renderSpeedAction)}
    </SpeedDialStyled>
  )
}

SpeedDial.defaultProps = {
  label: '',
  className: 'customSpeedDial',
  hidden: false,
  open: false,
  direction: 'up',
  float: true,
}

SpeedDial.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  open: PropTypes.bool,
  direction: PropTypes.oneOf(['up', 'right', 'down', 'left']),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
  float: PropTypes.bool,
}

export default memo(SpeedDial)
