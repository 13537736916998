import React, { memo } from 'react'
import RefreshIcon from '@material-ui/icons/Refresh'
import { IconButtonStyled } from './style'

const Refresh = ({ refresh, className, size }) => (
  <IconButtonStyled onClick={refresh} className={className} size={size}>
    <RefreshIcon />
  </IconButtonStyled>
)

export default memo(Refresh)
