import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { DialogTitle as DialogTitleComponent } from '@material-ui/core'

const DialogTitle = styled(DialogTitleComponent)`
  ${({ theme }) => `
    padding: ${theme.spacing(1, 3)};
  `}

  ${({ theme, $divider }) =>
    $divider &&
    css`
      border-bottom: 1px solid ${theme.palette.divider};
    `}

  .MuiTypography-h6 {
    font-size: 1.1rem;
  }
`

DialogTitle.defaultProps = {
  $divider: false,
}

DialogTitle.propTypes = {
  $divider: PropTypes.bool,
}

export default DialogTitle
