import { RestApi } from './api'

const path = '/simulations'

const Simulations = {
  getMaxInstallmentValue({ userId, salary, netSalary }) {
    return RestApi.customRequest(`${path}/max-installment-value`, {
      method: 'GET',
      params: {
        userId,
        salary,
        netSalary,
      },
    })
  },
}

export default Simulations
