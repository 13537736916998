import React, { memo, useState } from 'react'
import Popover from '../Popover'
import IconButton from '../IconButton'
import Grid from '../Grid'
import InfoIcon from '@material-ui/icons/Info'
import { Money as MoneyUtils } from '@base39/ui-utils'
import { getValue, formatPercent } from './utils'
import i18n from '~commons/i18n'
import { RatesInfoStyled } from './style'

function RatesInfos({ values }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'rates-infos' : undefined

  return (
    <>
      <IconButton
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <InfoIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <RatesInfoStyled container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div>{i18n.t('proposal.registerFee')}</div>
                {getValue(values, 'registerFee', MoneyUtils.formatCurrency)}
              </Grid>
              <Grid item xs={3}>
                <div>{i18n.t('proposal.iofValue')}</div>
                {getValue(values, 'iofValue', MoneyUtils.formatCurrency)}
              </Grid>
              <Grid item xs={3}>
                <div>{i18n.t('proposal.amCet')}</div>
                {getValue(values, 'monthlyCET', formatPercent)}
              </Grid>
              <Grid item xs={3}>
                <div>{i18n.t('proposal.aaCet')}</div>
                {getValue(values, 'yearlyCET', formatPercent)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div>{i18n.t('proposal.amTax')}</div>
                {getValue(values, 'monthlyFee', formatPercent)}
              </Grid>
              <Grid item xs={3}>
                <div>{i18n.t('proposal.aaTax')}</div>
                {getValue(values, 'yearlyFee', formatPercent)}
              </Grid>
              <Grid item xs={3}>
                <div>{i18n.t('proposal.insuranceRate')}</div>
                {getValue(values, 'insuranceFee', formatPercent)}
              </Grid>
            </Grid>
          </Grid>
        </RatesInfoStyled>
      </Popover>
    </>
  )
}

export default memo(RatesInfos)
