import React from 'react'
import PropTypes from 'prop-types'
import { Header, Subheader, Grid, Drawer } from '~components'
import { withRouter } from 'react-router'
import UserMenu from '~containers/User/Menu'
import { LayoutStyled, MainStyled, DrawerContainerStyled, ContentStyled } from './style'

const Layout = ({ children, subheader: SubheaderComponent, drawer: DrawerComponent }) => (
  <LayoutStyled>
    <Grid container direction="column">
      <Grid item>
        <Header>
          <UserMenu />
        </Header>
      </Grid>

      {SubheaderComponent && (
        <Grid item>
          <Subheader>
            <SubheaderComponent />
          </Subheader>
        </Grid>
      )}

      <MainStyled item xs container>
        {DrawerComponent && (
          <DrawerContainerStyled item>
            <Drawer open>
              <DrawerComponent />
            </Drawer>
          </DrawerContainerStyled>
        )}
        <ContentStyled item xs>
          {children}
        </ContentStyled>
      </MainStyled>
    </Grid>
  </LayoutStyled>
)

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  subheader: PropTypes.object,
  drawer: PropTypes.object,
  backTo: PropTypes.string,
}

export default withRouter(Layout)
