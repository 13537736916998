import PropTypes from 'prop-types'

const InstallmentsModel = PropTypes.shape({
  id: PropTypes.string,
  allowed: PropTypes.bool,
  message: PropTypes.string,
  errorValue: PropTypes.number,
  totalValue: PropTypes.number,
  installments: PropTypes.number,
  installmentValue: PropTypes.string,
  iofValue: PropTypes.string,
  registerFee: PropTypes.string,
  monthlyCET: PropTypes.string,
  monthlyFee: PropTypes.string,
  yearlyCET: PropTypes.string,
  yearlyFee: PropTypes.string,
})

export default InstallmentsModel
