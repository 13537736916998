import React, { memo } from 'react'
import theme from '~commons/constants/theme'
import { ChipProps } from './interfaces'
import { ChipStyled } from './styles'

const Chip = ({ backgroundColor, color, label, margin }: ChipProps) => {
  return (
    <ChipStyled
      $backgroundColor={backgroundColor}
      $color={color}
      $margin={margin}
      data-testid="chip"
    >
      {label}
    </ChipStyled>
  )
}

Chip.defaultProps = {
  backgroundColor: theme.palette.gray[300],
  color: theme.palette.white[0],
  margin: '0',
}

export default memo(Chip)
