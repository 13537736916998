import React, { memo } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from '../InfiniteScroll'
import Grid from '../Grid'
import Chip from '../Chip'
import CircularProgress from '../CircularProgress'
import { remakeListWithChip } from './util'
import Item from './Item'

function InfiniteSidebar(props) {
  const { rows, loadMore, redirect, fields } = props
  const { page, totalPages } = rows ?? {}

  const hasMore = page < totalPages

  const items = remakeListWithChip(rows)

  const renderItems = (item, index) => {
    const { type, title } = item
    if (type === 'header') {
      return (
        <div className="flex flex-center" key={`infinite-sidebar-item-${index}`}>
          <Chip
            label={title}
            color="#92929D"
            backgroundColor="#F4F4F5"
            key={`dayHeader-${index}`}
          />
        </div>
      )
    }

    return (
      <Item
        data={item}
        key={`sidebar-item-${index}`}
        redirect={redirect}
        fields={fields}
      />
    )
  }

  const loaderElement = (
    <Grid container alignItems="center" justifyContent="center" className="p-2">
      <Grid item>
        <CircularProgress size={30} />
      </Grid>
    </Grid>
  )

  return (
    <InfiniteScroll
      pageStart={1}
      initialLoad={false}
      key="infinite-scroller"
      loadMore={loadMore}
      hasMore={hasMore}
      loader={loaderElement}
      useWindow={false}
    >
      {items.map(renderItems)}
    </InfiniteScroll>
  )
}

InfiniteSidebar.propTypes = {
  fields: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  loadMore: PropTypes.func.isRequired,
  redirect: PropTypes.string.isRequired,
}

export default memo(InfiniteSidebar)
