import styled, { css } from 'styled-components'
import IconButton from '../IconButton'

export const DateContainerStyled = styled.div`
  position: relative;

  & .rdrMonth {
    width: auto;
  }

  & .rdrCalendarWrapper {
    width: 100%;
    padding-top: 0px;
  }

  & .rdrMonthAndYearPickers {
    text-transform: capitalize;
    font-weight: normal;
    justify-content: flex-start;
    padding-left: 15px;
  }

  & .rdrWeekDay {
    text-transform: capitalize;
  }

  & .rdrPprevButton {
    order: 2;
  }

  & .rdrNextButton {
    order: 3;
    ${({ showClear }) =>
      showClear &&
      css`
        margin-right: 50px;
      `}
  }
`

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 10px;
  top: 20px;
  padding: 5px;
`
