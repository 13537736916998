import React from 'react'
import { IntercomProvider } from 'react-use-intercom'
import IntercomUser from './IntercomUser'

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID

const Intercom = ({ children }) => (
  <IntercomProvider autoBoot appId={INTERCOM_APP_ID}>
    <IntercomUser>{children}</IntercomUser>
  </IntercomProvider>
)

export default Intercom
