import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as CircularIcon } from '../../assets/svg/circular.svg'
import { LoadingStyled } from './style'

function Loading({
  Icon,
  full,
  children,
  className,
  type,
  alignItems,
  width,
  widthIcon,
  height,
}) {
  return (
    <LoadingStyled
      full={full}
      className={className}
      type={type}
      alignItems={alignItems}
      width={width}
      widthIcon={widthIcon}
      height={height}
    >
      {<Icon className="icon" />}
      {children}
    </LoadingStyled>
  )
}

Loading.defaultProps = {
  Icon: CircularIcon,
  type: 'default',
  alignItems: 'center',
  width: '100%',
  widthIcon: '48px',
  height: '100%',
}

Loading.propTypes = {
  full: PropTypes.bool,
  type: PropTypes.oneOf(['default', 'content']),
  children: PropTypes.node,
  className: PropTypes.string,
  alignItems: PropTypes.string,
  Icon: PropTypes.elementType,
  width: PropTypes.string,
  widthIcon: PropTypes.string,
  height: PropTypes.string,
}

export default Loading
