import { gql } from '@apollo/client'

export const User = gql`
  {
    id
    name
    email
    phoneNumber
    company {
      salary
      netSalary
      talkTo
      hiredAt
      jobRole
      ref {
        id
        tradeName
        hrContact {
          phone
        }
      }
    }
    maxCredit {
      value
    }
    maxInstallmentValue {
      value
    }
    netSalary
    createdAt
    updatedAt
    cpf
    status
    roles
    emailVerified
    nationality
    birthdayDate
    rg
    rgState
    rgEmitter
    bank
    agency
    accountType
    accountDigit
    account
    address {
      zipCode
      city
      state
      address
      number
      complement
      neighborhood
    }
    gender
    isPublicPerson
    maritalStatus
    motherFullName
    native
    specialOffer {
      creditPolicy {
        id
        name
      }
      limitDate
      appliedTo
    }
  }
`
export const LIST_USERS = gql`
  query LIST_USERS(
    $companyId: String
    $page: Int!
    $pageSize: Int
    $sort: String
    $q: String
  ) {
    listUsers(
      companyId: $companyId
      pagination: { page: $page, pageSize: $pageSize, sort: $sort }
      query: { q: $q }
    ) {
      total
      totalPages
      page
      pageSize
      rows {
        id
        createdAt
        status
        name
        cpf
        company {
          ref {
            tradeName
          }
        }
        listLoansByClient(pagination: { page: 1 }) {
          total
        }
      }
    }
  }
`

export const QUERY_LIST_ALL_USERS = gql`
  query QUERY_LIST_ALL_USERS($companyId: String, $q: String) {
    listAllUsers(companyId: $companyId, query: { q: $q }) {
      result {
        id
        createdAt
        statuss
        name
        cpf
        company {
          ref {
            tradeName
          }
        }
      }
    }
  }
`

export const GET_USER = gql`
  query GET_USER($id: ID!) {
    getUser(query: {
      id: $id
    }) ${User}
  }
`

export const GET_ME = gql`
  query {
    me ${User}
  }
`

export const GET_USER_BY_CPF = gql`
  query GET_USER_BY_CPF($cpf: String) {
    getUser(query: { cpf: $cpf }) {
      name
      phoneNumber
      email
    }
  }
`

export const GET_COMPANIES_USER_CAN_APPROVE = gql`
  query QUERY_COMPANIES_USER_CAN_APPROVE(
    $id: ID!
    $page: Int!
    $pageSize: Int
    $sort: String
    $q: String
  ) {
    getUser(query: { id: $id }) {
      roles
      canApprove(
        pagination: { page: $page, pageSize: $pageSize, sort: $sort }
        query: { q: $q }
      ) {
        page
        pageSize
        total
        rows {
          id
          tradeName
          cnpj
          company
        }
      }
    }
  }
`

export const GET_COMPANIES_USER_AVAILABLE_CAN_APPROVE = gql`
  query QUERY_COMPANIES_USER_AVAILABLE_CAN_APPROVE(
    $id: ID!
    $page: Int!
    $pageSize: Int
    $sort: String
    $q: String
  ) {
    getUser(query: { id: $id }) {
      availableCanApprove(
        pagination: { page: $page, pageSize: $pageSize, sort: $sort }
        query: { q: $q }
      ) {
        page
        pageSize
        total
        rows {
          id
          tradeName
          cnpj
          company
        }
      }
    }
  }
`

export const GET_USER_DOCUMENTS = gql`
  query GET_USER_DOCUMENTS($user: String!, $page: Int!, $pageSize: Int, $sort: String) {
    listDocuments(
      pagination: { page: $page, pageSize: $pageSize, sort: $sort }
      query: { user: $user }
    ) {
      page
      pageSize
      total
      rows {
        file {
          key
          name
        }
        type
        loans {
          result {
            id
            value
          }
        }
        createdAt
      }
    }
  }
`
