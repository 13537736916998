import React from 'react'
import PropTypes from 'prop-types'
import { withField } from '../Field'
import { onKeyDownNumber, onPasteNumber } from '../utils'
import { MuiInputStyled } from './style'

const getInputProps = (type, inputProps) => {
  if (type === 'number') {
    return {
      ...inputProps,
      onPaste: onPasteNumber,
      onKeyDown: onKeyDownNumber,
    }
  }

  return inputProps
}

const Input = ({ inputProps, ...props }) => (
  <MuiInputStyled {...props} inputProps={getInputProps(props.type, inputProps)} />
)

Input.defaultProps = {
  type: 'text',
  inputProps: {},
}

Input.propTypes = {
  type: PropTypes.string,
  inputProps: PropTypes.object,
}

export { Input }
export default withField(Input)
