import LogoIcon from '~assets/svg/logo-header.svg'
import abcLogoNegativePath from '~assets/svg/logo-abc-white.svg'
import paketaNegativeLogoPath from '~assets/svg/logo-full-white.svg'
import paketaWhiteLogoPath from '~assets/svg/logo-white.svg'
import { ReactComponent as LogoIconPaketa } from '~assets/svg/logo-short.svg'
import abcLogoPath, { ReactComponent as ABCLogo } from '~assets/svg/logo-abc.svg'
import NoImageLogoPath, { ReactComponent as NoImageLogo } from '~assets/svg/no-image.svg'

const defaulToast = {
  error: {
    main: '#FC5A5A',
  },
  warning: {
    main: '#FF974A',
    contrastText: '#FFF',
  },
  success: {
    main: '#00B894',
    contrastText: '#FFF',
  },
}

export const abcTheme = {
  name: 'Banco ABC Brasil',
  favicon: 'favicon-abc.png',
  logo: {
    main: abcLogoPath,
    negative: abcLogoPath,
    white: abcLogoNegativePath,
    short: ABCLogo,
    error: abcLogoNegativePath,
  },
  palette: {
    primary: {
      contrastText: '#FFF',
      dark: '#634D32',
      darkVariant: '#634D32',
      light: '#E4D9CB',
      main: '#A58154',
    },
    secondary: {
      main: '#000',
      light: '#000',
      contrastText: '#666',
      dark: '#000',
    },
    tertiary: {
      main: '#A58154',
      contrastText: '#222',
    },
  },
  toast: {
    error: {
      main: '#F2443E',
    },
    warning: {
      main: '#A58154',
      contrastText: '#FFF',
    },
    success: {
      main: '#32C069',
      contrastText: '#FFF',
    },
  },
}

export const paketaTheme = {
  name: 'Paketá - Backoffice',
  favicon: 'favicon-paketa.png',
  logo: {
    main: LogoIcon,
    negative: paketaNegativeLogoPath,
    white: paketaNegativeLogoPath,
    short: LogoIconPaketa,
    error: paketaWhiteLogoPath,
  },
  palette: {
    primary: {
      main: '#0062FF',
      dark: '#004AC2',
      darkVariant: '#0047BA',
      light: '#CCDFFF',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#44444F',
      light: '#92DDF3',
      contrastText: '#FFF',
      dark: '#192145',
    },
    tertiary: {
      main: '#00b894',
      contrastText: '#171725',
    },
  },
  toast: defaulToast,
}

export const defaultTheme = {
  name: 'Backoffice',
  favicon: 'favicon-default.png',
  logo: {
    main: NoImageLogoPath,
    negative: NoImageLogoPath,
    white: NoImageLogoPath,
    short: NoImageLogo,
    error: NoImageLogoPath,
  },
  palette: {
    primary: {
      main: '#DF4A64',
      dark: '#DF4A64',
      darkVariant: '#DF4A64',
      light: '#DF4A64',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#14222A',
      light: '#14222A',
      contrastText: '#14222A',
      dark: '#14222A',
    },
    tertiary: {
      main: '#00b894',
      contrastText: '#171725',
    },
  },
  toast: defaulToast,
}
