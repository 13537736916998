import React, { memo } from 'react'
import i18n from '~commons/i18n'
import { TimelineItemStyled, TimelineBodyStyled } from '../style'
import { WrapBadgeStyled, AuthorStyled } from './style'
import { String as StringUtils } from '@base39/ui-utils'

const Item = ({ user, text, date, onClick, hasClick }) => {
  const hasUser = !StringUtils.isEmpty(user)
  const renderBadge = (
    <WrapBadgeStyled onClick={onClick} hasClick={hasClick}>
      <svg viewBox="0 0 12 16" version="1.1" width="12" height="16" aria-hidden="true">
        <path
          fillRule="evenodd"
          // eslint-disable-next-line max-len
          d="M4 3H3V2h1v1zM3 5h1V4H3v1zm4 0L4 9h2v7h2V9h2L7 5zm4-5H1C.45 0 0 .45 0 1v12c0 .55.45 1 1 1h4v-1H1v-2h4v-1H2V1h9.02L11 10H9v1h2v2H9v1h2c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1z"
        ></path>
      </svg>
    </WrapBadgeStyled>
  )

  const renderBody = (
    <TimelineBodyStyled>
      {`${text} ${date}`}
      {hasUser && (
        <>
          &nbsp;{i18n.t('timeline.by')} <AuthorStyled>{user}</AuthorStyled>
        </>
      )}
    </TimelineBodyStyled>
  )

  return (
    <TimelineItemStyled>
      {renderBadge}
      {renderBody}
    </TimelineItemStyled>
  )
}

export default memo(Item)
