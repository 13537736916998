import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import Loading from '~components/Loading'
import { String as StringUtils } from '@base39/ui-utils'

const opened = []
let containerDomNode = null

const removeOpened = (id) => {
  if (!id) {
    return
  }

  const index = opened.indexOf(id)
  const hasItem = index > -1

  /* istanbul ignore else */
  if (hasItem) {
    opened.splice(index, 1)
  }
}

const hasOpened = () => opened.length > 0

const unmountComponent = () => {
  if (!hasOpened()) {
    unmountComponentAtNode(containerDomNode)

    document.body.removeChild(containerDomNode)

    containerDomNode = null
  }
}

const mountComponent = () => {
  if (!containerDomNode) {
    containerDomNode = document.createElement('div')
    containerDomNode.id = 'loading'

    document.body.appendChild(containerDomNode)

    render(<Loading full />, containerDomNode)
  }
}

const open = (options = {}) => {
  const uid = options.id || StringUtils.getUniqueId()

  opened.push(uid)

  mountComponent()

  return uid
}

const close = (id) => {
  if (!id) {
    return
  }

  removeOpened(id)
  unmountComponent()
}

export default {
  open,
  close,
}
