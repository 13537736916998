import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '~components'
import i18n from '~commons/i18n'
import { EmptyContentStyled, EmptySvgStyled } from './style'

const EmptyView = ({ label }) => (
  <EmptyContentStyled>
    <div>
      <EmptySvgStyled />
    </div>
    <div>
      <Typography color="inherit">{i18n.t(label)}</Typography>
    </div>
  </EmptyContentStyled>
)

EmptyView.defaultProps = {
  label: null,
}

EmptyView.propTypes = {
  label: PropTypes.string.isRequired,
}

export default memo(EmptyView)
