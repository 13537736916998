import { createTheme } from '@material-ui/core/styles'
import { Tenant } from '@base39/ui-utils'
import { abcTheme, paketaTheme, defaultTheme } from './themeSheet'

export const whiteLabelThemes = {
  abc: abcTheme,
  paketa: paketaTheme,
  default: defaultTheme,
}

export const tenant = Tenant.getTenant()
export const themeDefined = whiteLabelThemes[tenant] || whiteLabelThemes.default

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 340,
      md: 768,
      lg: 1280,
      xl: 1920,
    },
  },
  ...themeDefined,
  socialLogin: {
    background: '#FFF',
  },
  palette: {
    ...themeDefined.palette,
    ...themeDefined.toast,
    black: {
      0: '#000000',
    },
    gray: {
      0: '#b5b5be',
      100: '#92929d',
      200: '#E5E5E5',
      300: '#DBDDDE',
      400: '#e2e2ea',
      500: '#d5d5dc',
      600: '#4A4A4A',
    },
    white: {
      0: '#ffffff',
      100: '#fafafb',
      200: '#f1f1f5',
      300: '#f5f5f5',
      400: '#f8f8f8',
    },
    error: {
      main: '#E25335',
      contrastText: '#FFF',
    },
    warning: {
      main: '#FFC542',
      contrastText: '#555555',
    },
    success: {
      main: '#59C00B',
      contrastText: '#FFF',
    },
    scrollbar: {
      main: '#f7f7f7',
    },
    grayScale: {
      main: '#000',
      light: '#95AFC0',
      middle: '#575757',
    },
  },
  typography: {
    border: 'rgba(0, 0, 0, 0.42)',
    label: '#222D5C',
    text: '#4A4A4A',
  },
  card: {
    boxShadow: 'inset 0 -1px 0 0 #E2E2EA',
    borderRadius: '0.5em',
  },
  overrides: {
    MuiTableSortLabel: {
      icon: {
        marginLeft: 0,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-error': {
          color: 'rgb(34, 45, 92)',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        color: '#222D5C',
        textAlign: 'justify',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid rgba(34, 45, 93, 0.2)',
        },
      },
    },
    MuiInputBase: {
      input: {
        color: '#222D5C',
        fontWeight: '400',
        '&.Mui-disabled': {
          color: '#575757',
        },
      },
    },
    MuiTabs: {
      root: {
        minHeight: '40px',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiTab: {
      root: {
        minHeight: '40px',
      },
    },
    MuiCheckbox: {
      root: {
        marginRight: '3px',
      },
    },
    MuiDialogTitle: {
      root: {
        color: '#222D5C',
      },
    },
    MuiDialogContent: {
      root: {
        backgroundColor: '#fafafb',
      },
    },
  },
})

export default theme
