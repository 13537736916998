import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { CalendarToday } from '@material-ui/icons'
import { Select } from '@material-ui/core'

class DateInput extends PureComponent {
  render() {
    const { value } = this.props

    return (
      <Select
        {...this.props}
        renderValue={() => value}
        IconComponent={CalendarToday}
        open={false}
        fullWidth
      />
    )
  }
}

DateInput.defaultProps = {
  value: '',
}

DateInput.propTypes = {
  value: PropTypes.string,
}

export default DateInput
