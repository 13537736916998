import { lazy } from 'react'
import Layout from '~containers/Layout'

const GroupsContainer = lazy(() => import('~containers/Groups'))
const EmployeeSubheaderContainer = lazy(() => import('~containers/Groups/Subheader'))
const EmployeeListContainer = lazy(() => import('~containers/Groups/Sidebar/List'))
const EmptyViewContainer = lazy(() => import('~containers/Client/EmptyView'))
const RegistrationContainer = lazy(() => import('~containers/Groups/RegistrationInfo'))
const CreateContainer = lazy(() => import('~containers/Groups/Create'))

const INITIAL_PATH = '/grupos'

const groups = [
  {
    path: `${INITIAL_PATH}`,
    drawer: EmployeeListContainer,
    subheader: EmployeeSubheaderContainer,
    component: GroupsContainer,
    subroutes: [
      {
        path: `${INITIAL_PATH}`,
        component: EmptyViewContainer,
        exact: true,
      },
      {
        path: `${INITIAL_PATH}/:group/informacoes`,
        component: RegistrationContainer,
        exact: true,
      },
      {
        path: `${INITIAL_PATH}/cadastrar`,
        component: CreateContainer,
        exact: true,
      },
    ],
    layout: Layout,
  },
]

export default groups
