import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import { Tenant } from '@base39/ui-utils'

const tenant = Tenant.getTenant()
const configuration = {
  abc: {
    whitelist: ['abcbrasil.com.br'],
    organization: 'REACT_APP_AUTH0_ORGANIZATION_ABC',
    tenant: 'abc',
    connection: 'abc',
  },
  paketa: {
    whitelist: ['paketa.com.br'],
    organization: 'REACT_APP_AUTH0_ORGANIZATION_PAKETA',
    tenant: 'paketa',
    connection: 'paketa',
  },
}

const tenantConfiguration = configuration[tenant]

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      connection={tenantConfiguration.connection}
      organization={process.env[tenantConfiguration.organization]}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
