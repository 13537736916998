import { createGlobalStyle } from 'styled-components'
import { getSpacing } from './mixing'

const globalStyle = createGlobalStyle`
  * {
    outline: none;
    box-sizing: border-box;
  }

  html {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  body {
    line-height: normal;
  }

  html,
  body,
  #root {
    width: 100%;
    min-height: 100%;
    display: flex;
    background-color: #fff;
  }
  .fullHeight {
    height: 100%;
  }
  strong,
  b {
    font-weight: 500;
  }

  .flex {
    display: flex;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-center {
    justify-content: center;
  }

  .flex-end {
    justify-content: flex-end;
  }

  .overflow-y {
    overflow-y: auto;
  }
  .fullHeight {
    height: 100%;
  }
  .fullWidth{
    width: 100%;
  }

  .relative {
    position: relative;
  }

  ${getSpacing('margin', 'm')}
  ${getSpacing('padding', 'p')}

`

export default globalStyle
