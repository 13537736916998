import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from 'apollo-link-context'
import { Tenant } from '@base39/ui-utils'

const uri = `${process.env.REACT_APP_API3_URL}/graphql`

const httpLink = new HttpLink({ uri })

const authMiddleware = setContext(() => {
  const token = sessionStorage.getItem('token')

  return {
    headers: {
      access_token: token,
      authorization: token,
      'x-tenant-id': Tenant.getTenant(),
    },
  }
})

const client = new ApolloClient({
  // @ts-ignore
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false }),
})

export default client
