import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Fab } from '@material-ui/core'

const getFloat = ({ theme, float }) =>
  float &&
  css`
    && {
      position: fixed;
      right: ${theme.spacing(2)}px;
      bottom: ${theme.spacing(2)}px;
      z-index: ${theme.zIndex.mobileStepper};
    }
  `

const FabStyled = styled(Fab)`
  ${getFloat}
`

FabStyled.propTypes = {
  float: PropTypes.bool,
}

export default FabStyled
