import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import i18n from '~commons/i18n'
import theme from '~commons/constants/theme'
import NavigationMenu from './NavigationMenu'
import {
  NavbarStyled,
  AppBarStyled,
  LogoStyled,
  ToolbarStyled,
  DividerStyled,
  SpaceBetweenStyled,
} from './style'

const Navbar = (props) => {
  const { children } = props

  return (
    <>
      <NavbarStyled>
        <AppBarStyled>
          <ToolbarStyled>
            <Link to="/empresas">
              <LogoStyled src={theme.logo.main} alt={i18n.t('seo.logo.alt')} />
            </Link>

            <DividerStyled className="mh-5" />

            <NavigationMenu {...props} />

            <SpaceBetweenStyled />

            {children}
          </ToolbarStyled>
        </AppBarStyled>
      </NavbarStyled>
    </>
  )
}

Navbar.propTypes = {
  children: PropTypes.node,
}

export { Navbar }
export default memo(withRouter(Navbar))
