import { gql } from '@apollo/client'

export const LIST_CREDIT_POLICIES = gql`
  query QUERY_CREDIT_POLICIES($page: Int!, $pageSize: Int, $q: String) {
    listCreditPolicies(
      pagination: { page: $page, pageSize: $pageSize }
      query: { name: $q }
    ) {
      rows {
        id
        revisionGroup
        name
        rules {
          minWorkingMonths
          maxWorkingMonths
          salaryQtd
          minCreditRequest
          fund {
            id
            name
          }
          monthlyAndRegisterFee {
            minMonth
            maxMonth
            monthlyFee
            registerFee
            insuranceType
            id
          }
          financialInstitution {
            name
            id
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`
