import React from 'react'
import i18n from '~commons/i18n'
import { Table, TableBody, TableRow, TablePagination } from '@material-ui/core'
import { LabelPaginationStyled, WrapPaginationStyled } from './style'

const Pagination = ({ show, handleChange, queryParams, params, rows, count }) => {
  const { page, rowsPerPage } = params

  const handleChangePage = (_, newPage) => {
    handleChange({
      ...queryParams,
      page: newPage,
    })
  }
  const handleChangeRowsPerPage = (ev) => {
    handleChange({
      ...queryParams,
      page: 0,
      rowsPerPage: ev.target.value,
    })
  }

  const renderLabelCountPagination = ({ from, to }) => (
    <LabelPaginationStyled>
      {from}-{to} {i18n.t('general.of')} {count}
    </LabelPaginationStyled>
  )

  const renderPagination = () => (
    <TablePagination
      rowsPerPageOptions={[6, 12, 24]}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelDisplayedRows={renderLabelCountPagination}
      labelRowsPerPage={
        <LabelPaginationStyled>{i18n.t('general.rowsPerPage')}</LabelPaginationStyled>
      }
    />
  )
  const canRenderPagination = () => {
    if (!show) {
      return null
    }

    return (
      <WrapPaginationStyled>
        <Table>
          <TableBody>
            <TableRow>{!!rows.length && renderPagination()}</TableRow>
          </TableBody>
        </Table>
      </WrapPaginationStyled>
    )
  }

  return canRenderPagination()
}

export default Pagination
