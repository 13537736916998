import React, { memo, useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import Apps from '@material-ui/icons/Apps'
import ClickAwayListener from '../ClickAwayListener'
import MenuList from '../MenuList'
import ListItemIcon from '../ListItemIcon'
import Popper from '../Popper'
import Grow from '../Grow'
import IconButton from '../IconButton'
import Paper from '../Paper'
import { ItemContentStyled, ItemLinkStyled, ItemNameStyled } from './style'

function MenuOptions({ options, history }) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const { pathname } = history.location

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const renderItem = (item) => {
    const { name, link, icon } = item

    const renderIcon = () => {
      if (!icon) return null
      return <ListItemIcon classes={{ root: 'menu-icon' }}>{icon}</ListItemIcon>
    }

    const renderLink = () => {
      const active = pathname === link

      return (
        <ItemLinkStyled onClick={() => history.push(link)} active={active}>
          {renderIcon()}
          <ItemNameStyled>{name}</ItemNameStyled>
        </ItemLinkStyled>
      )
    }

    return <ItemContentStyled key={`menu-name-${name}`}>{renderLink()}</ItemContentStyled>
  }
  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Apps />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {options.map(renderItem)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default memo(withRouter(MenuOptions))
