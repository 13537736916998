import React, { Fragment } from 'react'
import { Router } from 'react-router-dom'
import { QueryClient } from 'react-query'
import { ApolloProvider } from '@apollo/client'
import { SocketIOProvider } from 'use-socketio'
import { StylesProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import { Router as RouterUtils } from '@base39/ui-utils'
import { QueryClientProvider } from 'react-query'
import GlobalStyle from '~commons/styles/global-style'
import ThemeProvider from '~components/ThemeProvider'
import DatadogRum from '~commons/utils/datadog'
import Main from './containers/Main'
import 'react-toastify/dist/ReactToastify.css'
import { StoreProvider, createStore, persist } from 'easy-peasy'
import model from '~models'
import SocketConfig from '~commons/constants/socket-config'
import { Intercom } from '~components'
import PaketaProvider from './containers/PaketaProvider'
import './commons/i18n'
import client from './resources/api/graphql'

const store = createStore(persist(model, { storage: sessionStorage }))

DatadogRum.init()
const queryClient = new QueryClient()

const App = () => {
  return (
    <>
      <SocketIOProvider {...SocketConfig}>
        <ApolloProvider client={client}>
          <StoreProvider store={store}>
            <QueryClientProvider client={queryClient}>
              <StylesProvider injectFirst>
                <ThemeProvider>
                  {(props) => (
                    <Fragment>
                      <CssBaseline />
                      <GlobalStyle />
                      <PaketaProvider {...props}>
                        <Intercom>
                          <Router history={RouterUtils.history}>
                            <Main />
                          </Router>
                        </Intercom>
                      </PaketaProvider>
                    </Fragment>
                  )}
                </ThemeProvider>
              </StylesProvider>
            </QueryClientProvider>
          </StoreProvider>
        </ApolloProvider>
      </SocketIOProvider>
    </>
  )
}

export default App
