/* eslint-disable */
import { gql } from '@apollo/client'

export const LIST_CREDIT_POLICIES = gql`
  query QUERY_LIST_CREDIT_POLICIES(
    $page: Int!
    $pageSize: Int!
    $sort: String
    $q: String
  ) {
    listCreditPolicies(
      pagination: { page: $page, pageSize: $pageSize, sort: $sort }
      query: { name: $q }
    ) {
      total
      totalPages
      page
      pageSize
      rows {
        id
        name
        revisionGroup
        type
        createdAt
        updatedAt
      }
    }
  }
`
