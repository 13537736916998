import styled from 'styled-components'
import IconButton from '../IconButton'

export const IconButtonStyled = styled(IconButton)`
  background: #fff;
  border-radius: 0;

  :hover {
    border-color: rgba(0, 0, 0, 0.87);
  }

  :focus,
  :active {
    border-color: #00b8ec;
  }
`
