import React from 'react'
import { withField } from '../Field'
import MuiInput from '@material-ui/core/Input'
import { SelectCoreStyled } from './style'

const Select = ({ ...props }) => {
  const renderOptions = (options) =>
    options?.map(({ value, label }) => (
      <option value={value} key={value}>
        {label}
      </option>
    ))

  const renderSelectOptions = (
    <SelectCoreStyled {...props} native input={<MuiInput />}>
      <option value="" />
      {renderOptions(props?.options)}
    </SelectCoreStyled>
  )

  const renderDefaultSelect = <SelectCoreStyled {...props} native input={<MuiInput />} />

  return props.options ? renderSelectOptions : renderDefaultSelect
}

// eslint-disable-next-line react/display-name
const SelectItem = React.forwardRef((props, ref) => <option {...props} ref={ref} />)

export { Select, SelectItem }
export default withField(Select)
