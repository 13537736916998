import { action } from 'easy-peasy'
import withSearchParams from './withSearchParams'

const proposalModel = {
  refetchedAt: null,
  reload: false,
  setReload: action((state, payload) => {
    state.reload = payload
  }),
  setRefetchedAt: action((state, payload) => {
    state.refetchedAt = payload
  }),
}

export default withSearchParams({
  ...proposalModel,
  searchParams: { sort: '-requestDate', direction: 'desc' },
})
