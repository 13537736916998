import React from 'react'
import PropTypes from 'prop-types'
import { GridStyled, IconStyled, Title, Description } from './style'

const Placeholder = ({
  icon: Icon,
  title,
  description,
  color,
  type,
  position,
  className,
}) => (
  <GridStyled
    container
    alignItems="center"
    direction="column"
    justifyContent="center"
    position={position}
    className={className}
  >
    {title && <Title color={color}>{title}</Title>}
    <IconStyled type={type} color={color}>
      <Icon fontSize="large" />
    </IconStyled>
    {description && <Description type={type}>{description}</Description>}
  </GridStyled>
)

Placeholder.defaultProps = {
  color: undefined,
  type: 'default',
  position: 'static',
  className: undefined,
}

Placeholder.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  color: PropTypes.oneOf(['primary', 'secondary', 'brand', 'empty']),
  type: PropTypes.oneOf(['default', 'small']),
  position: PropTypes.oneOf(['static', 'absolute']),
  className: PropTypes.string,
}

export default Placeholder
