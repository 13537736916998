import * as yup from 'yup'
import locale from './locale'
import { phone } from './phone'
import { zipCode } from './zip-code'
import cnpj from './cnpj'
import cpf from './cpf'
import size from './size'
import identificationDocument from './identification-document'
import preventNotType from './preventNotType'
import { yup as YupUtils } from '@base39/ui-utils'

yup.setLocale(locale)

yup.addMethod(yup.string, 'phone', phone)
yup.addMethod(yup.string, 'ddd', YupUtils.string().ddd)
yup.addMethod(yup.string, 'brazilPhone', YupUtils.string().brazilPhone)
yup.addMethod(yup.string, 'companyNumber', YupUtils.string().companyNumber)
yup.addMethod(yup.string, 'zipCode', zipCode)
yup.addMethod(yup.string, 'cnpj', cnpj)
yup.addMethod(yup.string, 'cpf', cpf)
yup.addMethod(yup.string, 'identificationDocument', identificationDocument)
yup.addMethod(yup.number, 'size', size)
yup.addMethod(yup.number, 'preventNotType', preventNotType)
yup.addMethod(yup.string, 'preventNotType', preventNotType)

export * from 'yup'
export default yup
