import { getIn } from 'formik'
import i18n from '~commons/i18n'
import { List as ListUtils } from '@base39/ui-utils'

const getFieldProps = (props) => {
  const { field, form, ...otherProps } = props
  const { touched, errors } = form
  const error = getIn(touched, field.name) && getIn(errors, field.name)
  const hasError = !!error
  const helperText = hasError ? i18n.t(error) : null

  return {
    ...field,
    ...otherProps,
    helperText,
    error: hasError,
  }
}

const getSwitchProps = ({ field, ...props }) => ({
  ...field,
  ...props,
  value: field.value,
  checked: field.value,
})

const getOtherProps = (props) => {
  const newProps = { ...props }

  delete newProps.field
  delete newProps.form

  return newProps
}

const areEqual = (prevProps, nextProps) => {
  const field = nextProps.field.name
  const test = (type) =>
    getIn(prevProps.form[type], field) !== getIn(nextProps.form[type], field)
  const diffValue = prevProps.field.value !== nextProps.field.value
  const fields = ['errors', 'touched', 'isSubmitting']

  if (diffValue || fields.some(test)) {
    return false
  }

  const prevOtherProps = getOtherProps(prevProps)
  const nextOtherProps = getOtherProps(nextProps)

  return ListUtils.hasShallowDiff(prevOtherProps, nextOtherProps)
}

export { getFieldProps, getSwitchProps, areEqual }
