import React, { memo, useState } from 'react'
import i18n from '~commons/i18n'
import { Object as ObjectUtils } from '@base39/ui-utils'
import { TableRow, TableCell } from '@material-ui/core'
import { Tooltip, DataSheet, Loading } from '~components'
import Refresh from '~components/Refresh'
import TableHeader from './TableHeader'
import Pagination from './Pagination'
import {
  stableSort,
  getComparator,
  getDefaultSortWithoutDirection,
  getDefaultDirection,
} from './utils'
import {
  TableRowBodyStyled,
  TypographyStyled,
  TableBodyStyled,
  TableContainerStyled,
  PaperWrapTableStyled,
  TableCellStyled,
} from './style'

const CustomTable = ({
  filter,
  headers,
  rows,
  page: defaultPage,
  onRowSelected,
  rowsPerPage: defaultRowsPerPage,
  sort: defaultSort,
  onChange,
  count,
  loading,
  hasPagination,
  size,
  padding,
  wordBreak,
  maxHeight,
  elevation,
  onCellsSelected,
  initialRows,
  skipRender,
  sortDisabled,
  pagination,
}) => {
  const [queryParams, setQueryParams] = useState({
    page: defaultPage,
    rowsPerPage: defaultRowsPerPage,
    sort: getDefaultSortWithoutDirection(defaultSort),
    direction: getDefaultDirection(defaultSort),
  })

  const { page, rowsPerPage, sort, direction } = queryParams

  const handleFilterChange = (ev) => {
    handleChange({
      ...queryParams,
      [ev.target.name]: ev.target.value,
      page: 0,
    })
  }

  const handleChange = ({ page, rowsPerPage, sort, direction, ...params }) => {
    setQueryParams({ page, rowsPerPage, sort, direction, ...params })

    const newRows = initialRows
      ? stableSort(initialRows, getComparator(direction, sort.replace('-', '')))
      : rows

    onChange(
      {
        page,
        rowsPerPage,
        sort: (direction === 'desc' ? '-' : '') + getDefaultSortWithoutDirection(sort),
        direction,
        ...params,
      },
      newRows,
    )
  }

  const renderBodyRowCells = (row) =>
    headers.map(({ value, align, bold }, index) => (
      <TableCellStyled key={index} align={align} bold={bold}>
        {row.renderContent?.(row, value) || ObjectUtils.get(row, value)}
      </TableCellStyled>
    ))

  const handleBodyRowClick = (row) => onRowSelected?.(row)

  const renderBodyRow = (row, index) => (
    <TableRowBodyStyled
      key={index}
      disabled={row.disabled}
      backgrounddisabled={row.bkgColor}
      $onRowSelected={onRowSelected}
      onClick={() => handleBodyRowClick(row)}
    >
      {renderBodyRowCells(row)}
    </TableRowBodyStyled>
  )

  const renderBodyCell = (row, index) => {
    const cellMainContent = (row, index) => renderBodyRow(row, index)
    return row.tooltip ? (
      <Tooltip title={row.tooltip} placement="top">
        {cellMainContent(row, index)}
      </Tooltip>
    ) : (
      cellMainContent(row, index)
    )
  }

  const renderBody = () => <TableBodyStyled>{rows.map(renderBodyCell)}</TableBodyStyled>

  const renderEmpty = () => (
    <TableBodyStyled>
      <TableRow>
        <TableCell colSpan={headers.length}>
          <div className="p-4" align="center">
            {!onCellsSelected && <Refresh refresh={handleRefresh} />}
            <TypographyStyled>{i18n.t('general.empty')}</TypographyStyled>
          </div>
        </TableCell>
      </TableRow>
    </TableBodyStyled>
  )

  const canRenderBody = () => {
    if (rows.length < 1) {
      return renderEmpty()
    }
    return renderBody()
  }

  const handleRefresh = () => {
    handleChange(queryParams)
  }

  const renderFilter = () =>
    filter && filter({ onChange: handleFilterChange, refresh: handleRefresh })

  const renderContent = () => canRenderBody()

  const renderHeader = () => (
    <TableHeader
      headers={headers}
      handleChange={handleChange}
      queryParams={queryParams}
      pagination={{ direction, sort }}
      sortDisabled={sortDisabled}
    />
  )

  const renderDefaultTable = () => (
    <>
      {renderFilter()}
      <PaperWrapTableStyled maxheight={maxHeight} elevation={elevation}>
        {loading ? (
          <Loading height="300px" />
        ) : (
          <TableContainerStyled size={size} padding={padding} wordbreak={wordBreak}>
            {renderHeader()}
            {renderContent()}
          </TableContainerStyled>
        )}
      </PaperWrapTableStyled>
      {pagination && (
        <Pagination
          show={hasPagination}
          handleChange={handleChange}
          queryParams={queryParams}
          params={{ page, rowsPerPage }}
          rows={rows}
          count={count}
        />
      )}
    </>
  )

  const renderSheetTable = () => (
    <>
      <DataSheet
        renderHeader={renderHeader}
        rows={rows}
        renderBody={{ canRenderBody, renderEmpty }}
        skipRender={skipRender}
        onCellsSelected={onCellsSelected}
      />
    </>
  )

  return onCellsSelected ? renderSheetTable() : renderDefaultTable()
}

CustomTable.defaultProps = {
  headers: [],
  rows: [],
  rowsPerPage: 10,
  sort: '-createdAt',
  page: 0,
  count: 0,
  loading: false,
  onChange: () => undefined,
  onRowSelected: undefined,
  hasPagination: true,
  size: 'medium',
  padding: 'normal',
  wordBreak: 'break-word',
  maxHeight: undefined,
  elevation: 1,
  pagination: true,
}

export default memo(CustomTable)
