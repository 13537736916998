import { action, thunk } from 'easy-peasy'
import ApolloClient from '~resources/api/graphql'
import { GET_ME } from '~graphql/User/query'
import withSearchParams from './withSearchParams'

const userModel = {
  user: null,
  initializeUser: thunk(async (actions) => {
    const user = await ApolloClient.query({ query: GET_ME })
    actions.setUser(user.data.me)
  }),
  setUser: action((state, payload) => {
    state.user = payload
  }),
}

export default withSearchParams({ ...userModel, searchParams: { sort: 'name' } })
