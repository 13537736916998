import styled, { css } from 'styled-components'
import { Grid } from '@material-ui/core'

const getColor = ({ theme, color }) =>
  color === 'empty' ? '#999' : theme.palette[color].main

const GridStyled = styled(Grid)`
  && {
    ${({ position }) => css`
      position: ${position};
      ${position === 'absolute' &&
      css`
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      `}
    `}
    height: 100%;
    flex: 1;
    padding: ${({ theme }) => theme.spacing()}px;
  }
`

const IconStyled = styled.div`
  color: ${getColor};

  & svg {
    width: 80px;
    height: 80px;

    ${({ theme, type }) =>
      type === 'small' &&
      css`
        width: 48px;
        height: 48px;
        margin-bottom: ${theme.spacing()}px;
      `}
  }
`

const Title = styled.span`
  color: #b6b8bd;
  font-size: 0.875rem;
  font-weight: 500;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`

const Description = styled.div`
  color: #999999;
  max-width: 300px;
  font-size: 16px;
  text-align: center;

  ${({ type }) =>
    type === 'small' &&
    css`
      font-size: 14px;
    `}
`

IconStyled.defaultProps = {
  color: 'primary',
}

export { GridStyled, IconStyled, Title, Description }
