import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Router } from '@base39/ui-utils'
import Loading from '~components/Loading'

const Auth = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect()
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently])

  useEffect(() => {
    if (isAuthenticated) {
      Router.push('/empresas')
    }
  }, [isAuthenticated])

  return <Loading />
}

export default Auth
