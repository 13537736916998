import styled, { css } from 'styled-components'
import IconButton from '../IconButton'
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspace'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { ContainedType } from './interfaces'

export const ContentStyled = styled.div<ContainedType>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ contained }) =>
    contained &&
    css`
      width: fit-content;
    `}
`
export const IconStyled = styled(IconButton)`
  svg {
    font-size: 1.5rem;
  }
`

export const ArrowBackIconStyled = styled(KeyboardBackspaceRoundedIcon)`
  display: flex;
  cursor: pointer;
  path {
    fill: #0984e3;
  }
`

export const ContainedIconStyled = styled(ArrowBackIcon)`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 1.75rem;
  padding: 5px;
  border-radius: 6px;
`

export const TextStyled = styled.p<ContainedType>`
  ${({ contained }) =>
    contained &&
    css`
      cursor: pointer;
      color: ${({ theme }) => theme.palette.primary.main};
      font-size: 0.875rem;
      margin-left: 8px;
    `}
`
