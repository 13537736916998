import React, { memo, useState, useEffect, useCallback } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import i18n from '~commons/i18n'
import Menu, { MenuItem } from '~components/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { GET_ME } from '~graphql/User/query'
import { useLazyQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { IconButtonStyled } from './style'

const UserMenu = () => {
  const { logout } = useAuth0()
  const { setUser } = useStoreActions(({ user: { setUser } }) => ({ setUser }))
  const user = useStoreState(({ user: { user } }) => user)

  const [anchorEl, setAnchorEl] = useState(null)

  const onCompleted = useCallback(
    ({ me }) => {
      setUser(me)
    },
    [setUser],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleLogout = useCallback(() => {
    sessionStorage.clear()
    client?.resetStore()
    setUser(null)
    handleClose()
    logout()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUser, handleClose])

  const onError = useCallback(() => {
    handleLogout()
    // eslint-disable-next-line no-use-before-define
  }, [handleLogout])

  const [getUser, { client }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'network-only',
    onCompleted,
    onError,
  })

  useEffect(() => {
    !user && getUser()
  }, [user, getUser])

  const handleClick = (ev) => {
    setAnchorEl(ev.currentTarget)
  }

  return (
    <>
      <IconButtonStyled
        aria-controls="menu-appbar"
        aria-haspopup="true"
        size="small"
        onClick={handleClick}
        data-testid="header-profile"
      >
        <AccountCircle />
      </IconButtonStyled>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>{i18n.t('menu.logout')}</MenuItem>
      </Menu>
    </>
  )
}

export default memo(UserMenu)
