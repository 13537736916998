import { ApiSls } from './api'

const path = '/companies'

const Company = {
  getCompanies(params) {
    return ApiSls.customRequest(path, { params, method: 'GET' })
  },
  get(id) {
    return ApiSls.customRequest(`${path}/${id}`, { method: 'GET' })
  },
  create(data) {
    return ApiSls.customRequest(path, { method: 'POST', data })
  },
  update(id, data) {
    return ApiSls.customRequest(`${path}/${id}`, { method: 'PUT', data })
  },
  getEmployees(id, params) {
    return ApiSls.customRequest(`${path}/${id}/employees`, {
      params,
      method: 'GET',
    })
  },
}

export default Company
