import React from 'react'
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core'
import { getDefaultSortWithoutDirection, getDirection } from './utils'
import { TypographyStyled } from './style'

const TableHeader = ({
  headers,
  handleChange,
  queryParams,
  pagination,
  sortDisabled,
}) => {
  const { direction, sort } = pagination

  const handleSort = (value) => {
    handleChange({
      ...queryParams,
      sort: value,
      page: 0,
      direction: getDirection(value, direction, sort),
    })
  }

  const headerMainContent = (label) => <TypographyStyled>{label}</TypographyStyled>

  const renderContent = (label, value, noSortable) =>
    noSortable ? (
      headerMainContent(label)
    ) : (
      <TableSortLabel
        direction={direction}
        active={getDefaultSortWithoutDirection(sort) === value && !sortDisabled}
        onClick={() => handleSort(value)}
      >
        {headerMainContent(label)}
      </TableSortLabel>
    )

  return (
    <TableHead>
      <TableRow>
        {headers.map(({ label, value, align, noSortable }, index) => (
          <TableCell key={index} align={align}>
            {renderContent(label, value, noSortable)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default TableHeader
