import React, { memo } from 'react'
import TimelineStep from './TimelineStep'
import { String as StringUtils } from '@base39/ui-utils'
import { TimelineStyled } from './style'

const Timeline = ({ steps, fields, onClick, comments, displayUser }) => {
  const renderStep = (step, i) => (
    <TimelineStep
      onClick={() => onClick && onClick(i)}
      hasClick={!StringUtils.isEmpty(onClick)}
      key={`${step[fields.status]}_${i}`}
      color={step[fields.color]}
      text={step[fields.status]}
      date={step[fields.date]}
      document={step.document}
      comments={comments}
      displayUser={displayUser}
    />
  )

  return (
    <TimelineStyled comments={comments}>{steps && steps.map(renderStep)}</TimelineStyled>
  )
}

Timeline.defaultProps = {
  comments: true,
  displayUser: true,
}

export default memo(Timeline)
