import drawer from './drawer'
import form from './form'
import general from './general'
import registrationInfo from './registration-info'
import date from './date'
import documentUpload from './document-upload'
import creditPolicy from './credit-policy'
import groups from './groups'
import home from './home'
import proposal from './proposal'
import simulation from './simulation'
import employee from './employee'
import people from './people'
import errorBoundary from './errorBoundary'
import company from './company'
import financial from './financial'
import timeline from './timeline'
import movements from './movements'
import menu from './menu'
import causesOfRejection from './causes-of-rejection'
import seo from './seo'
import user from './user'
import fund from './fund'

export default {
  form,
  general,
  groups,
  home,
  date,
  drawer,
  registrationInfo,
  documentUpload,
  proposal,
  simulation,
  employee,
  creditPolicy,
  people,
  errorBoundary,
  company,
  timeline,
  causesOfRejection,
  financial,
  movements,
  seo,
  menu,
  user,
  fund,
}
