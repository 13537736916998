import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from '~commons/i18n'
import { Money as MoneyUtils } from '@base39/ui-utils'
import { Paper, Grid } from '~components'
import InfoIcon from '@material-ui/icons/Info'
import Popover from '../Popover'
import { RefundLoanInfoStyled, IconButtonStyled, PaperStyled } from './style'

function CardsValues(props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const { maxInstallmentValue, minCreditRequest, maxCreditAvailable, acquittanceLoans } =
    props

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getSum = MoneyUtils.formatCurrency(
    acquittanceLoans?.reduce((acc, item) => acc + item.value, 0),
  )

  const renderInfo = () => (
    <>
      <IconButtonStyled variant="contained" color="primary" onClick={handleClick}>
        <InfoIcon />
      </IconButtonStyled>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <RefundLoanInfoStyled container spacing={2}>
          {i18n.t('simulation.refinancingMaxValueInfoStart')}
          {getSum}
          {i18n.t('simulation.refinancingMaxValueInfoEnd')}
        </RefundLoanInfoStyled>
      </Popover>
    </>
  )

  return (
    <Grid container spacing={2} className="mt-2">
      <Grid item xs>
        <Paper className="p-2" style={{ textAlign: 'center' }}>
          <h4 className="m-0">{i18n.t('simulation.payableMargin')}</h4>
          <div>{MoneyUtils.formatCurrency(maxInstallmentValue ?? 0)}</div>
        </Paper>
      </Grid>
      <Grid item xs>
        <Paper className="p-2" style={{ textAlign: 'center' }}>
          <h4 className="m-0">{i18n.t('simulation.minValue')}</h4>
          <div>{MoneyUtils.formatCurrency(minCreditRequest ?? 0)}</div>
        </Paper>
      </Grid>
      <Grid item xs>
        <PaperStyled className="p-2" style={{ textAlign: 'center' }}>
          <div>
            <h4 className="m-0">{i18n.t('simulation.maxValue')}</h4>
            <div>{MoneyUtils.formatCurrency(maxCreditAvailable ?? 0)}</div>
          </div>
          {acquittanceLoans?.length ? renderInfo() : ''}
        </PaperStyled>
      </Grid>
    </Grid>
  )
}

CardsValues.propTypes = {
  maxInstallmentValue: PropTypes.string.isRequired,
  minCreditRequest: PropTypes.string.isRequired,
  maxCreditAvailable: PropTypes.string.isRequired,
  firstDueDate: PropTypes.string.isRequired,
  daysToIncrease: PropTypes.string.isRequired,
}

export default memo(CardsValues)
