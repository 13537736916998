import styled, { css } from 'styled-components'
import ArrowDownIcon from '~assets/svg/arrow-down.svg'

const days = css`
  .react-datepicker {
    &__day {
      border-radius: 50%;

      &--today {
        color: ${({ theme }) => theme.palette.secondary.dark};
      }

      &--in-selecting-range,
      &--selecting-range-end,
      &--in-range {
        background: #fff;
        color: ${({ theme }) => theme.typography.label};

        :hover {
          background: ${({ theme }) => theme.palette.secondary.dark};
          color: ${({ theme }) => theme.palette.secondary.contrastText};
        }
      }
    }

    &__day--range-start,
    &__day--keyboard-selected {
      background: #fff;
      color: ${({ theme }) => theme.typography.label};

      :hover,
      :focus {
        outline: none;
        border-radius: 50%;
      }
    }

    &__day--selected {
      background: ${({ theme }) => theme.palette.secondary.main};
      color: ${({ theme }) => theme.palette.secondary.contrastText};

      :hover,
      :focus {
        outline: none;
        border-radius: 50%;
      }
    }

    &__month-select,
    &__year-select {
      display: block;
      padding: 0.6em 1.4em 0.5em 0.8em;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      border: 1px solid #efefef;
      border-radius: 0.5em;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      background: url(${ArrowDownIcon}) no-repeat;
      background-position: right 0.7em top 50%, 0 0;
      background-size: 0.65em auto, 100%;
    }

    &__month-select,
    &__year-select::-ms-expand {
      display: none;
    }

    &__month-select,
    &__year-select option {
      font-weight: normal;
    }

    &__month-select,
    &__year-select:focus {
      outline: none;
    }
  }
`

export const DatePickerStyled = styled.div`
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;

    .MuiFormControl-root {
      width: 100%;
    }
  }

  .react-datepicker-popper {
    z-index: 100;
    ${({ dialog }) => (dialog ? 'position: relative !important;' : '')}
    ${({ dialog }) => (dialog ? 'transform: inherit !important;' : '')}
  }

  .react-datepicker {
    .react-datepicker__header .react-datepicker__current-month {
      display: none;
    }

    font-family: inherit;
    padding: ${({ theme }) => theme.spacing(2)}px;

    &__input-container {
      width: 100%;
      margin-top: ${({ theme }) => theme.spacing(2)}px;

      .MuiInputBase-root {
        color: ${({ theme }) => theme.palette.secondary.main};
      }
    }

    &__header {
      border-bottom: 0;
      background: #fff;
    }

    &__day-name {
      color: #828282;
    }

    &__navigation {
      width: 20px;
      height: 20px;
      top: 25px;

      &--previous {
        outline: none;
        border-top: 10px solid transparent;
        border-right: 14px solid #222d5c;
        border-bottom: 10px solid transparent;
        margin-left: ${({ theme }) => theme.spacing(2)}px;
      }

      &--next {
        outline: none;
        margin-right: 5px;
        border-top: 10px solid transparent;
        border-left: 14px solid #222d5c;
        border-bottom: 10px solid transparent;
      }
    }

    .react-datepicker__month--selecting-range {
      ${days}
    }

    ${days}
  }

  .react-datepicker__portal .react-datepicker__current-month {
    font-weight: normal;
    font-size: 1rem;
  }

  .react-datepicker__portal .react-datepicker__navigation {
    border: none;
  }

  + .MuiFormHelperText-root:last-child {
    display: none;
  }
`

export const WrapHeaderStyled = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing(1)}px;
`

export const DatePickerFooterStyled = styled.div`
  text-align: right;
`

export const DatePeriodPickerStyled = styled(DatePickerStyled)`
  display: flex;
  padding: 0;
  background: #fff;
  align-items: center;

  > div {
    width: 100%;

    ${({ theme }) => `
      ${theme.breakpoints.down('md')} {
        min-width: 40%;
      }
    `}
  }

  .MuiSelect-select {
    min-width: auto;

    &.MuiSelect-select {
      padding-right: 0;
    }
  }

  .MuiSelect-icon {
    position: relative;
    margin: 0 ${({ theme }) => theme.spacing(1)}px;
  }

  .MuiInputBase-root {
    cursor: pointer;
  }
`

export const TextStyled = styled.span`
  color: #8c8c8c;
  padding: ${({ theme }) => theme.spacing(1)}px;
  text-transform: lowercase;
`

export const InputYearStyled = styled.input`
  width: 50px;
`

const HeaderArrowStyled = css`
  width: 15px;
  height: 24px;
  outline: none;
  padding: 0;
  background: none;
  cursor: pointer;
  opacity: 0.8;
  margin-top: 3px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  transition: all 0.2s;

  :hover {
    opacity: 1;
  }
`

export const LeftArrowStyled = styled.button`
  ${HeaderArrowStyled}

  border-right: 10px solid #222d5c;
  margin-right: 8px;
  border-left: 0;
`

export const RightArrowStyled = styled.button`
  ${HeaderArrowStyled}

  border-left: 10px solid #222d5c;
  margin-left: 8px;
  border-right: 0;
`
