import React, { Suspense, memo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import routes from '~routes'
import Loading from '~components/Loading'
import CloseButton from '~components/Snackbar/SnackbarClose'
import AppRoute from './AppRoute'
import { ContainerStyled } from './style'

const Main = () => (
  <ContainerStyled>
    <Suspense fallback={<Loading />}>
      <Switch>
        {routes.map((route) => (
          <AppRoute key={route.path} {...route} />
        ))}
        <Route path="*" component={() => <Redirect from="*" to="/" />} />
      </Switch>
    </Suspense>
    <ToastContainer className="toast-position" closeButton={<CloseButton />} />
  </ContainerStyled>
)

export default memo(Main)
