import { lazy } from 'react'
import Layout from '~containers/Layout'

const ClientContainer = lazy(() => import('~containers/Client'))
const ConfigsSubheaderContainer = lazy(() =>
  import('~containers/Configs/Funds/Subheader'),
)
const ConfigsListContainer = lazy(() => import('~containers/Configs/Funds/Sidebar/List'))
const EmptyViewContainer = lazy(() => import('~containers/Configs/Funds/'))
const EditFundContainer = lazy(() => import('~containers/Configs/Funds/Edit'))

const INITIAL_PATH = '/fundos'

const customers = [
  {
    path: `${INITIAL_PATH}`,
    drawer: ConfigsListContainer,
    subheader: ConfigsSubheaderContainer,
    component: ClientContainer,
    subroutes: [
      {
        path: `${INITIAL_PATH}`,
        component: EmptyViewContainer,
        exact: true,
      },
      {
        path: `${INITIAL_PATH}/:id`,
        component: EditFundContainer,
        exact: true,
      },
    ],
    layout: Layout,
  },
]

export default customers
