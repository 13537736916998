import { lazy } from 'react'
import Layout from '~containers/Layout'

const ClientContainer = lazy(() => import('~containers/Client'))
const EmployeeSubheaderContainer = lazy(() => import('~containers/Client/Subheader'))
const EmployeeListContainer = lazy(() => import('~containers/Client/Sidebar'))
const EmptyViewContainer = lazy(() => import('~containers/Client/EmptyView.jsx'))
const ExtractContainer = lazy(() => import('~containers/Financial/Extract'))
const EditEmployeeContainer = lazy(() => import('~containers/Company/Employee/Edit'))

const INITIAL_PATH = '/clientes'

const customers = [
  {
    path: `${INITIAL_PATH}`,
    drawer: EmployeeListContainer,
    subheader: EmployeeSubheaderContainer,
    component: ClientContainer,
    subroutes: [
      {
        path: `${INITIAL_PATH}`,
        component: EmptyViewContainer,
        exact: true,
      },
      {
        path: `${INITIAL_PATH}/:user/informacoes`,
        component: EditEmployeeContainer,
        exact: true,
      },
      {
        path: `${INITIAL_PATH}/:user/financeiro`,
        component: ExtractContainer,
        exact: true,
        layout: Layout,
      },
    ],
    layout: Layout,
  },
]

export default customers
