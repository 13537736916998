import PropTypes from 'prop-types'

const TableHeaderModel = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
  align: PropTypes.string,
  bold: PropTypes.bool,
  noSortable: PropTypes.bool,
})

export default TableHeaderModel
