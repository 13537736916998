import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const CountDown = ({ startDate, duration, format, onFinish }) => {
  const [counter, setCounter] = useState()

  useEffect(() => {
    const finalDate = moment(startDate).add(duration, 'ms')
    const timeout = setInterval(() => {
      const diff = finalDate.diff(moment())
      if (diff > 1000) {
        setCounter(moment(diff).format(format))
      } else {
        onFinish()
        clearTimeout(timeout)
      }
    }, 1000)

    if (counter === undefined) {
      setCounter(moment(finalDate.diff(moment())).format(format))
    }

    return () => {
      clearInterval(timeout)
    }
  }, [startDate, duration, format, onFinish, counter])

  return <div>{counter}</div>
}

CountDown.defaultProps = {
  format: 'mm:ss',
  startDate: moment(),
  onFinish: () => undefined,
}

CountDown.propTypes = {
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(moment),
  ]),
  duration: PropTypes.number.isRequired,
  format: PropTypes.string,
  onFinish: PropTypes.func,
}

export default CountDown
