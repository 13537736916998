import { action, thunk } from 'easy-peasy'
import { CompanyApi } from '~resources'
import withSearchParams from './withSearchParams'

const companyModel = {
  company: null,
  companies: [],
  documents: [],
  policies: [],
  getCompanies: thunk(async (actions) => {
    const { rows: companies } = await CompanyApi.getCompanies()
    actions.setCompanies(companies)
  }),
  getCompany: thunk(async (actions, id) => {
    const company = await CompanyApi.get(id)
    actions.setCompany(company)
  }),
  setCompany: action((state, payload) => {
    state.company = payload
  }),
  setCompanies: action((state, payload) => {
    // eslint-disable-next-line prefer-destructuring
    !state.company && payload.length && (state.company = payload[0])
    state.companies = payload
  }),
  setCompanyDocuments: action((state, payload) => {
    state.documents = payload
  }),
  setCompanyPolicies: action((state, payload) => {
    state.policies = payload
  }),
}

export default withSearchParams({ ...companyModel, searchParams: { sort: 'tradeName' } })
