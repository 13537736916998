import React, { memo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { String as StringUtils } from '@base39/ui-utils'
import Comment from '../Comment'
import Item from '../Item'

const TimelineStep = ({
  date,
  text,
  document,
  onClick,
  hasClick,
  comments,
  displayUser,
}) => {
  const isNote = !StringUtils.isEmpty(document?.doc.comment)
  const user = document?.user?.name
  const formatedDate = moment(date).fromNow()

  const renderNote = (
    <Comment
      user={user}
      text={text}
      rejected={document?.doc?.causeRejection}
      date={formatedDate}
      onClick={onClick}
      hasClick={hasClick}
      displayUser={displayUser}
    />
  )

  const renderDefaultStep = (
    <Item
      user={user}
      text={text}
      date={formatedDate}
      onClick={onClick}
      hasClick={hasClick}
      displayUser={displayUser}
    />
  )

  return <>{isNote ? comments && renderNote : renderDefaultStep}</>
}

TimelineStep.defaultProps = {
  onClick: undefined,
  comments: true,
  displayUser: true,
}

TimelineStep.propTypes = {
  date: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  hasClick: PropTypes.bool,
}

export default memo(TimelineStep)
