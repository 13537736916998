import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Dialog, Slide } from '@material-ui/core'

const DialogStyled = styled(Dialog)`
  .paper {
    width: ${({ theme, fullScreen }) =>
      !fullScreen && `calc(100% - ${theme.spacing(4)}px)`};

    ${({ theme }) => `
      ${theme.breakpoints.down('sm')} {
        max-width: calc(100% - ${theme.spacing(4)}px);
      }
    `}
  }

  .paperScrollPaper {
    margin: ${({ theme, fullScreen }) => !fullScreen && `${theme.spacing(2)}px`};
    max-height: ${({ theme, fullScreen }) =>
      !fullScreen && `calc(100% - ${theme.spacing(4)}px)`};
  }

  ${({ scroll }) =>
    scroll === 'body' &&
    `
    .paper {
      margin: 0;
    }
  `}
`

const DialogExtended = ({ classes, ...props }) => (
  <DialogStyled
    {...props}
    classes={{
      ...classes,
      scrollPaper: 'scrollPaper',
      paperFullWidth: 'paperFullWidth',
      paperScrollPaper: 'paperScrollPaper',
      paper: 'paper',
    }}
  />
)

Slide.defaultProps = {
  direction: 'up',
}

DialogExtended.defaultProps = {
  fullScreen: false,
  classes: {},
  TransitionComponent: Slide,
}

DialogExtended.propTypes = {
  fullScreen: PropTypes.bool,
  classes: PropTypes.object,
}

export default DialogExtended
