import theme from '~commons/constants/theme'

export const colors = {
  done: '#7ED321',
  underReview: theme.palette.warning.main,
  onRelease: '#15BAE6',
  notApproved: '#D0021B',
}

export const statusRHProposal = {
  IN_ANALYSIS_RH: {
    color: colors.onRelease,
    label: 'proposal.status.inAnalysisRH',
  },
  IN_ANALYSIS_FINANCIAL: {
    color: colors.onRelease,
    label: 'proposal.status.inAnalysisFinancial',
  },
  SIGNED: {
    color: colors.onRelease,
    label: 'proposal.status.signed',
  },
  SIGNATURE_PENDING: {
    color: colors.underReview,
    label: 'proposal.status.signaturePending',
  },
  DONE: {
    color: colors.done,
    label: 'proposal.status.done',
  },
}

export const statusProposal = {
  IN_ANALYSIS_PAKETA: {
    color: colors.onRelease,
    label: 'proposal.status.inAnalysisPaketa',
  },
  REQUESTED_CHANGES_PAKETA: {
    color: colors.underReview,
    label: 'proposal.status.underReviewPaketa',
  },
  REJECTED_PAKETA: {
    color: colors.notApproved,
    label: 'proposal.status.rejectedByPaketa',
  },
  IN_ANALYSIS_RH: {
    color: colors.onRelease,
    label: 'proposal.status.inAnalysisRH',
  },
  REQUESTED_CHANGES_RH: {
    color: colors.underReview,
    label: 'proposal.status.underReviewRH',
  },
  REJECTED_RH: {
    color: colors.notApproved,
    label: 'proposal.status.rejectedByRH',
  },
  IN_ANALYSIS_FINANCIAL: {
    color: colors.onRelease,
    label: 'proposal.status.inAnalysisFinancial',
  },
  SIGNED: {
    color: colors.onRelease,
    label: 'proposal.status.signed',
  },
  REQUESTED_CHANGES_FINANCIAL: {
    color: colors.underReview,
    label: 'proposal.status.underReviewFinancial',
  },
  SIGNATURE_PENDING: {
    color: colors.underReview,
    label: 'proposal.status.signaturePending',
  },
  CANCELED: {
    color: colors.notApproved,
    label: 'proposal.status.canceled',
  },
  DONE: {
    color: colors.done,
    label: 'proposal.status.done',
  },
  PAID: {
    color: colors.done,
    label: 'proposal.status.paid',
  },
}

export const changeStatus = {
  IN_ANALYSIS_PAKETA: {
    approved: 'IN_ANALYSIS_RH',
    disapproved: 'REJECTED_PAKETA',
    sendPaketa: 'IN_ANALYSIS_PAKETA',
  },
  REQUESTED_CHANGES_PAKETA: {
    sendPaketa: 'IN_ANALYSIS_PAKETA',
  },
  REJECTED_PAKETA: {
    sendPaketa: 'IN_ANALYSIS_PAKETA',
  },
  IN_ANALYSIS_RH: {
    approved: 'SIGNATURE_PENDING',
    disapproved: 'REJECTED_RH',
    sendPaketa: 'IN_ANALYSIS_PAKETA',
  },
  REQUESTED_CHANGES_RH: {
    sendPaketa: 'IN_ANALYSIS_PAKETA',
  },
  REJECTED_RH: {
    sendPaketa: 'IN_ANALYSIS_PAKETA',
  },
  IN_ANALYSIS_FINANCIAL: {
    sendPaketa: 'IN_ANALYSIS_PAKETA',
  },
  REQUESTED_CHANGES_FINANCIAL: {
    approved: 'IN_ANALYSIS_FINANCIAL',
    sendPaketa: 'IN_ANALYSIS_PAKETA',
  },
  SIGNATURE_PENDING: {
    sendPaketa: 'IN_ANALYSIS_PAKETA',
  },
  CANCELED: {
    sendPaketa: 'IN_ANALYSIS_PAKETA',
  },
}

export const getCanChangeStatus = (status) => Object.keys(changeStatus).includes(status)
