import { RestApi } from './api'

const path = '/payments'

interface GetCompanyParams {
  id: string
  startDate?: Date | string
  endDate?: Date | string
  user: string
  sort: string
}

interface GetMonthBilletsParams {
  id: string
  year: number
  month: number
}

interface GenerateCSVPaymentsParams {
  id: string
  year: number
  month: number
}

const Payments = {
  getCompany(params: GetCompanyParams) {
    return RestApi.customRequest(`${path}/company`, {
      method: 'GET',
      params,
    })
  },
  getMonthBillets(params: GetMonthBilletsParams) {
    return RestApi.customRequest(`${path}/billet`, {
      method: 'GET',
      params,
    })
  },
  generateCSVPayments(params: GenerateCSVPaymentsParams) {
    return RestApi.customRequest(`${path}/csv-generate`, {
      method: 'GET',
      params,
    })
  },
}

export default Payments
