import React, { memo } from 'react'
import Tooltip from '../Tooltip'
import TimelineStep from './TimelineStep'
import { String as StringUtils } from '@base39/ui-utils'
import { TimelineStyled } from './style'

const Timeline = ({ steps, fields, onClick, comments, displayUser, placement }) => {
  const renderStep = (step, i) => (
    <Tooltip
      key={`${step[fields.status]}_${i}`}
      title={step.tooltip}
      placement={placement}
    >
      <div>
        <TimelineStep
          onClick={() => onClick && onClick(i)}
          hasClick={!StringUtils.isEmpty(onClick)}
          color={step[fields.color]}
          text={step[fields.status]}
          date={step[fields.date]}
          document={step.document}
          comments={comments}
          displayUser={displayUser}
        />
      </div>
    </Tooltip>
  )

  return (
    <TimelineStyled comments={comments}>{steps && steps.map(renderStep)}</TimelineStyled>
  )
}

Timeline.defaultProps = {
  comments: true,
  displayUser: true,
  placement: 'left',
}

export default memo(Timeline)
