import styled, { css } from 'styled-components'
import { ReactComponent as UserIcon } from '~assets/svg/ico_funcionario.svg'

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  color: #586069;
  flex-direction: row-reverse;
  background-color: #f6f8fa;
  border-bottom: 1px solid #d1d5da;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  &:only-child {
    border-bottom: 0;
    border-radius: 3px;
  }
`

export const TextStyled = styled.p`
  min-width: 0;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  margin-top: 0;
  margin-bottom: 1px;
  flex: 1 1 auto;
  font-size: 0.875rem;
  font-weight: 400;
`

export const UserStyled = styled.strong`
  color: #24292e;
`

export const BodyStyled = styled.div`
  display: block;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)}px;
  overflow: visible;
  font-size: 0.875rem;

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`

export const WrapAvatarStyled = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background: #e1e4e8;
  outline: 2px solid #fff;
  position: absolute;
  left: -${({ theme }) => theme.spacing(9)}px;
  z-index: 1;

  border-radius: 3px;
  transition: all 0.1s;

  ${({ hasClick }) =>
    hasClick &&
    css`
      cursor: pointer;

      :hover {
        transform: scale(1.1);
      }
    `}
`

export const AvatarStyled = styled.img`
  display: inline-block;
  width: 40px;
  height: 40px;
  overflow: hidden;
  line-height: 1;
  vertical-align: middle;
  border-radius: 3px;
`

export const CaretWrapStyled = styled.div`
  position: relative;
  margin-left: -${({ theme }) => theme.spacing(2)}px;
  color: #24292e;
  background-color: #fff;
  border: 1px solid #d1d5da;
  border-radius: 3px;

  &:after,
  &:before {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 11px;
    right: 100%;
    left: -16px;
    pointer-events: none;
    content: ' ';
    border-color: transparent;
    border-style: solid solid outset;
  }

  &:after {
    margin-top: 1px;
    margin-left: 2px;
    border-width: 7px;
    border-right-color: #f6f8fa;
  }

  &:before {
    border-width: 8px;
    border-right-color: #d1d5da;
  }
`

export const UserIconStyled = styled(UserIcon)`
  width: 16px;
`
