import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const getSizes = ({ fullWidth, fullHeight }) => css`
  ${fullWidth &&
  `
    max-width: 100% !important;
  `}

  ${fullHeight &&
  `
    height: 100%;
  `}
`

const PageContentStyled = styled.div`
  flex: 1;
  width: calc(100% - ${({ theme }) => theme.spacing(4)}px);
  position: relative;
  align-self: flex-end;
  padding: ${({ theme }) => theme.spacing(4)}px 0;
  margin-right: ${({ theme }) => theme.spacing(2)}px;

  ${getSizes}

  ${({ theme, noPadding, notLogged }) => css`
    ${noPadding &&
    `
      padding: 0;
    `}

    ${({ fullWidth }) => css`
      ${fullWidth &&
      `
        width: 100%;
        margin-right: 0;
      `}
    `}

    ${({ minHeight }) =>
      minHeight &&
      css`
        min-height: 0;
      `};

    ${notLogged &&
    `
      width: 100%;
      margin-right: 0;
      background: ${theme.palette.white[0]};
    `}

    ${theme.breakpoints.up('sm')} {
      width: calc(100% - ${({ theme }) => theme.spacing(8)}px);
      margin-right: ${({ theme }) => theme.spacing(4)}px;

      ${notLogged &&
      `
        width: 100%;
        margin-right: 0;
        background: ${theme.palette.white[0]};
      `}
    }
  `}
`

PageContentStyled.defaultProps = {
  fullWidth: false,
  fullHeight: false,
  noPadding: false,
  minHeight: false,
  notLogged: false,
}

PageContentStyled.propTypes = {
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  noPadding: PropTypes.bool,
  notLogged: PropTypes.bool,
}

export default PageContentStyled
