import styled from 'styled-components'
import Loading from '../Loading'

const LoadingStyled = styled(Loading)`
  color: ${({ theme, color }) => color || theme.palette.text.primary};
  font-size: 0.875rem;
  position: absolute;
  top: 0;
  left: 0;
`

const ImageContainerStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const ImageStyled = styled.img`
  visibility: ${({ visibility }) => visibility};
  max-width: 100%;
  max-height: 100%;
`

export { LoadingStyled, ImageContainerStyled, ImageStyled }
