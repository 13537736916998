import i18n from '~commons/i18n'
import moment from 'moment'

export const getDayToHeader = (date) => {
  const diffFromToday = moment().diff(moment(date), 'days')

  if (diffFromToday === 0) return i18n.t('date.today')
  if (diffFromToday === 1) return i18n.t('date.yesterday')
  if (diffFromToday <= 6) return moment(date).format('dddd')

  return moment(date).format('DD/MM')
}

export const remakeListWithChip = (rows) => {
  const days = {}
  return rows.map((row) => {
    const dayHeader = getDayToHeader(row.requestDate)
    if (!days[dayHeader] && row.requestDate) {
      days[dayHeader] = true
      return {
        type: 'header',
        title: dayHeader,
      }
    }
    return row
  })
}
