import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import debounce from 'debounce'
import { Search, Close } from '@material-ui/icons'
import { InputStyled, SearchContentStyled, IconButtonStyled } from './style'

class SearchInput extends PureComponent {
  constructor(props) {
    super(props)

    this.oldValue = props.value
    this.state = { value: props.value }

    this.onSearch = debounce((event) => {
      if (event.target.value !== this.oldValue) {
        props.onSearch(event)

        this.oldValue = event.target.value
      }
    }, props.delay)
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value })
      this.oldValue = this.props.value
    }
  }

  handleSearch = (event) => {
    const { value } = event.target

    event.persist()

    this.setState({ value }, () => {
      this.onSearch(event)
    })
  }

  handleClear = (callback) => {
    const value = ''
    const event = { target: { name: this.props.name, value } }

    this.setState({ value }, () => {
      if (this.oldValue !== value) {
        this.props.onSearch(event)
      }

      if (callback) {
        callback()
      }
    })
  }

  handleBack = () => {
    this.handleClear(this.props.onBack)
  }

  hasClear = () => {
    const { showClear } = this.props
    const { value } = this.state

    return value && showClear
  }

  render() {
    const { name, placeholder, noPadding, inputRef, className, autoFocus, fullWidth } =
      this.props
    const { value } = this.state
    const focus = () => inputRef?.current?.focus()

    const searchIcon = (
      <SearchContentStyled>
        <Search color="action" />
      </SearchContentStyled>
    )

    const cancelBtn = (
      <IconButtonStyled data-e2e-id="clearButton" onClick={() => this.handleClear(focus)}>
        <Close cursor="pointer" color="action" />
      </IconButtonStyled>
    )

    return (
      <InputStyled
        $noPadding={noPadding}
        value={value}
        name={name}
        id={name}
        autoFocus={autoFocus}
        className={className}
        placeholder={placeholder}
        onChange={this.handleSearch}
        inputRef={inputRef}
        startAdornment={searchIcon}
        endAdornment={this.hasClear() ? cancelBtn : null}
        fullWidth={fullWidth}
        inputProps={{ maxLength: 300 }}
      />
    )
  }
}

SearchInput.defaultProps = {
  delay: 1500,
  placeholder: '',
  name: 'search',
  noPadding: false,
  showClear: true,
  autoFocus: false,
  showBack: false,
  onBack: undefined,
  inputRef: React.createRef(),
  value: '',
  fullWidth: false,
}

SearchInput.propTypes = {
  name: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  noPadding: PropTypes.bool,
  delay: PropTypes.number,
  inputRef: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  showClear: PropTypes.bool,
  showBack: PropTypes.bool,
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
  fullWidth: PropTypes.bool,
}

export default SearchInput
