import { CPF as CPFUtils, CNPJ as CNPJUtils } from '@base39/ui-utils'

const isValid = (value) => {
  if (!value) {
    return true
  }

  const size = value.length

  if (size === 11) {
    return CPFUtils.isValid(value)
  }

  if (size === 14) {
    return CNPJUtils.isValid(value, true)
  }

  return false
}

export default function identificationDocument() {
  return this.test('identificationDocument', 'form.cnpjOrCpfInvalid', (value) =>
    isValid(value),
  )
}
