import styled from 'styled-components'

const ContainerStyled = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.white[400]};

  & .toast-position {
    top: 2em;
  }
  & .Toastify__toast--error {
    background: #c0392b;
  }
`

export { ContainerStyled }
