const isPhone = (value) => {
  if (!value) {
    return true
  }

  const size = value.length

  if (size === 10 || size === 11) {
    return true
  }

  return false
}

function phone() {
  return this.test('phone', 'form.phoneInvalid', (value) => isPhone(value))
}

export { isPhone, phone }
