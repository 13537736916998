import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import RCTDatePicker from 'react-datepicker'
import i18n from '~commons/i18n'
import { Date as DateUtils } from '@base39/ui-utils'
import i18nCalendar from '~commons/i18n/calendar'
import { getLanguage } from '~commons/utils/locale'
import Button from '../../Button'
import Input from '../Input'
import {
  DatePickerStyled,
  DatePickerFooterStyled,
  WrapHeaderStyled,
  InputYearStyled,
  LeftArrowStyled,
  RightArrowStyled,
} from './style'
import { withField } from '../Field'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import 'react-datepicker/dist/react-datepicker.min.css'

const localeUser = i18nCalendar[getLanguage()]

const refCalendar = createRef()

const DatePicker = (props) => {
  const {
    dateFormat,
    required,
    disabled,
    onChange,
    value,
    minDate,
    maxDate,
    name,
    customInput,
    shouldCloseOnSelect,
    placeholderText,
    isfloatCalendar,
    customProps,
  } = props

  const getValue = (value) => value && DateUtils.convertDateToUTC(value)

  const { months } = localeUser

  const closeCalendar = () => {
    if (refCalendar && refCalendar.current) {
      refCalendar.current.setOpen(false)
    }
  }

  const handleOnChange = (date) => {
    const formatValue = DateUtils.formatToISO(date)

    onChange(name, formatValue)
  }

  const floatCalendarConfig = isfloatCalendar
    ? {
        popperPlacement: 'bottom-end',
        popperModifiers: {
          flip: {
            behavior: ['bottom'],
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
          },
          hide: {
            enabled: false,
          },
        },
      }
    : {}

  return (
    <>
      <DatePickerStyled {...props}>
        <RCTDatePicker
          ref={refCalendar}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <WrapHeaderStyled>
              <LeftArrowStyled
                onClick={(event) => {
                  event.preventDefault()
                  decreaseMonth()
                }}
                disabled={prevMonthButtonDisabled}
              />
              <InputYearStyled
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
              />
              <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <RightArrowStyled
                onClick={(event) => {
                  event.preventDefault()
                  increaseMonth()
                }}
                disabled={nextMonthButtonDisabled}
              />
            </WrapHeaderStyled>
          )}
          customInput={customInput || <Input {...customProps} />}
          dateFormat={dateFormat}
          onChange={(date) => handleOnChange(date)}
          selected={getValue(value)}
          required={required}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          name={name}
          autoComplete="off"
          shouldCloseOnSelect={shouldCloseOnSelect}
          placeholderText={placeholderText}
          fixedHeight
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          isClearable
          {...floatCalendarConfig}
        >
          <DatePickerFooterStyled>
            <Button name="confirm-date" color="primary" onClick={closeCalendar}>
              {i18n.t('general.close')}
            </Button>
          </DatePickerFooterStyled>
        </RCTDatePicker>
      </DatePickerStyled>
    </>
  )
}

DatePicker.propTypes = {
  customInput: PropTypes.node,
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  localeFormat: PropTypes.object,
  customProps: PropTypes.object,
  maxDate: PropTypes.number,
  minDate: PropTypes.number,
  name: PropTypes.string,
  onCancel: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
  required: PropTypes.bool,
  shouldCloseOnSelect: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  isfloatCalendar: PropTypes.bool,
}

DatePicker.defaultProps = {
  customProps: null,
  customInput: null,
  dateFormat: localeUser.formats.L,
  disabled: false,
  maxDate: null,
  minDate: null,
  onCancel: null,
  placeholderText: null,
  required: false,
  shouldCloseOnSelect: false,
  withPortal: false,
  isfloatCalendar: false,
  localeFormat: {
    localize: {
      month: (month) => localeUser.months[month],
      day: (day) => localeUser.days[day],
    },
    formatLong: {},
  },
}

export { DatePicker }
export default withField(DatePicker)
