import PropTypes from 'prop-types'
import yup from '~commons/utils/yup'
import moment from 'moment'
import i18n from '~commons/i18n'

const SimulationModel = PropTypes.shape({
  cpf: PropTypes.string,
  company: PropTypes.string,
  hiredAt: PropTypes.string,
  salary: PropTypes.number,
  netSalary: PropTypes.number,
  value: PropTypes.number,
  payableMarginValue: PropTypes.number,
  firstDueDate: PropTypes.string,
})

export const SimulationInitialValues = {
  company: '',
  hiredAt: moment().format('YYYY-MM-DD'),
  firstDueDate: '',
}

export const validationSchema = yup.object().shape({
  cpf: yup.string(),
  company: yup.string(),
  hiredAt: yup.date().required(i18n.t('form.fieldRequired')).nullable(),
  salary: yup.number().required(),
  netSalary: yup.number().required(),
  firstDueDate: yup.string().required(),
  value: yup.number().required(),
  payableMarginValue: yup
    .number()
    .nullable()
    .transform((val, origVal) => (String(origVal).trim() === '' ? null : val)),
})

export default SimulationModel
