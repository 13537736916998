import { thunk } from 'easy-peasy'
import company from './company'
import proposal from './proposal'
import user from './user'
import movement from './movement'
import linkedCompanies from './linkedCompanies'
import specialOffer from './specialOffer'

export default {
  company,
  proposal,
  user,
  movement,
  linkedCompanies,
  specialOffer,
  initialise: thunk(async (actions, payload, { dispatch }) => {
    await dispatch.user.initializeUser()
  }),
}
