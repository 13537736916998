import React, { useMemo, memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '~components'
import i18n from '~commons/i18n'
import { useQuery } from '@apollo/client'
import { LIST_GROUPS } from '~graphql/Groups/query'
import { Autocomplete } from '~components/Form/Autocomplete'
import throttle from 'lodash/throttle'
import { TextFieldStyled } from './style'

const GroupSelect = ({ onChange, currentGroup, disabled, required }) => {
  const [inputValue, setInputValue] = useState('')
  const { data, refetch, loading } = useQuery(LIST_GROUPS, {
    variables: {
      page: 1,
    },
  })

  useEffect(() => {
    setInputValue(currentGroup?.name || '')
  }, [currentGroup])

  const getGroupsThrottle = useMemo(
    () =>
      throttle(
        (query) =>
          refetch({
            page: 1,
            q: query,
          }),
        1500,
      ),
    [refetch],
  )

  const handleGroupChange = ({ target }) => {
    if (!target.value) {
      return refetch({
        page: 1,
        q: '',
      })
    }

    return getGroupsThrottle(target.value)
  }

  return (
    <Autocomplete
      options={data?.listGroups?.rows}
      getOptionLabel={(it) => it.name}
      inputValue={inputValue}
      disabled={disabled}
      renderInput={(params) => (
        <TextFieldStyled
          {...params}
          required={required}
          margin="none"
          autoComplete="off"
          label={i18n.t('groups.label')}
          onChange={handleGroupChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress size={16} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={(_, value) =>
        onChange({
          target: {
            name: 'group',
            value: value,
          },
        })
      }
      freeSolo
      onInputChange={(_, value, reason) => {
        setInputValue(value)

        if (reason === 'clear' || value === '') {
          onChange({
            target: {
              name: 'group',
              value: value,
            },
          })
        }
      }}
    />
  )
}

Autocomplete.defaultProps = {
  options: [],
}

GroupSelect.defaultProps = {
  disabled: false,
}

GroupSelect.propTypes = {
  onChange: PropTypes.func,
}

export default memo(GroupSelect)
