import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getFieldProps, areEqual } from '../Field/utils'
import FormHelperText from '../FormHelperText'
import AlertHelperText from '../AlertHelperText'
import { MuiFormControlStyled } from './style'
import InputLabel from './InputLabel'

const renderAlertText = (maxCharacters, value, helperText) => {
  const showAlert = maxCharacters && !helperText

  return showAlert && <AlertHelperText value={value} max={maxCharacters} />
}

const FormControl = ({ customComponent: Input, ...props }) => {
  const {
    id,
    label,
    helperText,
    fullWidth,
    required,
    error,
    margin,
    shrink,
    maxCharacters,
    name,
    ...fieldProps
  } = getFieldProps(props)
  const fieldIdentifier = id || name
  const labelledby = `${fieldIdentifier}-label`
  const describedby = `${fieldIdentifier}-helperText`

  return (
    <MuiFormControlStyled
      error={error}
      fullWidth={fullWidth}
      required={required}
      margin={margin}
    >
      {label && (
        <InputLabel id={labelledby} shrink={shrink} htmlFor={fieldIdentifier}>
          {label}
        </InputLabel>
      )}
      <Input
        {...fieldProps}
        id={fieldIdentifier}
        name={name}
        shrink={shrink}
        aria-labelledby={labelledby}
        aria-describedby={describedby}
        inputProps={{
          'aria-labelledby': labelledby,
          'aria-describedby': describedby,
        }}
      />
      {helperText && (
        <FormHelperText id={describedby} aria-labelledby={labelledby} role="alert">
          {helperText}
        </FormHelperText>
      )}
      {renderAlertText(maxCharacters, fieldProps.value, helperText)}
    </MuiFormControlStyled>
  )
}

FormControl.defaultProps = {
  margin: 'normal',
  required: false,
  maxCharacters: null,
}

FormControl.propTypes = {
  customComponent: PropTypes.elementType.isRequired,
  maxCharacters: PropTypes.number,
}

export { MuiFormControlStyled as FormControl }
export default memo(FormControl, areEqual)
