import { gql } from '@apollo/client'

export const SIMULATION_PARTS = gql`
  fragment SIMULATION_PARTS on Simulation {
    id
    value
    withInsurance
    maxInstallmentValue {
      value
    }
    maxCreditAvailable {
      value
    }
    firstDueDate
    daysToIncrease
    minCreditRequest
    acquittanceLoans {
      id
      value
    }
    fund {
      id
      name
    }
    financialInstitution {
      id
      name
    }
    installments {
      installments
      allowed
      message
      errorValue
      installmentValue
      registerFee
      iofValue
      totalValue
      monthlyCET
      yearlyCET
      monthlyFee
      yearlyFee
      insuranceValue
      insuranceType
      withInsurance
      insuranceFee
      id
    }
  }
`
