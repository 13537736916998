import { datadogRum } from '@datadog/browser-rum'

function init() {
  if (
    process.env.REACT_APP_DATADOG_RUM_APP_ID &&
    process.env.REACT_APP_DATADOG_RUM_TOKEN
  ) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
      clientToken: process.env.REACT_APP_DATADOG_RUM_TOKEN,
      site: 'datadoghq.com',
      service: 'backoffice',
      env: process.env.REACT_APP_ENV,
      version: process.env.REACT_APP_SHORT_SHA,
      sampleRate: 100,
      trackInteractions: true,
      allowedTracingOrigins: [
        process.env.REACT_APP_API_URL,
        process.env.REACT_APP_SIGN_URL,
      ],
    })
  }
}

export default { init }
