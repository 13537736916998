import i18n from '~commons/i18n'

export const HeaderTableInstallments = [
  {
    value: 'installments',
    label: i18n.t('proposal.qtdPortions'),
    align: 'right',
    bold: true,
    noSortable: true,
  },
  {
    value: 'installmentValue',
    label: i18n.t('proposal.portion'),
    align: 'right',
    bold: true,
    noSortable: true,
  },
  {
    value: 'installmentInsuranceValue',
    label: i18n.t('proposal.installmentInsuranceValue'),
    align: 'right',
    noSortable: true,
  },
  {
    value: 'monthlyFee',
    label: i18n.t('proposal.amTax'),
    align: 'right',
    noSortable: true,
  },
  {
    value: 'ratesInfos',
    label: i18n.t('proposal.otherRates'),
    align: 'right',
    noSortable: true,
  },
]

export const HeaderTableInstallmentsActions = [
  ...HeaderTableInstallments,
  {
    value: 'actions',
    align: 'center',
    noSortable: true,
  },
]
