import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import { GET_USER_BY_CPF } from './query'
import moment from 'moment'
import InputMask from '~components/Form/InputMask'

const InputCpfSimulation = ({
  name,
  label,
  disabled,
  onChange,
  setValues,
  values,
  onUserFound,
  ...props
}) => {
  const formatMomentDate = (momentDate) => momentDate.format('YYYY-MM-DD')
  const [cpf, setCpf] = useState()

  const resetValuesUser = () => {
    setValues({
      ...values,
      cpf,
      id: '',
      salary: '',
      netSalary: '',
      linked: false,
      hiredAt: formatMomentDate(moment()),
      value: '',
      company: '',
    })
  }

  const onReturnQueryUser = ({ getUserByCPF }) => {
    if (getUserByCPF) {
      const data = getUserByCPF
      setValues({
        ...values,
        cpf,
        id: data?.id,
        salary: data?.company?.salary,
        netSalary: data?.company?.netSalary,
        linked: !!data?.company?.ref,
        hiredAt: formatMomentDate(moment(data?.company?.hiredAt?.substr(0, 10))),
        company: data?.company?.ref?.id || '',
        companyTradeName: data?.company?.ref?.tradeName,
      })

      onUserFound(data)
      return
    }

    resetValuesUser()
  }

  const [getUser] = useLazyQuery(GET_USER_BY_CPF, {
    onCompleted: onReturnQueryUser,
    onError: resetValuesUser,
  })

  const getUserCompanyData = (cpf) => {
    setCpf(cpf)
    getUser({
      variables: {
        cpf,
      },
    })
  }

  const handleCPFChange = (ev) => {
    onChange(ev)
    const {
      target: { value: cpf },
    } = ev
    cpf.length === 11 && getUserCompanyData(cpf)
  }

  return (
    <InputMask
      margin="normal"
      name={name}
      mask="999.999.999-99"
      type="tel"
      onChange={handleCPFChange}
      disabled={disabled}
      label={label}
      fullWidth
      {...props}
    />
  )
}

InputCpfSimulation.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  setFieldValue: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default InputCpfSimulation
