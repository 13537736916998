import React, { memo, Fragment, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { withRouter, Link } from 'react-router-dom'
import i18n from '~commons/i18n'
import TextField from '../TextField'
import { IconButton } from '@material-ui/core'
import MenuOptions from './MenuOptions'
import { ArrowBack, Close } from '@material-ui/icons'
import debounce from 'debounce'
import { LIST_COMPANIES } from '~graphql/Company/companies.query'
import { Autocomplete } from '~components/Form/Autocomplete'
import LogoIcon from '~assets/svg/logo.svg'
import { useAuth0 } from '@auth0/auth0-react'
import { isFunction } from '~commons/utils/is'
import {
  IconButtonStyled,
  NavbarStyled,
  AppBarStyled,
  SpacerStyled,
  MenuStyled,
  WrapMenuDropdown,
  ArrowStyled,
  MenuItemStyled,
  LogoStyled,
  CompaniesStyled,
  ToolbarStyled,
  LeftStyled,
  RightStyled,
  AutoCompleteStyled,
} from './style'

const BLOCK_PATH_COMPANIES = ['/simulador', '/empresas']

const Navbar = (props) => {
  const {
    title,
    backTo,
    match,
    history,
    showBack,
    children,
    location,
    allowCancel,
    items,
    companyRequired,
  } = props
  const { logout } = useAuth0()
  const [menuOpen, setMenuOpen] = useState()
  const cancel = location.state && location.state.cancel
  const hasCancel = allowCancel && !!cancel
  const { setUser } = useStoreActions(({ user: { setUser } }) => ({ setUser }))
  const [allCompanies, setAllCompanies] = useState([])
  const freezeCompanies = useRef()
  const company = useStoreState(({ company: { company } }) => company)
  const { setCompany, setCompanyDocuments, setCompanyPolicies } = useStoreActions(
    ({ company }) => company,
  )
  const { data, loading, fetchMore } = useQuery(LIST_COMPANIES, {
    variables: {
      page: 1,
    },
  })

  useEffect(() => {
    if (!loading) {
      const companies = data?.listCompanies?.rows
      setAllCompanies(companies)
      if (!freezeCompanies.current) {
        freezeCompanies.current = companies
      }
    }
  }, [loading, data])

  const hasBack = () => showBack && backTo

  const onBack = () => {
    if (isFunction(backTo)) {
      backTo()
    } else {
      history.push(backTo)
    }
  }

  const handleLogout = () => {
    setMenuOpen(false)
    window.location.href = '/'
    setUser(null)
    logout()
  }

  const onCancel = () => {
    history.push(cancel)
  }

  const handleProfileMenuOpen = () => {
    setMenuOpen(menuOpen ? handleMenuClose : true)
  }

  const handleMenuClose = () => {
    setMenuOpen(false)
  }

  const renderMenu = (
    <MenuStyled open={menuOpen} onClose={handleMenuClose}>
      <MenuItemStyled onClick={handleLogout}>Sair</MenuItemStyled>
    </MenuStyled>
  )

  const renderMenuDropdown = (
    <WrapMenuDropdown onMouseLeave={handleMenuClose}>
      <IconButton color="inherit" onClick={handleProfileMenuOpen}>
        <ArrowStyled open={menuOpen} />
      </IconButton>
      {renderMenu}
    </WrapMenuDropdown>
  )

  const renderTitle = () => {
    if (!title) return null
    return (
      <>
        <SpacerStyled className="mh-2" />
        {title}
      </>
    )
  }

  const renderBackArrow = () => {
    if (!hasBack() || hasCancel) return null

    return (
      <IconButtonStyled color="inherit" aria-label="back" onClick={onBack}>
        <ArrowBack />
      </IconButtonStyled>
    )
  }

  const renderCancel = () => {
    if (!hasCancel) return null
    return (
      <IconButtonStyled color="inherit" aria-label="cancel" onClick={onCancel}>
        <Close />
      </IconButtonStyled>
    )
  }

  const handleQueryCompany = (text) => {
    const query = text ? { q: text } : null
    fetchMore({
      variables: query,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || fetchMoreResult.listCompanies.total === 0) return prev
        return fetchMoreResult
      },
    })
  }

  const handleInputChangeCompany = debounce((text) => {
    const hasCompanieInList = freezeCompanies?.current?.find(
      (company) => company.tradeName === text,
    )
    if (text.length >= 3 && !hasCompanieInList) {
      handleQueryCompany(text)
    } else if (!text) {
      setAllCompanies(freezeCompanies.current)
    }
  }, 2000)

  const getCompanyLabel = (company) => (company ? company.tradeName : '')

  const renderInput = (params, label, name) => (
    <TextField {...params} name={name} label={label} fullWidth />
  )

  const handleSelectCompany = (event, value) => {
    setCompanyDocuments([])
    setCompanyPolicies([])
    setCompany(value)
  }

  const renderCompanies = () => {
    if (loading) return null
    const hasCompany = company?.id
    const getClassAutocomplete = () => (!hasCompany ? 'spotlight-autocomplete' : '')
    const showSpotlight = !hasCompany && companyRequired

    return (
      <AutoCompleteStyled>
        <Autocomplete
          className={getClassAutocomplete()}
          name="company"
          options={allCompanies}
          getOptionLabel={getCompanyLabel}
          renderInput={(params) =>
            renderInput(params, i18n.t('company.allCompanies'), 'company')
          }
          onChange={(event, value) => handleSelectCompany(event, value)}
          onInputChange={(event, value) => {
            handleInputChangeCompany(value)
          }}
          freeSolo
          value={company}
          fullWidth
        />
        {showSpotlight && (
          <>
            <div className="dark-screen"></div>
            <div className="box-autocomplete">{i18n.t('company.companyRequired')}</div>
          </>
        )}
      </AutoCompleteStyled>
    )
  }

  return (
    <Fragment>
      <NavbarStyled>
        <AppBarStyled>
          <ToolbarStyled>
            <LeftStyled>
              {renderBackArrow()}
              {renderCancel()}
              <Link to="/empresas">
                <LogoStyled src={LogoIcon} alt={i18n.t('seo.logo.alt')} />
              </Link>
              {<MenuOptions options={items} />}
              {renderTitle()}
            </LeftStyled>
            {!BLOCK_PATH_COMPANIES.includes(match.url) && (
              <CompaniesStyled>{renderCompanies()}</CompaniesStyled>
            )}
            <RightStyled>
              {children}
              {renderMenuDropdown}
            </RightStyled>
          </ToolbarStyled>
        </AppBarStyled>
      </NavbarStyled>
    </Fragment>
  )
}

Autocomplete.defaultProps = {
  options: [],
}

Navbar.defaultProps = {
  showBack: true,
  allowCancel: true,
  title: null,
  companyRequired: false,
}

Navbar.propTypes = {
  title: PropTypes.string,
  openSidebar: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  showMenu: PropTypes.bool,
  showBack: PropTypes.bool,
  allowCancel: PropTypes.bool,
  backTo: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.node,
  companyRequired: PropTypes.bool,
}

export { Navbar }
export default memo(withRouter(Navbar))
