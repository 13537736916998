import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '~components'
import i18n from '~commons/i18n'
import { Dialog } from '~components/Dialog'
import { DialogTitleStyled, DialogActionsStyled, DialogContentStyled } from './style'

const ModalConfirm = ({
  maxWidth,
  head,
  content,
  onConfirm,
  open,
  onClose,
  confirmText,
  cancelText,
  disableBackdropClick,
  beforeConfirm,
}) => {
  const onClick = () => {
    beforeConfirm && beforeConfirm()
    onConfirm && onConfirm()
  }

  return (
    <Dialog
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
    >
      <DialogTitleStyled disableTypography>{head}</DialogTitleStyled>
      <DialogContentStyled>{content}</DialogContentStyled>
      <DialogActionsStyled>
        <Button className="button button--cancel" onClick={onClose} variant="contained">
          {cancelText}
        </Button>
        <Button className="button button--success" onClick={onClick} variant="contained">
          {confirmText}
        </Button>
      </DialogActionsStyled>
    </Dialog>
  )
}

ModalConfirm.defaultProps = {
  maxWidth: 'xs',
  confirmText: i18n.t('general.confirm'),
  cancelText: i18n.t('general.cancel'),
  head: undefined,
  content: undefined,
}

ModalConfirm.propTypes = {
  maxWidth: PropTypes.string,
  head: PropTypes.node,
  content: PropTypes.node,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
}

export default ModalConfirm
