import React, { useMemo, memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '~components'
import i18n from '~commons/i18n'
import { useQuery } from '@apollo/client'
import { LIST_COMPANIES } from '~graphql/Company/companies.query'
import { Autocomplete } from '~components/Form/Autocomplete'
import throttle from 'lodash/throttle'
import { CNPJ as CNPJUtils } from '@base39/ui-utils'
import { TextFieldStyled } from './style'

const CompanySelect = ({ id, onChange, currentCompany, disabled }) => {
  const [inputValue, setInputValue] = useState('')
  const { data, refetch, loading } = useQuery(LIST_COMPANIES, {
    variables: {
      page: 1,
    },
  })

  useEffect(() => {
    setInputValue(currentCompany?.tradeName ? currentCompany?.tradeName : '')
  }, [currentCompany])

  const getCompaniesThrottle = useMemo(
    () =>
      throttle(
        (query) =>
          refetch({
            page: 1,
            q: query,
          }),
        1500,
      ),
    [refetch],
  )

  const handleCompanyChange = ({ target }) => {
    if (!target.value) {
      return refetch({
        page: 1,
        q: '',
      })
    }

    return getCompaniesThrottle(target.value)
  }

  const handleChange = (_, value) =>
    onChange({
      target: {
        name: 'ref',
        value: value,
      },
    })

  const renderInput = (params) => (
    <TextFieldStyled
      {...params}
      required
      margin="none"
      autoComplete="off"
      label={i18n.t('company.allCompanies')}
      onChange={handleCompanyChange}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading && <CircularProgress size={16} />}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
      fullWidth
    />
  )

  return (
    <Autocomplete
      id={id}
      options={data?.listCompanies?.rows}
      data-testid="company-select"
      getOptionLabel={(it) => `${it.cnpj} ${it.tradeName}`}
      inputValue={inputValue}
      disabled={disabled}
      onInputChange={(event, newInputValue) => event && setInputValue(newInputValue)}
      renderOption={(it) => `${CNPJUtils.format(it.cnpj)} - ${it.tradeName}`}
      freeSolo
      fullWidth
      renderInput={renderInput}
      onChange={handleChange}
    />
  )
}

CompanySelect.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  currentCompany: PropTypes.object,
  disabled: PropTypes.bool,
}

export default memo(CompanySelect)
