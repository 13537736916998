import styled, { css } from 'styled-components'
import Select from '@material-ui/core/Select'
import MenuItem from '~components/MenuItem'
import IconButton from '../IconButton'
import { InputLabel } from '@material-ui/core'

export const SelectStyled = styled(Select)`
  && {
    margin-top: 0;
    background-color: #fff;
    height: 48px;

    ${({ label }) =>
      label &&
      css`
        min-width: 90px;
      `}
  }
`

export const ContainerStyled = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: ${({ theme }) => theme.spacing(8)}px;
`

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 32px;
  border-radius: 0;
`

export const MenuItemStyled = styled(MenuItem)`
  white-space: pre-wrap;
`

export const InputLabelStyled = styled(InputLabel)`
  z-index: 1;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  transform: none;
`
