import React, { createContext, useCallback, useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { LIST_CREDIT_POLICIES } from '../Sidebar/query'

const CreditPolicyContextA = createContext()

const CreditPolicyProvider = ({ children }) => {
  const [listPolicies, { loading, data, fetchMore, refetch }] = useLazyQuery(
    LIST_CREDIT_POLICIES,
    {
      variables: {
        q: '',
        sort: '-requestDate',
        page: 1,
        pageSize: 10,
      },
    },
  )

  const getListPolicies = useCallback(
    ({ q, page, pageSize }) => {
      listPolicies({
        variables: {
          q,
          page,
          pageSize,
        },
      })
    },
    [listPolicies],
  )

  return (
    <CreditPolicyContextA.Provider
      value={{ data, getListPolicies, fetchMore, loading, refetch }}
    >
      {children}
    </CreditPolicyContextA.Provider>
  )
}

function useCreditPolicy() {
  const context = useContext(CreditPolicyContextA)

  if (!context) {
    throw new Error('useCreditPolicy must be used within an CreditPolicyProvider')
  }

  return context
}

export { CreditPolicyProvider, useCreditPolicy }
