import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import { CloseIcon } from './style'

const SnackbarClose = ({ closeToast }) => (
  <IconButton
    data-testid="snackbar-close"
    key="close"
    aria-label="Close"
    color="inherit"
    onClick={closeToast}
  >
    <CloseIcon />
  </IconButton>
)

SnackbarClose.propTypes = {
  closeToast: PropTypes.func,
}

export default SnackbarClose
