/* eslint-disable max-lines */
import React, { memo, useRef, useEffect, useState, useCallback } from 'react'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import i18n from '~commons/i18n'
import { Date as DateUtils } from '@base39/ui-utils'
import { CustomersApi, SimulationsApi } from '~resources'
import SimulationModel, {
  validationSchema,
  SimulationInitialValues,
} from '~commons/models/simulation'
import {
  InstallmentPortions,
  InstallmentPortionsConcise,
} from '~commons/constants/installmentPortions'
import TableInstallments from '../TableInstallments'
import InputPrice from '../Form/InputPrice'
import Select from '../Form/Select'
import CompanySelect from '../CompanySelect'
import Grid from '../Grid'
import Button from '../Button'
import Loading from '../Loading'
import CircularProgress from '../CircularProgress'
import { Date as DateInput } from '../Form/Datepicker'
import { LIST_COMPANIES } from '~graphql/Company/companies.query'
import { GET_AVAILABLE_FIRST_DUE_DATES } from '~graphql/Simulation/query'
import { ADD_SIMULATE } from '~graphql/Simulation/add.mutation'
import { HeaderTableInstallments, HeaderTableInstallmentsActions } from './constants'
import { joinSimulations } from './utils'
import InputCpfSimulation from './InputCpfSimulation'
import CardsValues from './CardsValues'
import {
  ContainerFormStyled,
  ContainerTableStyled,
  ContentLoaderStyled,
  DueDateGrid,
  DueDateLoading,
} from './style'

const FormSimulation = ({
  initialValues,
  showSelectCompany,
  onSelectInstallment,
  loading,
}) => {
  const formikRef = useRef(null)
  const [indexLoading, setIndexLoading] = useState(null)
  const [maxCredit, setMaxCredit] = useState(0)
  const [maxInstallmentValue, setMaxInstallmentValue] = useState(0)
  const [addSimulation, { data: simulation, loading: loadingMutation }] =
    useMutation(ADD_SIMULATE)
  const [addSimulationWithInsurance, { data: simulationWithInsurance }] =
    useMutation(ADD_SIMULATE)

  const [getFirstDates, { data: dataFirstDueDates = [], loading: loadingFirstDueDates }] =
    useLazyQuery(GET_AVAILABLE_FIRST_DUE_DATES, {
      onCompleted: ({ getAvailableFirstDueDates }) => {
        getAvailableFirstDueDates[0] !== null &&
          formikRef.current.setFieldValue(
            'firstDueDate',
            toString(getAvailableFirstDueDates[0]),
          )
      },
    })

  const [fields, setFields] = useState()
  const [currentCompany, setCurrentCompany] = useState()

  const { data, fetchMore } = useQuery(LIST_COMPANIES, {
    variables: {
      page: 1,
      pageSize: 9999999,
    },
  })

  useEffect(() => {
    initialValues.company &&
      getFirstDates({
        variables: { id: initialValues.company },
      })
  }, [initialValues, getFirstDates])

  useEffect(() => {
    if (showSelectCompany) {
      fetchMore({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          return fetchMoreResult
        },
      })
    }
  }, [fetchMore, showSelectCompany])

  const handleSelectInstallment = (installment) => {
    setIndexLoading(installment.id)
    if (!!onSelectInstallment && installment.allowed) {
      const formValues = formikRef.current.state.values
      const { cpf, salary, netSalary, hiredAt, company, value, payableMarginValue } =
        formValues
      const data = {
        hiredAt: new Date(`${hiredAt}`).toISOString(),
        netSalary: Number.parseFloat(netSalary),
        salary: Number.parseFloat(salary),
        cpf,
        company,
        value: Number.parseFloat(value),
        payableMarginValue: Number.parseFloat(payableMarginValue),
        proposalId: initialValues.proposalId,
        installment: installment.id,
        simulation: installment.simulationId,
      }
      onSelectInstallment(data)
    }
  }

  const renderContent = (row, column) => {
    if (column !== 'actions' || !row.allowed) return null
    const checkRowLoading = () => loading && indexLoading === row.id
    const tryRenderLoading = () => checkRowLoading() && <CircularProgress size={16} />
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          size="small"
          disabled={loading}
          onClick={() => handleSelectInstallment(row)}
        >
          {tryRenderLoading()} {i18n.t('simulation.selectInstallment')}
        </Button>
        {row.insuranceType === 'OPTIONAL' && (
          <Button
            className="ml-4"
            color="primary"
            variant="contained"
            size="small"
            disabled={loading}
            onClick={() =>
              handleSelectInstallment({
                ...row,
                id: row.insuranceId,
                simulationId: row.simulationInsuranceId,
              })
            }
          >
            {tryRenderLoading()} {i18n.t('simulation.selectInstallmentInsurance')}
          </Button>
        )}
      </>
    )
  }

  const getInstallments = (values, type) => {
    const {
      cpf,
      id,
      salary,
      netSalary,
      hiredAt,
      company,
      value,
      payableMarginValue,
      firstDueDate,
    } = values
    const valuesSimulation = {
      hiredAt: new Date(`${hiredAt}`).toISOString(),
      netSalary: Number.parseFloat(netSalary),
      salary: Number.parseFloat(salary),
      cpf,
      company,
      value: Number.parseFloat(value),
      payableMarginValue: Number.parseFloat(payableMarginValue),
      installments: type === 'full' ? InstallmentPortions : InstallmentPortionsConcise,
      firstDueDate,
      loanId: initialValues.proposalId,
    }

    CustomersApi.getMaxCredit(id).then((result) => {
      setMaxCredit(result.maxCredit)
    })

    SimulationsApi.getMaxInstallmentValue({
      userId: id,
      salary: valuesSimulation.salary,
      netSalary: valuesSimulation.netSalary,
    }).then((result) => {
      setMaxInstallmentValue(result.value)
    })

    addSimulation({
      variables: {
        ...valuesSimulation,
      },
    })
    addSimulationWithInsurance({
      variables: {
        ...valuesSimulation,
        withInsurance: true,
      },
    })
  }

  const handleSubmitForm = (typeSubmit) => {
    const payload = formikRef.current.state.values
    if (validationSchema.isValidSync(payload)) {
      getInstallments(payload, typeSubmit)
    }
  }

  const handleUserFound = (user) => {
    if (user.company?.ref?.id) {
      getFirstDates({
        variables: {
          id: user.company.ref.id,
        },
      })
    }
  }

  const renderBlockCards = () => (
    <CardsValues
      {...simulation.addSimulation}
      maxCreditAvailable={maxCredit}
      maxInstallmentValue={maxInstallmentValue}
    />
  )

  const canRenderBlockCards = () => simulation?.addSimulation?.minCreditRequest >= 0

  const renderCompanyOption = useCallback(
    (companyID) => data?.listCompanies?.rows.find((it) => it.id === companyID),
    [data],
  )

  const canRenderShowSelectCompany = (values, setFieldValue) => {
    if (!showSelectCompany) return null
    setFields(values)
    return (
      <Grid item xs className="pt-3">
        <CompanySelect
          onChange={(company) => {
            if (!company?.target?.value) return
            const { id: ref } = company?.target?.value
            getFirstDates({
              variables: { id: ref },
            })
            setFieldValue('company', ref)
          }}
          name="company"
          margin="none"
          disabled={values.linked}
          currentCompany={currentCompany}
        />
      </Grid>
    )
  }

  const renderLoading = () => {
    if (!loadingMutation) return null
    return (
      <ContentLoaderStyled>
        <div className="pt-2">
          <Loading />
        </div>
      </ContentLoaderStyled>
    )
  }

  const renderInstallments = () => {
    if (!simulation?.addSimulation) return null

    const simulationsCombined = joinSimulations(
      simulation?.addSimulation,
      simulationWithInsurance?.addSimulation,
      renderContent,
    )

    return (
      <ContainerTableStyled>
        <TableInstallments
          headers={
            showSelectCompany ? HeaderTableInstallments : HeaderTableInstallmentsActions
          }
          rows={simulationsCombined}
        />
      </ContainerTableStyled>
    )
  }

  const renderAvailableFirstDuedates = (date, index) => (
    <option key={`date-${index}`} value={date}>
      {DateUtils.formatDateUTC(date)}
    </option>
  )

  const firstDueDates = dataFirstDueDates?.getAvailableFirstDueDates?.result

  useEffect(() => {
    setCurrentCompany(
      renderCompanyOption(fields?.company) || { tradeName: fields?.companyTradeName },
    )
  }, [fields, renderCompanyOption])

  const renderForm = () => (
    <Formik
      initialValues={{ ...SimulationInitialValues, ...initialValues, firstDueDate: [] }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
      ref={formikRef}
      isInitialValid={validationSchema.isValidSync(initialValues)}
      render={({ handleSubmit, handleChange, setValues, values, setFieldValue }) => {
        return (
          <>
            <form onSubmit={handleSubmit}>
              <Grid container direction="column">
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <InputCpfSimulation
                        name="cpf"
                        onChange={handleChange}
                        onUserFound={handleUserFound}
                        setValues={setValues}
                        disabled={!showSelectCompany}
                        label={i18n.t('simulation.cpf')}
                        fullWidth
                        values={values}
                      />
                    </Grid>
                    {canRenderShowSelectCompany(values, setFieldValue)}
                    <Grid item xs>
                      <DateInput
                        label={i18n.t('simulation.admissionDate')}
                        withPortal
                        name="hiredAt"
                        margin="none"
                        fullWidth
                        required
                        onChange={setFieldValue}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md>
                      <InputPrice
                        name="salary"
                        type="tel"
                        label={i18n.t('simulation.grossSalary')}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md>
                      <InputPrice
                        name="netSalary"
                        type="tel"
                        label={i18n.t('simulation.netSalary')}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md>
                      <InputPrice
                        name="value"
                        type="tel"
                        label={i18n.t('simulation.requestedValue')}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md>
                      <InputPrice
                        name="payableMarginValue"
                        type="tel"
                        label={i18n.t('simulation.deductPayableMargin')}
                        fullWidth
                      />
                    </Grid>
                    <DueDateGrid item xs={12} sm={6} md>
                      <Select
                        name="firstDueDate"
                        label={i18n.t('proposal.firstDueDate')}
                        fullWidth
                        disabled={!values.company}
                        required
                      >
                        {firstDueDates?.map(renderAvailableFirstDuedates)}
                      </Select>
                      {loadingFirstDueDates && (
                        <DueDateLoading>
                          <CircularProgress size={16} />
                        </DueDateLoading>
                      )}
                    </DueDateGrid>
                  </Grid>
                  <Grid container spacing={1} className="mt-2">
                    <Grid item xs>
                      <Button
                        color="secondary"
                        variant="contained"
                        fullWidth
                        onClick={() => handleSubmitForm('concise')}
                      >
                        {i18n.t('simulation.simulateConcise')}
                      </Button>
                    </Grid>
                    <Grid item xs>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={() => handleSubmitForm('full')}
                      >
                        {i18n.t('simulation.simulateFull')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>

            {canRenderBlockCards() && renderBlockCards()}
            {renderLoading()}
            {renderInstallments()}
          </>
        )
      }}
    />
  )

  return <ContainerFormStyled>{renderForm()}</ContainerFormStyled>
}

FormSimulation.defaultProps = {
  initialValues: SimulationInitialValues,
  showSelectCompany: false,
  pageSize: 999,
}

FormSimulation.propTypes = {
  initialValues: SimulationModel,
  showSelectCompany: PropTypes.bool,
  onSelectInstallment: PropTypes.func,
  pageSize: PropTypes.number,
  loading: PropTypes.bool,
}

export default memo(FormSimulation)
