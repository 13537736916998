import React from 'react'
import ChipOption from '../ChipOption'
import { withField } from '~components/Form/Field'

const TaxType = ({ name, className, value, policyType, onChange }) => (
  <div className={`flex mt-2 ${className}`}>
    <div className="mr-1">
      <ChipOption
        name={name}
        checked={value === 'ABSOLUTE'}
        onClick={onChange}
        label="R$"
        disabled={policyType !== 'ADVANCE'}
        value="ABSOLUTE"
      />
    </div>
    <div>
      <ChipOption
        name={name}
        checked={value === 'PERCENTAGE'}
        onClick={onChange}
        label="%"
        disabled={policyType !== 'PAYROLL'}
        value="PERCENTAGE"
      />
    </div>
  </div>
)

TaxType.defaultProps = {
  className: '',
  policyType: '',
}

const TaxTypeField = withField(TaxType)

TaxTypeField.defaultProps = {
  shrink: true,
}

export { TaxType }
export default TaxTypeField
