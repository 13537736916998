import React, { useMemo, memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '~components'
import i18n from '~commons/i18n'
import { useQuery } from '@apollo/client'
import { LIST_CREDIT_POLICIES } from '~graphql/CreditPolicy/list.query'
import { Autocomplete } from '~components/Form/Autocomplete'
import throttle from 'lodash/throttle'
import { TextFieldStyled } from './style'

const CreditPolicySelect = ({ onChange, currentValue, disabled }) => {
  const [inputValue, setInputValue] = useState('')
  const { loading, data, refetch } = useQuery(LIST_CREDIT_POLICIES, {
    variables: {
      page: 1,
    },
  })

  useEffect(() => {
    setInputValue(currentValue?.name ?? '')
  }, [currentValue])

  const getValuesThrottle = useMemo(
    () =>
      throttle(
        (query) =>
          refetch({
            page: 1,
            q: query,
          }),
        1500,
      ),
    [refetch],
  )

  const handleCompanyChange = ({ target }) => {
    if (!target.value) {
      return refetch({
        page: 1,
        q: '',
      })
    }

    return getValuesThrottle(target.value)
  }

  return (
    <Autocomplete
      options={data?.listCreditPolicies?.rows}
      getOptionLabel={(it) => it.name}
      inputValue={inputValue}
      disabled={disabled}
      onInputChange={(event, newInputValue) => event && setInputValue(newInputValue)}
      renderInput={(params) => (
        <TextFieldStyled
          {...params}
          required
          margin="none"
          autoComplete="off"
          label={i18n.t('creditPolicy.name')}
          onChange={handleCompanyChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress size={16} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          fullWidth
        />
      )}
      onChange={(_, value) =>
        onChange({
          target: {
            name: 'ref',
            value,
          },
        })
      }
      freeSolo
      fullWidth
    />
  )
}

CreditPolicySelect.propTypes = {
  onChange: PropTypes.func,
  currentValue: PropTypes.object,
  disabled: PropTypes.bool,
}

export default memo(CreditPolicySelect)
