import { lazy } from 'react'

const Layout = lazy(() => import('~containers/PrivacyPolicy/Layout'))
const PrivacyPolicyContainer = lazy(() => import('~containers/PrivacyPolicy'))

const privacyPolicy = [
  {
    path: '/privacidade',
    layout: Layout,
    component: PrivacyPolicyContainer,
    skipAuthentication: true,
  },
]

export default privacyPolicy
