import { gql } from '@apollo/client'

export const Group = gql`
  {
    id
    name
  }
`

export const LIST_GROUPS = gql`
  query LIST_GROUPS($page: Int!, $pageSize: Int, $sort: String, $q: String) {
    listGroups(
      pagination: { page: $page, pageSize: $pageSize, sort: $sort }
      query: { q: $q }
    ) {
      total
      totalPages
      page
      pageSize
      rows {
        id
        name
      }
    }
  }
`
