import styled, { css } from 'styled-components'
import { TableBody, TableRow, Typography, TableCell, Table } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

const TableCellStyled = styled(TableCell).attrs(({ align }) => ({ align }))`
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`

const TableBodyStyled = styled(TableBody)`
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`

const TypographyDefaultStyle = css`
  && {
    color: #222d5d;
  }
`

const TypographyStyled = styled(Typography)`
  ${TypographyDefaultStyle}
`

const LabelPaginationStyled = styled.span`
  ${TypographyDefaultStyle}
`

const TableRowBodyStyled = styled(TableRow)`
  ${({ $onRowSelected }) =>
    $onRowSelected &&
    css`
      cursor: pointer;
    `}}
  ${({ backgrounddisabled, disabled }) =>
    disabled &&
    css`
      cursor: auto;
      background: ${backgrounddisabled || '#ddd'};
    `}}
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background: #fafafa;
      }
    `}
`

const PaperStyled = styled(Paper)`
  background: none;
`

const PaperWrapTableStyled = styled(Paper)`
  width: 100%;
  overflow-x: auto;

  ${({ maxheight }) =>
    maxheight &&
    css`
      max-height: ${maxheight};
      overflow: auto;
    `};

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      width: 100%;
      margin: 0 auto;
    }
  `}

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar:hover {
    box-shadow: inset 0 0 1px 0 #0005;
  }
  ::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #777;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`

const TableContainerStyled = styled(Table).attrs(({ size, padding }) => ({
  size,
  padding,
  stickyHeader: true,
}))`
  width: 100%;

  thead th {
    white-space: nowrap;
  }

  tbody td {
    word-break: ${({ wordbreak }) => wordbreak || 'break-word'};
  }
`

const WrapPaginationStyled = styled.div`
  width: 100%;
  overflow-x: auto;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing(9)}px;
`

export {
  TableBodyStyled,
  TypographyStyled,
  LabelPaginationStyled,
  TableRowBodyStyled,
  PaperStyled,
  WrapPaginationStyled,
  PaperWrapTableStyled,
  TableContainerStyled,
  TableCellStyled,
}
