import styled, { css } from 'styled-components'
import { AppBar, IconButton, Button, Toolbar } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Menu, { MenuItem } from '../Menu'
import { Grid } from '~components'

export const LogoStyled = styled.img`
  display: none;
  height: 38px;

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      display: block;
    }
  `}
`

export const ToolbarStyled = styled(Toolbar)`
  width: 100%;
  display: flex;
  height: 50px !important;
  min-height: 0px;
  align-items: center;
`

export const NavbarStyled = styled.div`
  top: ${({ theme }) => theme.spacing(4)}px;
  left: auto;
  min-height: 0px;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  border-radius: ${({ theme }) => theme.card.borderRadius};
`

export const IconButtonStyled = styled(IconButton)`
  && {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`

export const DividerStyled = styled.hr`
  width: 1px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  height: 30px;
`

export const DividerRowStyled = styled(DividerStyled)`
  width: 100%;
  height: 1px;
`

export const MenuIndicatorStyled = styled.div`
  border-radius: 4px;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.palette.white[0]};
`

export const NavigationMenuStyled = styled(Button)`
  text-transform: none;
`

export const AppBarStyled = styled(AppBar)`
  && {
    width: 100%;
    right: 0;
    background-color: ${({ theme }) => theme.palette.white[0]};
    color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.palette.gray[400]};
    height: 50px !important;
    min-height: 0px;
  }
`

export const MenuItemStyled = styled(MenuItem)`
  font-size: 0.875rem;
  width: 100px;
  display: block;
`

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: inherit;
`

export const GridStyled = styled(Grid)`
  white-space: normal;
  text-align: center;
`

export const SpaceBetweenStyled = styled.div`
  display: flex;
  flex-grow: 1;
`

export const MenuStyled = styled(Menu)`
  margin-top: 2rem;
  .menu {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const MenuContentStyled = styled.div`
  display: flex;
  justify-content: space-around;
`
