import React from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '../FormHelperText'
import i18n from '~commons/i18n'

const AlertHelperText = ({ value, max }) => {
  const size = value.length
  const error = size > max

  return (
    <FormHelperText error={error}>
      {i18n.t('form.alertMaxText', { value: size, max })}
    </FormHelperText>
  )
}

AlertHelperText.propTypes = {
  value: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
}

export default AlertHelperText
