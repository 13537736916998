import React from 'react'
import PropTypes from 'prop-types'
import PageFooter from '../PageFooter'
import { PageHeaderStyled } from './style'

const PageHeader = ({ children, noPadding, fullWidth, color, ...props }) => (
  <PageHeaderStyled color={color}>
    <PageFooter noPadding={noPadding} fullWidth={fullWidth} {...props}>
      {children}
    </PageFooter>
  </PageHeaderStyled>
)

PageHeader.defaultProps = {
  noPadding: false,
  fullWidth: false,
  color: null,
}

PageHeader.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  noPadding: PropTypes.bool,
  color: PropTypes.string,
}

export default PageHeader
