import styled from 'styled-components'
import { Chip } from '@material-ui/core'

export const ChipOptionStyled = styled(Chip)`
  border-radius: 4px;
  min-width: 36px;

  .label {
    padding: 4px;
  }
`
