import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Table from '../Table'
import { InstallmentsModel, TableHeaderModel } from '~commons/models'

const TableInstallments = ({ headers, rows, loading }) => (
  <Table
    size="small"
    maxHeight={'300px'}
    wordBreak="normal"
    headers={headers}
    rows={rows}
    hasPagination={false}
    loading={loading}
  />
)

TableInstallments.defaultProps = {
  rows: [],
  loading: false,
}

TableInstallments.propTypes = {
  headers: PropTypes.arrayOf(TableHeaderModel),
  rows: PropTypes.arrayOf(InstallmentsModel),
  loading: PropTypes.bool,
}

export default memo(TableInstallments)
