import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '~components'
import i18n from '~commons/i18n'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import STATUS_COLORS from '~commons/constants/statusColors'
import { statusProposal } from '~commons/constants/loans'
import { Money as MoneyUtils } from '@base39/ui-utils'
import { ReactComponent as ValueSvg } from '~assets/svg/proposalValue.svg'
import DateSvg from '@material-ui/icons/DateRangeOutlined'
import { isFunction } from '~commons/utils/is'
import {
  ContainerStyled,
  ClickableStyled,
  ContainerItemStyled,
  StatusLabelStyled,
  DividerStyled,
  ItemTypography,
  WrapSVGStyled,
  GridStyled,
  LinkStyled,
  ProposalDataTypography,
} from './style'

const Item = ({ data, location, redirect, fields }) => {
  const { id, status } = data
  const { pathname } = location
  const isActive = pathname.includes(id)
  const statusColor = STATUS_COLORS[status]?.color

  return (
    <ContainerItemStyled>
      <LinkStyled to={`/${redirect}/${id}`}>
        <ClickableStyled>
          <ContainerStyled $active={isActive} container direction="column">
            {data[fields.title] && (
              <Grid item className="pt-1">
                <ProposalDataTypography>{data[fields.title]}</ProposalDataTypography>
              </Grid>
            )}
            {data[fields.subTitle] && (
              <Grid item>
                <ProposalDataTypography>{data[fields.subTitle]}</ProposalDataTypography>
              </Grid>
            )}
            <StatusLabelStyled color={statusColor}>
              {i18n.t(statusProposal[status]?.label)}
            </StatusLabelStyled>
            <DividerStyled color={statusColor} />
            <Grid container justifyContent="space-between">
              <GridStyled item>
                <WrapSVGStyled>
                  <ValueSvg />
                </WrapSVGStyled>
                <ItemTypography display="inline">
                  {isFunction(fields.value)
                    ? fields.value(data)
                    : MoneyUtils.formatCurrency(data[fields.value])}
                </ItemTypography>
              </GridStyled>
              <Grid item>
                <GridStyled item>
                  <WrapSVGStyled>
                    <DateSvg />
                  </WrapSVGStyled>
                  <ItemTypography display="inline">
                    {moment(data[fields.day]).fromNow()}
                  </ItemTypography>
                </GridStyled>
              </Grid>
            </Grid>
          </ContainerStyled>
        </ClickableStyled>
      </LinkStyled>
    </ContainerItemStyled>
  )
}

Item.defaultProps = {
  fields: {
    title: 'user',
    subTitle: 'companyName',
    status: 'status',
    value: 'value',
    day: 'requestDate',
  },
}

Item.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    status: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    day: PropTypes.string,
  }),
}

export default memo(withRouter(Item))
