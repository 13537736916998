import React, { memo } from 'react'
import ArrowBackIcon from '~assets/png/arrow-back.png'
import { WrapBackStyled, ImageStyled, TextStyled } from './style'
import { Router as RouterUtils } from '@base39/ui-utils'
import { BackHistoryInterface } from './interfaces'

const BackHistory = ({ text, onClick }: BackHistoryInterface) => {
  const history = RouterUtils.history
  const handleBack = () => history.goBack()

  return (
    <WrapBackStyled onClick={onClick || handleBack} data-testid="backHistory-wrap">
      <ImageStyled src={ArrowBackIcon} alt="back" data-testid="backHistory-image" />
      <TextStyled data-testid="backHistory-text">{text}</TextStyled>
    </WrapBackStyled>
  )
}

export default memo(BackHistory)
