import styled from 'styled-components'
import { ChipStyledProps } from './interfaces'

export const ChipStyled = styled.div<ChipStyledProps>`
  align-items: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
  margin: ${({ $margin }) => $margin};
  border-radius: 16px;
  border: none;
  box-sizing: border-box;
  cursor: default;
  display: inline-flex;
  font-size: 0.8125rem;
  height: 32px;
  justify-content: center;
  line-height: 11px;
  min-width: 90px;
  outline: 0;
  padding: 0 10px;
  text-decoration: none;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  vertical-align: middle;
  white-space: nowrap;
`
