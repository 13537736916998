import { gql } from '@apollo/client'

export const LIST_COMPANIES = gql`
  query QUERY_LIST_COMPANIES(
    $page: Int!
    $q: String
    $pageSize: Int
    $sort: String = "tradeName"
  ) {
    listCompanies(
      pagination: { page: $page, pageSize: $pageSize, sort: $sort }
      query: { q: $q }
    ) {
      total
      rows {
        id
        tradeName
        company
        cnpj
        employees
      }
    }
  }
`
