import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRange } from 'react-date-range'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'
import { DateContainerStyled, IconButtonStyled } from './style'
import ptLocale from 'date-fns/locale/pt-BR'

const Calendar = ({ searchParams, updateSearchParams }) => {
  const [state, setState] = useState([
    {
      startDate: searchParams.startDate,
      endDate: searchParams.endDate || new Date(),
      key: 'selection',
    },
  ])

  const onChange = (item) => {
    setState([item.selection])
    const { startDate, endDate } = item.selection
    updateSearchParams({
      startDate: startDate ? moment(startDate).startOf('day').toDate() : undefined,
      endDate: moment(endDate).endOf('day').toDate(),
    })
  }

  const clearSelection = () => {
    onChange({
      selection: {
        startDate: undefined,
        endDate: new Date(),
        key: 'selection',
      },
    })
  }

  const showClear = Boolean(state?.[0]?.startDate)

  return (
    <DateContainerStyled showClear={showClear}>
      {showClear && (
        <IconButtonStyled onClick={clearSelection}>
          <CloseIcon fontSize="small" />
        </IconButtonStyled>
      )}
      <DateRange
        locale={ptLocale}
        showMonthAndYearPickers={false}
        editableDateInputs={false}
        showDateDisplay={false}
        onChange={onChange}
        moveRangeOnFirstSelection={false}
        scroll={{
          enabled: false,
          calendarWidth: 250,
        }}
        maxDate={new Date()}
        ranges={state}
      />
    </DateContainerStyled>
  )
}

Calendar.propTypes = {
  updateSearchParams: PropTypes.func.isRequired,
}

export default memo(Calendar)
