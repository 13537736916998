import styled, { css } from 'styled-components'
import theme from '~commons/constants/theme'

const LoadingStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ alignItems }) => alignItems};
  color: #9e9e9e;
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  ${({ full }) =>
    full &&
    css`
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: ${theme.zIndex.snackbar};
    `};

  .icon {
    animation: spin 0.7s linear infinite;
    width: ${({ widthIcon }) => widthIcon};

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  ${({ type }) =>
    type === 'content' &&
    css`
      position: absolute;
      color: #ddd;
      background-color: #fff;
      font-size: 0.875rem;
    `}
`

export { LoadingStyled }
