import axios from 'axios'
import qs from 'qs'
import defaultsDeep from 'lodash.defaultsdeep'
import { Tenant } from '@base39/ui-utils'
import loading from '~commons/utils/loading'
import { getLanguage } from '~commons/utils/locale'
import { onResponseError } from './interceptors'
import { CustomAxiosInstance, CustomAxiosRequestConfig } from './interfaces'

const getConfig = () => {
  const token = sessionStorage.getItem('token')

  return {
    headers: {
      Accept: 'application/json',
      'Accept-Language': getLanguage(),
      Authorization: token,
      access_token: token,
      'x-tenant-id': Tenant.getTenant(),
    },
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'indices' })
    },
    loadingId: null,
    showLoading: true,
  }
}

const baseApi = (baseURL: string, config?: CustomAxiosRequestConfig) => {
  const axiosApi = axios.create({
    baseURL,
    ...config,
  }) as CustomAxiosInstance

  axiosApi.customRequest = async (path, options) => {
    const mergedOptions = defaultsDeep(options, getConfig())

    if (mergedOptions.showLoading) {
      mergedOptions.loadingId = loading.open()
    }

    const closeLoading = () => {
      loading.close(mergedOptions.loadingId)
    }

    return axiosApi(path, mergedOptions)
      .then(({ data }) => data)
      .finally(closeLoading)
  }

  axiosApi.interceptors.response.use(null, onResponseError)

  return axiosApi
}

export default baseApi
