import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const getPadding = ({ theme, children, noPadding }) =>
  !!children &&
  css`
    ${!noPadding && `padding: ${theme.spacing(2, 3)};`}
  `

export const FooterStyled = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${getPadding}

  ${({ theme, fullWidth }) => css`
    ${theme.breakpoints.up('lg')} {
      max-width: 66.666667%;
    }

    ${fullWidth &&
    css`
      max-width: 100% !important;
    `}

    ${theme.breakpoints.up('md')} {
      max-width: 50%;
    }
  `}
`

const PageFooter = ({ className, children, noPadding, fullWidth }) => (
  <FooterStyled className={className} noPadding={noPadding} fullWidth={fullWidth}>
    {children}
  </FooterStyled>
)

PageFooter.defaultProps = {
  className: '',
  noPadding: false,
  fullWidth: false,
}

PageFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  noPadding: PropTypes.bool,
}

export default PageFooter
