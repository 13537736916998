import React from 'react'
import RatesInfos from './RatesInfos'
import {
  Number as NumberUtils,
  Money as MoneyUtils,
  Object as ObjectUtils,
} from '@base39/ui-utils'
import i18n from '~commons/i18n'

export const formatPercent = (v) => `${NumberUtils.formatNumber(v)}%`

export const getValue = (item, keyName, fnFormat = (v) => v) => {
  const value = ObjectUtils.get(item, keyName)
  return value !== null ? fnFormat(value) : '-'
}

export const formatInstallment = (
  item,
  itemWithInsurance,
  simulationId,
  simulationInsuranceId,
  renderContent,
) => ({
  id: ObjectUtils.get(item, 'id'),
  insuranceId: ObjectUtils.get(itemWithInsurance, 'id'),
  allowed: getValue(item, 'allowed'),
  disabled: !item.allowed,
  tooltip:
    !item.allowed && item.message
      ? i18n.t(`simulation.${item.message}`, {
          errorValue: MoneyUtils.formatCurrency(item.errorValue),
        })
      : null,
  installments: getValue(item, 'installments'),
  installmentValue: getValue(item, 'installmentValue', MoneyUtils.formatCurrency),
  installmentInsuranceValue: getValue(
    itemWithInsurance,
    'installmentValue',
    MoneyUtils.formatCurrency,
  ),
  monthlyFee: getValue(item, 'monthlyFee', formatPercent),
  insuranceType: getValue(item, 'insuranceType'),
  insuranceFee: getValue(itemWithInsurance, 'insuranceFee', formatPercent),
  bkgColor: item.allowed ? '#ffffff' : '#fab1a0',
  simulationId,
  simulationInsuranceId,
  renderContent,
  ratesInfos: (
    <RatesInfos
      values={{ ...item, insuranceFee: getValue(itemWithInsurance, 'insuranceFee') }}
    />
  ),
})

export const joinSimulations = (simulation, insurance, renderContent) =>
  simulation.installments.map((item) => {
    const itemWithInsurance = insurance?.installments.find(
      (value) => value?.installments === item?.installments,
    )
    return formatInstallment(
      item,
      itemWithInsurance,
      simulation?.id,
      insurance?.id,
      renderContent,
    )
  })
