import { gql } from '@apollo/client'
import { SIMULATION_PARTS } from './simulation.fragment'

export const ADD_SIMULATE = gql`
  ${SIMULATION_PARTS}
  mutation ADD_SIMULATE(
    $cpf: String
    $salary: Float!
    $netSalary: Float
    $hiredAt: DateTime!
    $withInsurance: Boolean
    $company: String
    $value: Float!
    $payableMarginValue: Float
    $installments: [Int]!
    $firstDueDate: DateTime
    $loanId: String
  ) {
    addSimulation(
      body: {
        cpf: $cpf
        salary: $salary
        withInsurance: $withInsurance
        netSalary: $netSalary
        hiredAt: $hiredAt
        company: $company
        value: $value
        payableMarginValue: $payableMarginValue
        installments: $installments
        firstDueDate: $firstDueDate
        loanId: $loanId
      }
    ) {
      ...SIMULATION_PARTS
    }
  }
`
