import styled, { css } from 'styled-components'
import { Grid } from '~components'
import { DRAWER_WIDTH } from '~components/Drawer/drawer-style'

export const LayoutStyled = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  background: ${({ theme }) => theme.palette.background.level2};
  padding-top: 50px;
`

export const NavbarBackground = styled.div`
  background: ${({ theme }) => theme.palette.background.main};
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
`

export const MainStyled = styled(Grid)`
  overflow: hidden;
`

export const DrawerContainerStyled = styled(Grid)`
  height: 100%;
  width: ${DRAWER_WIDTH}px;
  overflow-y: auto;
`
export const ContentStyled = styled(Grid)`
  height: 100%;
  overflow-y: auto;
`

export const BodyContainer = styled.div`
  width: 100%;
  min-height: 250px;
  height: 100%;
  position: relative;
  background-color: #fafafb;
`

export const HeaderStyled = styled.div`
  width: 100%;
  height: 70px;
  background-color: #fff;
  border-bottom: 1px solid #d8d8db;
`

export const WrapContainer = styled.div`
  display: flex;
  width: 75%;
  min-height: 70px;
  margin: 0 auto;
  position: relative;
  flex-direction: ${({ direction }) => direction || 'column'};

  ${({ alignItens }) =>
    alignItens &&
    css`
      align-items: center;
    `}

  @media only screen and (max-width: 1440px) {
    width: 80%;
  }

  @media only screen and (max-width: 1280px) {
    width: 100%;
    padding: 0 ${({ theme }) => theme.spacing(3)}px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 0 ${({ theme }) => theme.spacing(2)}px;
    }
  `}
`
