import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Input from '@material-ui/core/Input'
import IconButton from '../IconButton'

export const InputStyled = styled(Input)`
  width: 100%;
  background-color: #fff;
  min-height: 48px;

  && {
    ${({ $noPadding }) =>
      !$noPadding &&
      css`
        padding-left: ${({ theme }) => theme.spacing(1)}px;
      `}
  }
`

InputStyled.propTypes = {
  noPadding: PropTypes.bool,
}

export const SearchContentStyled = styled.div`
  padding-top: 4px;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
`

export const IconButtonStyled = styled(IconButton)`
  border-radius: 0;
`
