import styled, { css } from 'styled-components'

export const WrapBadgeStyled = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  position: relative;
  z-index: 1;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  margin-left: -15px;
  color: #444d56;
  align-items: center;
  background-color: #e1e4e8;
  border: 2px solid #fff;
  border-radius: 50%;
  justify-content: center;
  flex-shrink: 0;
  transition: all 0.1s;

  ${({ hasClick }) =>
    hasClick &&
    css`
      cursor: pointer;

      :hover {
        transform: scale(1.1);
      }
    `}
`

export const AuthorStyled = styled.span`
  font-weight: 600;
`
