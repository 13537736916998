import styled from 'styled-components'
import { InputLabel } from '@material-ui/core'

const InputLabelStyled = styled(InputLabel).attrs({
  classes: {
    shrink: 'ShrinkedInput',
  },
})`
  &.ShrinkedInput {
    white-space: nowrap;
    letter-spacing: 0.2px;
  }

  color: ${({ theme }) => theme.typography.label};
  font-weight: 400;
`

export default InputLabelStyled
