import { lazy } from 'react'
import Layout from '~containers/Layout'

const CompanyContainer = lazy(() => import('~containers/Company'))
const CompanyListContainer = lazy(() => import('~containers/Company/Sidebar'))
const CompanySubheaderContainer = lazy(() => import('~containers/Company/Subheader'))
const CreateContainer = lazy(() => import('~containers/Company/Create'))
const EditEmployeeContainer = lazy(() => import('~containers/Company/Employee/Edit'))
const EmployeeContainer = lazy(() => import('~containers/Company/Employee/List'))
const EmptyViewContainer = lazy(() => import('~containers/Company/EmptyView.jsx'))
const FinancialContainer = lazy(() => import('~containers/Financial/Extract'))
const RegistrationContainer = lazy(() => import('~containers/Company/RegistrationInfo'))

const INITIAL_PATH = '/empresas'

const company = [
  {
    path: `${INITIAL_PATH}`,
    drawer: CompanyListContainer,
    subheader: CompanySubheaderContainer,
    component: CompanyContainer,
    subroutes: [
      {
        path: `${INITIAL_PATH}`,
        component: EmptyViewContainer,
        exact: true,
      },
      {
        path: `${INITIAL_PATH}/cadastrar-empresa`,
        component: CreateContainer,
        exact: true,
      },
      {
        path: `${INITIAL_PATH}/:id/cadastro`,
        component: RegistrationContainer,
        exact: true,
      },
      {
        path: `${INITIAL_PATH}/:id/funcionarios`,
        component: EmployeeContainer,
        exact: true,
      },
      {
        path: `${INITIAL_PATH}/:id/funcionarios/:user`,
        component: EditEmployeeContainer,
        exact: true,
      },
      {
        path: `${INITIAL_PATH}/:id/financeiro`,
        component: FinancialContainer,
        exact: true,
      },
    ],
    layout: Layout,
  },
]

export default company
