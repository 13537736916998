import React from 'react'
import PropTypes from 'prop-types'
import { PageStyled, PageScrollStyled } from './style'

const Page = ({ children, navbar, scroll, ...props }) => (
  <PageStyled {...props} hasNavbar={!!navbar}>
    {navbar}
    {!scroll && children}
    {scroll && <PageScrollStyled>{children}</PageScrollStyled>}
  </PageStyled>
)

Page.defaultProps = {
  scroll: false,
}

Page.propTypes = {
  children: PropTypes.node,
  navbar: PropTypes.node,
  scroll: PropTypes.bool,
}

export default Page
