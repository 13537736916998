import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { withField } from '../Field'
import MuiInput from '@material-ui/core/Input'

const InputNumber = ({ onChange, ...props }) => {
  const handleChange = (values) => {
    const { name, value } = props

    if (value !== values.value) {
      onChange({ target: { name, value: values.value } })
    }
  }

  return <NumberFormat {...props} onValueChange={handleChange} customInput={MuiInput} />
}

InputNumber.defaultProps = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  decimalScale: 2,
  isNumericString: true,
  fixedDecimalScale: true,
  allowNegative: false,
  type: 'tel',
}

InputNumber.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  thousandSeparator: PropTypes.string,
  decimalSeparator: PropTypes.string,
  decimalScale: PropTypes.number,
  isNumericString: PropTypes.bool,
  fixedDecimalScale: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export { InputNumber }
export default withField(InputNumber)
