import { action } from 'easy-peasy'

const withSearchParams = (model = {}) => ({
  ...model,
  searchParams: {
    q: '',
    page: 0,
    rowsPerPage: 12,
    sort: model?.sort || '-createdAt',
    direction: model?.direction || 'desc',
    status: [],
    startDate: '',
    endDate: '',
    ...model.searchParams,
  },
  setSearchParams: action((state, payload) => {
    state.searchParams = { ...state.searchParams, ...payload }
  }),
})

export default withSearchParams
