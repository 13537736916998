import { lazy } from 'react'

const Auth = lazy(() => import('~containers/Auth'))

const Routes = [
  {
    path: [
      '/login',
      '/',
      '/autenticacao/registrar',
      '/autenticacao/validacao',
      '/autenticacao/codigo',
      '/autenticacao/qrcode',
    ],
    component: Auth,
    condition: 'IS_NOT_AUTHENTICATED',
    exact: true,
  },
]

export default Routes
