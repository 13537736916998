import styled, { css } from 'styled-components'
import { AppBar, IconButton, MenuItem, Toolbar } from '@material-ui/core'
import Typography from '../Typography'

export const SpacerStyled = styled.div`
  width: 1px;
  background: #eaeaea;
  height: 50px;
`

export const LogoStyled = styled.img`
  display: none;
  height: 38px;

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      display: block;
    }
  `}
`

export const NavbarStyled = styled.div`
  top: ${({ theme }) => theme.spacing(4)}px;
  left: auto;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  border-radius: ${({ theme }) => theme.card.borderRadius};
`

export const IconButtonStyled = styled(IconButton)`
  && {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`

export const AppBarStyled = styled(AppBar)`
  && {
    width: 100%;
    right: 0;
    background-color: ${({ theme }) => theme.palette.white[0]};
    color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: inset 0 -1px 0 0 #e2e2ea;

    ${({ theme }) => css`
      ${theme.breakpoints.up('sm')} {
        padding-top: 4px;
        min-height: 70px;
      }
    `}
  }
`

export const MenuStyled = styled.ul`
  min-width: 150px;
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  top: ${({ theme }) => theme.spacing(4)}px;
  right: ${({ theme }) => theme.spacing(3)}px;
  background: ${({ theme }) => theme.palette.white[0]};
  padding: 0;
  box-shadow: inset 0 0px 0 1px #e2e2ea;

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      top: ${({ theme }) => theme.spacing(5)}px;
      right: ${({ theme }) => theme.spacing(4)}px;
    }
  `}
`

export const WrapMenuDropdown = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`

export const ArrowStyled = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #93939e;
    transition: all 0.4s;

    ${({ open }) =>
      open &&
      css`
        transform: rotateZ(180deg);
      `}
  }
`

export const MenuItemStyled = styled(MenuItem)`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.typography.text};
  padding: 1rem;
`

export const TypographyStyled = styled(Typography)`
  && {
    max-height: 1.2em;
    line-height: 1.2em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const ItemContentStyled = styled.div``

export const ItemLinkStyled = styled.div`
  display: flex;
  justify-content: flex-start;

  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)}px;
  .menu-icon {
    min-width: auto;
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.08);
    cursor: pointer;
  }

  color: ${({ theme, active }) => (active ? theme.palette.primary.main : '#000')};

  svg {
    fill: ${({ theme, active }) => (active ? theme.palette.primary.main : '#000')};
  }
`

export const ItemNameStyled = styled.li`
  overflow: hidden;
  font-size: 1rem;
  box-sizing: border-box;
  font-weight: 400;
  line-height: 1.5;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  white-space: nowrap;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding-left: ${({ theme }) => theme.spacing(1)}px;
`

export const CompaniesStyled = styled.div`
  min-width: 250px;
`

export const ToolbarStyled = styled(Toolbar)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const LeftStyled = styled.div`
  display: flex;
`

export const RightStyled = styled.div`
  display: flex;
  align-items: center;
`

export const AutoCompleteStyled = styled.div`
  .spotlight-autocomplete,
  .box-autocomplete {
    position: relative;
    z-index: 311;
    background-color: #fff;
  }

  .dark-screen {
    background: #000;
    bottom: 100%;
    height: 100%;
    left: 0;
    opacity: 0.7;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.3s, bottom 0s 0.3s;
    z-index: 310;
  }

  .box-autocomplete {
    border: 1px solid #efefef;
    padding: 20px;
    margin-top: 25px;
    color: #000;
  }

  .box-autocomplete:before {
    content: '';
    position: absolute;
    bottom: 56px;
    right: 5px;
    border-style: solid;
    border-width: 0px 10px 10px;
    border-color: #ffffff transparent;
  }
`
