import styled, { css } from 'styled-components'
import { SpeedDial } from '@material-ui/lab'

export const SpeedDialStyled = styled(SpeedDial)`
  ${({ float }) =>
    float &&
    css`
      position: fixed;
      right: ${({ theme }) => theme.spacing(2)}px;
      bottom: ${({ theme }) => theme.spacing(2)}px;
      z-index: ${({ theme }) => theme.zIndex.mobileStepper};
    `}
`
