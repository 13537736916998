import React from 'react'
import PropTypes from 'prop-types'
import { useStoreState } from 'easy-peasy'
import i18n from '~commons/i18n'
import {
  Number as NumberUtils,
  CNPJ as CNPJUtils,
  Date as DateUtils,
  String as StringUtils,
  Money as MoneyUtils,
} from '@base39/ui-utils'
import { FlatfileButton } from '@flatfile/react'

function Flatfile({ fields, type, title, onResponse, renderImporter }) {
  const me = useStoreState(({ user }) => user.user)

  // eslint-disable-next-line consistent-return
  const checkCNPJ = (cnpj) => {
    if (!CNPJUtils.isValid(cnpj)) {
      return {
        value: cnpj,
        info: [{ message: i18n.t('form.cnpjInvalid'), level: 'error' }],
      }
    }
  }

  // eslint-disable-next-line consistent-return
  const checkDate = (date) => {
    if (!DateUtils.isValidDate(date) && !StringUtils.isEmpty(date)) {
      return {
        value: date,
        info: [{ message: i18n.t('form.dateInvalid'), level: 'error' }],
      }
    }
  }

  // eslint-disable-next-line consistent-return
  const checkNumber = (value) => {
    if (!NumberUtils.isNumber(value) && !StringUtils.isEmpty(value)) {
      return {
        value: value,
        info: [{ message: i18n.t('form.numberInvalid'), level: 'error' }],
      }
    }
  }

  // eslint-disable-next-line consistent-return
  const checkCurrencyNumber = (value) => {
    const hasNumberRegex = /\d/
    if (value) {
      return {
        value: hasNumberRegex.test(value)
          ? MoneyUtils.sanitizeMoney(value).replace(',', '.')
          : null,
      }
    }
  }

  const validateColumnData = (record) => ({
    cnpj: checkCNPJ(record.cnpj),
    birthdayDate: checkDate(record.birthdayDate),
    netSalary: checkNumber(record.netSalary),
    salary: checkNumber(record.salary),
    revenue: checkCurrencyNumber(record.revenue),
    agreementStartDate: checkDate(record.agreementStartDate),
  })

  return (
    <FlatfileButton
      licenseKey={process.env.REACT_APP_FLATFILE}
      customer={{
        userId: me.id,
      }}
      settings={{
        type,
        fields,
        title,
        devMode: process.env.NODE_ENV !== 'production',
        allowCustom: true,
      }}
      onData={onResponse}
      onRecordInit={validateColumnData}
      onRecordChange={validateColumnData}
      render={renderImporter}
    />
  )
}

Flatfile.propTypes = {
  fields: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onResponse: PropTypes.func.isRequired,
  renderImporter: PropTypes.func.isRequired,
}

export default Flatfile
