import React from 'react'
import Layout from '~containers/Layout'
import { CreditPolicyProvider } from './Hooks/CreditPolicyHook'

const CustomLayout = ({ children, ...rest }) => {
  return (
    <CreditPolicyProvider>
      <Layout {...rest}>{children}</Layout>
    </CreditPolicyProvider>
  )
}

export default CustomLayout
