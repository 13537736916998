import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as LoadingIcon } from '~assets/svg/circular.svg'
import { LoadingStyled, ImageContainerStyled, ImageStyled } from './style'
import imgOff from '~assets/svg/image_off.svg'

const Image = ({ src, className, alt, color, ...props }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const visibility = loading ? 'hidden' : 'visible'

  const handleError = () => {
    setError(true)
    setLoading(false)
  }

  const handleLoad = () => {
    setLoading(false)
  }

  useEffect(() => {
    setError(false)
    if (!loading) {
      setLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  return (
    <ImageContainerStyled className={className}>
      <ImageStyled
        visibility={visibility}
        onError={handleError}
        onLoad={handleLoad}
        alt={alt}
        src={error ? imgOff : src}
        {...props}
      />
      {loading && <LoadingStyled Icon={LoadingIcon} color={color} />}
    </ImageContainerStyled>
  )
}

Image.defaultProps = {
  className: undefined,
}

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  color: PropTypes.string,
  alt: PropTypes.string,
}

export default Image
