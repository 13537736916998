const socketConfig = {
  url: process.env.REACT_APP_API3_URL,
  opts: {
    autoConnect: false,
    transports: ['websocket'],
    upgrade: false,
    reconnectionDelay: 1000,
    reconnection: true,
    reconnectionAttempts: 4,
  },
}

export default socketConfig
