import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { ContainerStyled } from './style'

const Subheader = (props) => {
  const { children } = props
  return <ContainerStyled>{children}</ContainerStyled>
}

Subheader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(Subheader)
