const spacing = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

const dimensions = ['sm', 'xs', 'md', 'lg', 'xl']

const getSpacing =
  (name, prefix) =>
  ({ theme }) =>
    spacing.reduce(
      (accumulator, value) => `
    ${accumulator}

    .${prefix}-${value} {
      ${name}: ${theme.spacing(value)}px !important;
    }

    .${prefix}h-${value} {
      ${name}-left: ${theme.spacing(value)}px !important;
      ${name}-right: ${theme.spacing(value)}px !important;
    }

    .${prefix}v-${value} {
      ${name}-top: ${theme.spacing(value)}px  !important;
      ${name}-bottom: ${theme.spacing(value)}px  !important;
    }

    .${prefix}t-${value} {
      ${name}-top: ${theme.spacing(value)}px !important;
    }

    .${prefix}b-${value} {
      ${name}-bottom: ${theme.spacing(value)}px !important;
    }

    .${prefix}r-${value} {
      ${name}-right: ${theme.spacing(value)}px !important;
    }

    .${prefix}l-${value} {
      ${name}-left: ${theme.spacing(value)}px !important;
    }

    ${dimensions
      .map(
        (item) => `
      .${prefix}-${item}-${value} {
        ${theme.breakpoints.up(item)} {
          ${name}: ${theme.spacing(value)}px !important;
        }
      }

      .${prefix}v-${item}-${value} {
        ${theme.breakpoints.up(item)} {
          ${name}-left: ${theme.spacing(value)}px !important;
          ${name}-right: ${theme.spacing(value)}px !important;
        }
      }

      .${prefix}h-${item}-${value} {
        ${theme.breakpoints.up(item)} {
          ${name}-top: ${theme.spacing(value)}px !important;
          ${name}-bottom: ${theme.spacing(value)}px !important;
        }
      }

      .${prefix}t-${item}-${value} {
        ${theme.breakpoints.up(item)} {
          ${name}-top: ${theme.spacing(value)}px !important;
        }
      }

      .${prefix}b-${item}-${value} {
        ${theme.breakpoints.up(item)} {
          ${name}-bottom: ${theme.spacing(value)}px !important;
        }
      }

      .${prefix}r-${item}-${value} {
        ${theme.breakpoints.up(item)} {
          ${name}-right: ${theme.spacing(value)}px !important;
        }
      }

      .${prefix}l-${item}-${value} {
        ${theme.breakpoints.up(item)} {
          ${name}-left: ${theme.spacing(value)}px !important;
        }
      }

    `,
      )
      .toString()
      .replace(/,/g, '')}

  `,
      '',
    )

export { getSpacing }
