import React, { useState, useEffect, useCallback, useRef } from 'react'
import 'react-datasheet/lib/react-datasheet.css'
import Datasheet from 'react-datasheet'
import { Table, TableBody } from '@material-ui/core'
import { Number as NumberUtils } from '@base39/ui-utils'
import debounce from 'debounce'
import { WrapTableStyled } from './style'

const DataSheet = ({ rows, renderHeader, renderBody, onCellsSelected, skipRender }) => {
  const normalizeToDataSheet = useCallback(
    (row) =>
      Object.keys(row)
        .filter((key) => !skipRender.includes(key))
        .map((key) => {
          const newValue = row[key]
          return { value: newValue }
        }),
    [skipRender],
  )

  const renderData = useCallback(() => {
    const content = []

    rows.map((row) => {
      const item = normalizeToDataSheet(row)
      content.push(item)
      return row
    })

    return content
  }, [rows, normalizeToDataSheet])

  const [grid, setGrid] = useState(renderData())

  useEffect(() => {
    setGrid(renderData())
  }, [rows, renderData])

  const valueRenderer = (cell) => cell.value

  const onCellsChanged = (changes) => {
    const newGrid = grid
    changes.forEach(({ row, col, value }) => {
      newGrid[row][col] = { ...newGrid[row][col], value }
    })
    setGrid(newGrid)
  }

  const onContextMenu = (e, cell) => (cell.readOnly ? e.preventDefault() : null)

  const CURRENCY = 'R$'

  const getValue = (point) =>
    point?.value &&
    NumberUtils.convertStringToNumber(
      NumberUtils.formatToDecimalNumber(
        NumberUtils.removeCurrency(point.value, CURRENCY),
      ),
    )

  const sumValues = (values) =>
    values.reduce((total, column) => {
      const getTotal = total
      const value = column
      return getTotal + value
    })

  const handleOnCellsSelected = debounce(({ start, end }) => {
    const valuesToSum = []

    grid.map((item, index) => {
      if (NumberUtils.inRange(index, start.i, end.i)) {
        item.map((subItem, subItemIndex) => {
          if (!NumberUtils.inRange(subItemIndex, start.j, end.j)) {
            return false
          }

          const value = getValue(subItem)

          if (!value) {
            return false
          }

          valuesToSum.push(value)
          return subItem
        })
      }
      return item
    })

    const sum = valuesToSum.length < 1 ? 0 : sumValues(valuesToSum)

    onCellsSelected({ sum })
  }, 500)

  const useOutsideClick = (ref) => {
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          onCellsSelected({ sum: null })
        }
      }

      document.addEventListener('mousedown', handleOutsideClick)
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick)
      }
    }, [ref])
    return ''
  }

  const wrapperRef = useRef(null)

  return (
    <>
      <Datasheet
        data={grid}
        sheetRenderer={(props) => (
          <WrapTableStyled ref={wrapperRef}>
            <Table className={props.className}>
              {renderHeader()}
              {renderBody.canRenderBody() ? (
                <TableBody>{props.children}</TableBody>
              ) : (
                renderBody.renderEmpty()
              )}
            </Table>
          </WrapTableStyled>
        )}
        valueRenderer={valueRenderer}
        onContextMenu={onContextMenu}
        onCellsChanged={onCellsChanged}
        onSelect={handleOnCellsSelected}
      />
      {useOutsideClick(wrapperRef)}
    </>
  )
}

export default DataSheet
