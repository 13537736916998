import React, { memo } from 'react'
import PropTypes from 'prop-types'
import imgOff from '~assets/svg/image_off.svg'
import { ThumbStyled } from './style'

export const getURLPreview = (file) => (!file ? imgOff : URL.createObjectURL(file))

const Thumb = ({ file, url, ...props }) => (
  <ThumbStyled {...props} image={url || getURLPreview(file)} />
)

Thumb.defaultProps = {
  border: true,
}

Thumb.propTypes = {
  file: PropTypes.object,
  url: PropTypes.string,
  border: PropTypes.bool,
}

export default memo(Thumb)
