import React from 'react'
import { Mask as MaskUtils } from '@base39/ui-utils'
import { withField } from '../Field'
import MaskedInput from 'react-input-mask'
import PropTypes from 'prop-types'
import { Input } from '../Input'

const InputMask = ({ onChange, disabled, mask, ...props }) => {
  const handleChange = (event) => {
    event.target.value = MaskUtils.getUnmask(mask)(event.target.value)
    onChange(event)
  }

  const maskFormat = MaskUtils.getMask(mask)

  return (
    <MaskedInput {...props} disabled={disabled} mask={maskFormat} onChange={handleChange}>
      {(inputProps) => <Input disabled={disabled} {...inputProps} />}
    </MaskedInput>
  )
}

InputMask.defaultProps = {
  maskChar: '',
  disabled: false,
  type: 'tel',
}

InputMask.propTypes = {
  onChange: PropTypes.func.isRequired,
  mask: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  maskChar: PropTypes.string,
}

export { InputMask }
export default withField(InputMask)
