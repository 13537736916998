import React from 'react'
import PropTypes from 'prop-types'
import { Close as CloseIcon } from '@material-ui/icons'
import {
  DialogTitleStyled,
  DialogSubtitleStyled,
  DialogHeaderStyled,
  TitleContainerStyled,
  ActionsContainerStyled,
  IconButtonStyled,
} from './style'

const DialogHeader = ({ title, subtitle, className, children, onClose, color }) => (
  <DialogHeaderStyled className={className} disableTypography color={color}>
    <TitleContainerStyled>
      <DialogTitleStyled component="h2" variant="h6" color="inherit">
        {title}
      </DialogTitleStyled>

      <DialogSubtitleStyled component="h2" variant="subtitle1">
        {subtitle}
      </DialogSubtitleStyled>
    </TitleContainerStyled>
    <ActionsContainerStyled>
      {children}
      {onClose && (
        <IconButtonStyled color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButtonStyled>
      )}
    </ActionsContainerStyled>
  </DialogHeaderStyled>
)

DialogHeader.propTypes = {
  onClose: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
}

export default DialogHeader
