import { lazy } from 'react'
import CustomLayout from '~containers/CreditPolicy/CustomLayout'

const CreditPolicyContainer = lazy(() => import('~containers/CreditPolicy'))
const Subheader = lazy(() => import('~containers/CreditPolicy/Subheader'))
const Sidebar = lazy(() => import('~containers/CreditPolicy/Sidebar'))
const EmptyView = lazy(() => import('~containers/CreditPolicy/EmptyView.jsx'))
const Details = lazy(() => import('~containers/CreditPolicy/Details'))

const INITIAL_PATH = '/politicas-credito'

const creditPolicy = [
  {
    path: `${INITIAL_PATH}`,
    drawer: Sidebar,
    subheader: Subheader,
    component: CreditPolicyContainer,
    subroutes: [
      {
        path: `${INITIAL_PATH}`,
        component: EmptyView,
        exact: true,
      },
      {
        path: `${INITIAL_PATH}/:id`,
        component: Details,
        exact: true,
      },
    ],
    layout: CustomLayout,
  },
]

export default creditPolicy
