import { RestApi } from './api'

const path = '/funds'

const Fund = {
  getFunds(params) {
    return RestApi.customRequest(path, { params, method: 'GET' })
  },

  get(id) {
    return RestApi.customRequest(`${path}/${id}`, { method: 'GET' })
  },

  create(data) {
    return RestApi.customRequest(path, { data, method: 'POST' })
  },

  update({ id, data }) {
    return RestApi.customRequest(`${path}/${id}`, { data, method: 'PUT' })
  },
}

export default Fund
