/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { memo } from 'react'
import { Field } from 'formik'
import FormControl from '../FormControl'
import { areEqual } from './utils'

const withField = (Input) => {
  const FieldComponent = (props) => <FormControl {...props} customComponent={Input} />
  const component = memo(FieldComponent, areEqual)

  return (props) => <Field {...props} component={component} />
}

export default withField
