import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useAuth0 } from '@auth0/auth0-react'
import { Route } from 'react-router-dom'
import Auth from '../Auth'
import { useStoreActions } from 'easy-peasy'
import { Loading } from '~components'
import { parseJwt } from '~commons/utils/token'

const DefaultLayout = ({ children }) => <Fragment>{children}</Fragment>

const AppRoute = (props) => {
  const {
    component: Component,
    layout: Layout,
    backTo,
    title,
    drawer,
    subheader,
    subroutes,
    ...otherProps
  } = props

  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const setUser = useStoreActions(({ user }) => user.setUser)
  const userIsAuthenticated = isAuthenticated || sessionStorage.getItem('token')

  const ComponentWithLayout = (props) => (
    <Layout
      {...props}
      drawer={drawer}
      subheader={subheader}
      title={title}
      backTo={backTo}
    >
      <Component {...props} subroutes={subroutes} title={title} />
    </Layout>
  )

  const storeToken = async () => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently()
      setUser({
        id: parseJwt(token)['https://api.paketa.io/claims/id'],
      })
      sessionStorage.setItem('token', token)
    }
  }

  storeToken()

  if (!userIsAuthenticated) {
    return <Auth />
  }

  const component = userIsAuthenticated ? ComponentWithLayout : Loading

  return <Route {...otherProps} render={(props) => component(props)} />
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

AppRoute.defaultProps = {
  layout: DefaultLayout,
  backTo: null,
  title: null,
}

AppRoute.propTypes = {
  component: PropTypes.object.isRequired,
  title: PropTypes.string,
  layout: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.object]),
  backTo: PropTypes.string,
}

export default AppRoute
