import toast from '~commons/utils/toast'
import i18n from '~commons/i18n'

const ERROR_ACTIONS = {
  404: () => undefined,
  422: () => undefined,
  500: () => toast.error(i18n.t('general.errorUnknown')),
  UNKNOWN: () => toast.error(i18n.t('general.errorUnknown')),
}

export const onResponseError = (error) => {
  const status = error?.response?.status
  const action = ERROR_ACTIONS[status] || ERROR_ACTIONS.UNKNOWN

  action()

  return Promise.reject(error)
}
