import { RestApi } from './api'

const path = '/groups'

const Groups = {
  get(id: string) {
    return RestApi.customRequest(`${path}/${id}`, {
      method: 'GET',
    })
  },
}

export default Groups
