import { lazy } from 'react'
import Layout from '~containers/Layout'

const SimulatorContainer = lazy(() => import('~containers/Simulator'))

const simulator = [
  {
    path: '/simulador',
    component: SimulatorContainer,
    exact: true,
    layout: Layout,
  },
]

export default simulator
