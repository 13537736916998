import { gql } from '@apollo/client'

export const GET_USER_BY_CPF = gql`
  query GET_USER_BY_CPF($cpf: String) {
    getUserByCPF(cpf: $cpf) {
      name
      id
      phoneNumber
      email
      company {
        salary
        netSalary
        hiredAt
        ref {
          id
          tradeName
        }
      }
    }
  }
`
