import i18n from '~commons/i18n'

const locale = {
  mixed: {
    default: i18n.t('form.fieldInvalid'),
    required: i18n.t('form.fieldRequired'),
    notType: i18n.t('form.notType'),
  },
  string: {
    email: i18n.t('form.emailInvalid'),
    min: ({ min }) => i18n.t('form.minCharacters', { quantity: min }),
    max: ({ max }) => i18n.t('form.maxCharacters', { quantity: max }),
  },
  number: {
    positive: i18n.t('form.biggerThanZero'),
    min: ({ min }) => i18n.t('form.minimumValue', { number: min }),
    max: ({ max }) => i18n.t('form.maximumValue', { number: max }),
  },
}

export default locale
