import styled from 'styled-components'

export const WrapTableStyled = styled.div`
  display: inline-block;
  overflow-x: auto;
  overflow-y: hidden;

  th {
    top: 0;
    left: 0;
    z-index: 2;
    padding: ${({ theme }) => theme.spacing(1)}px;
    background-color: #fff;
  }

  .data-grid {
    table-layout: auto;
    border-collapse: inherit;

    .cell {
      padding: ${({ theme }) => theme.spacing(1)}px;
      background: #fff;
      vertical-align: middle;
    }
  }
`
