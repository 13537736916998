import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from '../Image'

const Card = ({ image, ...props }) => <Image {...props} src={image} />

const ThumbStyled = styled(Card)`
  && {
    background-size: contain;
  }
  object-fit: contain;
  height: 100%;
  width: 100%;

  ${({ border, theme }) =>
    border &&
    `
    border: 1px solid ${theme.palette.grey[300]};
    border-radius: 4px;
  `}
`

Card.propTypes = {
  border: PropTypes.bool,
  image: PropTypes.string,
}

export { ThumbStyled }
