import React, { memo } from 'react'
import { withRouter } from 'react-router-dom'
import { WrapMenuStyled, HeaderStyled, ContentStyled } from './style'

export const DrawerHeader = HeaderStyled
export const DrawerContent = ContentStyled

const CustomDrawer = ({ children }) => (
  <WrapMenuStyled container direction="column">
    {children}
  </WrapMenuStyled>
)

export default memo(withRouter(CustomDrawer))
