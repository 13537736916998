import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

export const TypographyStyled = styled(Typography)`
  && {
    font-weight: ${({ weight }) => weight};
  }
`

TypographyStyled.defaultProps = {
  weight: 'normal',
  display: 'block',
  variant: 'body2',
}

export default TypographyStyled
