export const getDefaultSortWithoutDirection = (sort) => sort?.replace('-', '')

export const getDefaultDirection = (sort) => (sort?.[0] === '-' ? 'desc' : 'asc')

const invertCurrentDirection = (direction) => (direction === 'asc' ? 'desc' : 'asc')

export const getDirection = (value, direction, currentSort) =>
  value === currentSort ? invertCurrentDirection(direction) : 'asc'

const hasThreeIndexs = (value, orderBySplited) =>
  value &&
  value[orderBySplited[0]] &&
  value[orderBySplited[0]][orderBySplited[1]] &&
  value[orderBySplited[0]][orderBySplited[1]][orderBySplited[2]]

const hasTwoIndexs = (value, orderBySplited) =>
  value && value[orderBySplited[0]] && value[orderBySplited[0]][orderBySplited[1]]

const getSortValue = (values, orderBy) => {
  const orderBySplited = orderBy.split('.')
  let newValues
  if (hasThreeIndexs(values, orderBySplited)) {
    newValues = values[orderBySplited[0]][orderBySplited[1]][orderBySplited[2]]
  } else if (hasTwoIndexs(values, orderBySplited)) {
    newValues = values[orderBySplited[0]][orderBySplited[1]]
  } else {
    newValues = values[orderBy]
  }
  return newValues
}

const descendingComparator = (a, b, orderBy) => {
  const newValueA = getSortValue(a, orderBy)
  const newValueB = getSortValue(b, orderBy)

  if (newValueB < newValueA) {
    return -1
  }
  if (newValueB > newValueA) {
    return 1
  }
  return 0
}

export const getComparator = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)

const SORT_INDEX_COMPARATOR = 1

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) {
      return order
    }

    return a[SORT_INDEX_COMPARATOR] - b[SORT_INDEX_COMPARATOR]
  })
  return stabilizedThis.map((el) => el[0])
}
