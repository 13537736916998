import React, { memo } from 'react'
import { ChipOptionProps } from './interfaces'
import { ChipOptionStyled } from './styles'

const classes = {
  label: 'label',
  root: 'root',
}

const ChipOption = (props: ChipOptionProps) => {
  const { name, checked, onClick, value, disabled, label, ...rest } = props

  return (
    <ChipOptionStyled
      classes={classes}
      aria-checked={checked}
      aria-disabled={disabled}
      role="menuitemradio"
      variant={checked ? 'default' : 'outlined'}
      onClick={() => onClick({ target: { name, value } })}
      color="primary"
      label={label}
      disabled={disabled}
      {...rest}
    />
  )
}

ChipOption.defaultProps = {
  checked: false,
}

export default memo(ChipOption)
