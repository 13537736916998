import styled from 'styled-components'
import { SnackbarContent } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { green, amber } from '@material-ui/core/colors'

export const getColor = ({ type, theme }) => {
  const colors = {
    success: green[600],
    error: theme.palette.error.dark,
    info: theme.palette.primary.dark,
    warning: amber[700],
  }

  return colors[type]
}

const SnackbarContentStyled = styled(SnackbarContent)`
  && {
    background-color: ${getColor};
  }
`

const MessageStyled = styled.span`
  display: flex;
  align-items: center;

  .message-icon {
    font-size: 20px;
    opacity: 0.9;
    margin-right: ${({ theme }) => theme.spacing()}px;
  }
`

const CloseIcon = styled(Close)`
  font-size: 20px;
`

export { SnackbarContentStyled, MessageStyled, CloseIcon }
