import React, { memo } from 'react'
import i18n from '~commons/i18n'
import { String as StringUtils } from '@base39/ui-utils'
import { TimelineItemStyled, CommentStyled } from '../style'
import {
  HeaderStyled,
  TextStyled,
  UserStyled,
  BodyStyled,
  WrapAvatarStyled,
  UserIconStyled,
  CaretWrapStyled,
} from './style'

const Comment = ({ user, displayUser, rejected, text, date, onClick, hasClick }) => {
  const hasUser = !StringUtils.isEmpty(user)

  const renderHeader = (
    <HeaderStyled>
      <TextStyled>
        {displayUser && hasUser && <UserStyled>{`${user} `}</UserStyled>}
        {i18n.t('timeline.addedAComment', { date })}
      </TextStyled>
    </HeaderStyled>
  )

  const renderBody = (
    <BodyStyled>
      {rejected && `❌ ${i18n.t(`causesOfRejection.${rejected}`)}`}
      <p>{text}</p>
    </BodyStyled>
  )

  const renderAvatar = (
    <WrapAvatarStyled onClick={onClick} hasClick={hasClick}>
      <UserIconStyled />
    </WrapAvatarStyled>
  )

  const renderComment = (
    <CommentStyled>
      <CaretWrapStyled>
        {renderHeader}
        {renderBody}
      </CaretWrapStyled>
    </CommentStyled>
  )

  return (
    <TimelineItemStyled space={2}>
      {renderAvatar}
      {renderComment}
    </TimelineItemStyled>
  )
}

export default memo(Comment)
