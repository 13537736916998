import styled from 'styled-components'
import { FormControl as MuiFormControl } from '@material-ui/core'

const MuiFormControlStyled = styled(MuiFormControl)`
  border-color: #f1f1f1;
  background: none;
  border-radius: 4px;
  font-weight: 400;

  & fieldset {
    border-color: #f1f1f1;
  }
`

export { MuiFormControlStyled }
