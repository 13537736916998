import styled from 'styled-components'
import { ReactComponent as EmptySvg } from '~assets/svg/empty-icon.svg'

export const EmptyContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #98a9bc;
`

export const EmptySvgStyled = styled(EmptySvg)`
  width: 250px;
  height: 200px;
`
