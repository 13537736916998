import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import Skeleton from '../Skeleton'
import Typography from '../Typography'
import Grid from '../Grid'
import { ButtonBase } from '../Button'

export const WrapSVGStyled = styled.div`
  margin-right: 5px;

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: ${({ theme }) => theme.palette.grayScale.light}};
    }
  }
`

export const ContainerStyled = styled(Grid)`
  background: #f4f4f5;
  padding: 10px;
  border-radius: 4px;
  transition: 0.2s filter;

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.white[0]};

      svg path {
        fill: ${({ theme }) => theme.palette.white[0]};
      }
    `}
`

export const ClickableStyled = styled(ButtonBase)`
  padding: 0px;
  margin: 0px;
  width: 100%;
  text-align: unset;
  border-radius: 4px;
`

export const SkeletonStyled = styled(Skeleton)`
  && {
    background-color: #dadada;
  }
`

export const GridStyled = styled(Grid)`
  display: flex;
  align-items: center;
`

export const ContainerItemStyled = styled.div`
  padding: 10px;
`

export const StatusLabelStyled = styled.div`
  text-align: center;
  padding: 2px;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`

export const DividerStyled = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  margin-bottom: 10px;
  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`

export const ItemTypography = styled(Typography)`
  font-size: 10px;
  letter-spacing: 0.3px;
  line-height: 10px;
`

export const LinkStyled = styled(Link)`
  text-decoration: inherit;
  color: inherit;
`

export const ProposalDataTypography = styled(Typography)`
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 14px;
`
