import theme from '../constants/theme'

const getTheme = () => ({
  ...theme,
  palette: {
    ...theme.palette,
    divider: '#e5e5e5',
  },
})

export { getTheme }
