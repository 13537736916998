import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk'
import { useStoreState } from 'easy-peasy'
import { Tenant } from '@base39/ui-utils'
import theme from '~commons/constants/theme'

const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY,
})

function PaketaProvider({ children }) {
  const { user } = useStoreState(({ user }) => user)

  const applyBrowserTheme = () => {
    const favicon = document.getElementById('favicon')

    document.title = theme.name
    if (favicon) {
      favicon.href = theme.favicon
    }
  }

  useEffect(() => {
    applyBrowserTheme()
  }, [])

  const userData = {
    id: user?.id,
    attributes: {
      tenant: Tenant.getTenant(),
      application: process.env.REACT_APP_APPLICATION,
    },
  }

  return (
    <OptimizelyProvider optimizely={optimizely} user={userData}>
      {children}
    </OptimizelyProvider>
  )
}

PaketaProvider.propTypes = {
  children: PropTypes.node,
}

export default PaketaProvider
